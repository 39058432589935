import React from 'react';
import bindAll from 'lodash.bindall';
import {connect} from 'react-redux';
import {defineMessages, injectIntl} from 'react-intl';
import styles from './create-model.css';
import LANG_MESSAGE from './lang.js'

import Create from './components/create.jsx'
import TaskType from './components/task-type.jsx'
import TrainModel from './components/train-model.jsx'
import CompileModel from './components/compile-model.jsx'
import DownloadModel from './components/download-model.jsx'
import DownloadState from './components/download-state.jsx'
import PreviewBox from './components/preview-box.jsx'
import TakePhoto from '../take-photo/take-photo.jsx';

import {
    getDefaultConfig, 
    getResourceSpec, 
    createJob,
    getTrainList,
    getTrainStatus,
    getTrainDetail,
    getCloudList,
    createCloud,
    getCloudStatus,
    getCloudResult,
    createCompilationTask,
    getCompileList,
    getCompileStatus,
    getCompileDetail,
    getCompileLog,
    getDownloadModel,
    stopAllTranin,
    stopAllClound,
    stopAllCompile
} from '../../api/code.js'

import { categorize, detection } from './verification.js'
import { formatDuring, randomStr, getRandomColor } from '../../utils/utils.js'

import axios from 'axios';

class CreateModel extends React.Component {
    constructor(props) {
        super(props)
        this.train_timer = null
        this.input_timer = null
        this.state = {
            // 创建模型
            model_name: '',
            // 任务类型
            task_type_list: [],
            task_type_act: 0,
            // 训练模式
            train_model_type: 'ordinary', // ordinary普通模式 senior高级模式
            round: 50, // 训练轮次
            image_size: 640, // 图像大小
            batch_sample: 8, // 批样本数
            learning_rate: 0.01, // 学些率

            model_list: [], // 模型列表
            model_list_val: '', // 模型名称

            train_time: 0, // 推理时间
            train_source: [], // 训练资源池
            train_source_val: '', // 训练资源池
            train_specs: [], // 训练规格
            train_specs_val: '', // 训练规格

            reason_source: [], // 推理资源池
            reason_source_val: '', // 训练资源池
            reason_specs: [], // 推理规格
            reason_specs_val: '', // 推理规格

            compile_source: [], // 编译资源池
            compile_source_val: '', // 编译资源池
            compile_specs: [], // 编译规格
            compile_specs_val: '', // 训编译规格

            train_job_id: null, // 训练任务id
            train_suc_data: {},
            train_status: '',
            train_status_text: '', // 训练状态文字

            cur_round: 0, // 当前轮次
            accuracy_rate: 0, // 正确率
            loss_rate: 0, // 损失率

            task_type: null, // 任务状态

            // 部署
            deployment_time: 0, // 部署时间
            deployment: null, // 部署
            deployment_suc_data: {}, // 部署成功
            deployment_status: '', // 部署状态
            deployment_status_text: '', // 部署状态文字

            // 编译模型
            compile_time: 0, // 编译时间
            compile_model_type: 'ordinary', // ordinary普通模式 senior高级模式
            compile_size_width: 640,
            compile_size_height: 640,
            compile_id: '', // 创建编译成功id
            compile_status: '', // 编译状态
            compile_status_text: '', // 编译状态文字

            compile_suc_data: {}, // 编程成功
            compile_similarity: 0, // 相似度

            tpu_list: [], // tpu类型
            tpu_type_val: '', // tpu类型

            // 上传图片
            upload_type: '', // train compile
            upload_image: null, // 上传图片
            result_image: null, // 推理画框图

            // tpu推理
            tpu_test_list: [], // tpu测试模型
            tpu_test_val: '', //tpu测试模型
            conf_thres: 0.5,
            iou_thres: 0.45,
            result_list: [], // 推理结果
            result_state: false,

            // tpu下载弹框
            down_model_show: false,
            down_model_name: '',
            down_model_width: 640,
            down_model_height: 640,
            is_cover: true,
            is_down: false,
            confThres: 0.5,
            iouThres: 0.45,
            // tpu下载状态提示框
            down_state: null, // true false
            down_state_show: false,

            // 预览
            preview: false,
            take_photo: false,

            is_load: false,
            custom_parame: '', // 自定参数

            action: null,
        }
        bindAll(this, [
            'closeDialog',
            'cancle',
            'confirm',
            'setModelName',
            'taskListEvent',
            'selectDataSet',
            'createDataSet',
            'menuClick',
            'changeModeType',
            'setRound',
            'setImageSize',
            'setBatchSample',
            'setLearningRate',
            'changeModel',
            'changeSource',
            'changeSpecs',
            'changeReasonSource',
            'changeReasonSpecs',
            'createTrainTask',
            'deploymentModel',
            'trainStatus',
            'changeStatus',
            'deploymentStatus',
            'cloudResult',
            'saveJson',
            'setModelList',
            'setSource',
            'openCompileModel',
            'setWidth',
            'setHeight',
            'createCompileTask',
            'getTpuList',
            'setTpuType',
            'compileStatus',
            'getTpuDefaultList',
            'setDownModelShow',
            'setDownModelName',
            'downloadModelConfirm',
            'setDownModelWidth',
            'setDownModelHeight',
            'setCover',
            'setDownStateShow',
            'setConfThres',
            'setIouThres',
            'setUploadType',
            'tpuResult',
            'initData',
            '_sortKeyDesc',
            'drawResultImg',
            'setPreview',
            'changeTpuTest',
            'changeTakePhoto',
            'takePhotoConfirm',
            'onDropdownVisibleChange',
            'setCustomParame',
            'setDownModelTh',
            'setDownModelIo',
            'selectModel',
            'onDropdownModelChange'
        ])
    }
    componentDidMount() {
        if (
            this.props.createModel.type === 'train-model' ||
            this.props.createModel.type === 'compile-model'
        ) {
            this.setModelList(true)
            this.setSource()

            if (this.props.createModel.type === 'compile-model') {
                this.getTpuList()
                this.getTpuDefaultList()
            }
        }
    }
    async componentDidUpdate(prevProps) {
        if (
            (
                this.props?.createModel?.type === 'train-model' ||
                this.props?.createModel?.type === 'compile-model'
            )
            && 
            (
                this.props?.createModel?.type !== prevProps?.createModel?.type 
            )
        ) {
            this.setModelList()
            this.setSource()
        }

        if (
            (
                this.props?.createModel?.type.indexOf('create-train-model') !== -1 ||
                this.props?.createModel?.type.indexOf('create-compile-model') !== -1
            ) &&
            (this.props?.createModel?.type !== prevProps?.createModel?.type)
        ) {
            let response = await getDefaultConfig({
                name: '训练'
            })
            if (response.success) {
                this.setState({
                    task_type_list: response.payload.settings,
                    model_name: ''
                }, () => {
                    this.saveJson('set-algorithmName', {
                        modelVersionName: '-1',
                        algorithmName: this.state.task_type_list[0].value
                    })
                    this.props.onSetCreateModel({
                        algorithmName: this.state.task_type_list[0].value
                    })
                })
            }
            this.setState({
                action: this.props?.createModel?.type
            })
        }
    }
    closeDialog() {
        this.props.onSetCreateModel({
            show: false,
            type: '',
            // modelVersionName: '',
            // algorithmName: ''
        })
        if (this.train_timer) clearTimeout(this.train_timer)
    }
    /* 创建模型 */
    setModelName(e) {
        this.setState({
            model_name: e.target.value
        })
    }
    async cancle() {
        if (this.state.action) {
            await this.saveJson('remove')
            let option = this.state.action.split('-')
            this.setState({
                action: null
            }, () => {
                this.props.onSetCreateModel({
                    show: true,
                    type: option[1] + '-' + option[2]
                })
            })
        } else {
            this.props.onSetCreateModel({
                show: false,
                type: '',
                modelVersionName: '',
                algorithmName: ''
            })
        }
    }
    async confirm() {
        if (!this.state.model_name) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.modelNameCannotEmpty),
                type: 'fail'
            })
            return
        }
        let name = this.state.model_name
        if (this.state.action) {
            if (this.state.task_type_act === -1) {
                this.props.onChangeMessgae({
                    visible: true,
                    content: this.props.intl.formatMessage(LANG_MESSAGE.taskType),
                    type: 'fail'
                })
                return
            }
            let result = await this.saveJson('edit-name', { modelVersionName: name })
            if (!result) return false

            let option = this.state.action.split('-')
            this.setState({
                action: null,
                model_name: ''
            }, async () => {

                this.props.onSetCreateModel({
                    show: true,
                    type: option[1] + '-' + option[2]
                })
            })
        } else {
            let response = await getDefaultConfig({
                name: '训练'
            })
            if (response.success) {
                this.setState({
                    task_type_list: response.payload.settings,
                    model_name: ''
                })

                let result = await this.saveJson('set-name', {modelVersionName: name})
                if (!result) return false
    
                this.props.onSetCreateModel({
                    show: true,
                    type: 'task-type',
                    modelVersionName: name,
                    algorithmName: this.state.task_type_list[0].value
                })

                this.saveJson('set-algorithmName', {
                    modelVersionName: this.props.createModel.modelVersionName,
                    algorithmName: this.state.task_type_list[0].value
                })
            }   
        }
    }
    /* 任务类型 */
    taskListEvent(e) {
        let index = this.state.task_type_list.findIndex(item => item.value === e.target.innerText)
        if (index === this.state.task_type_act) return
        if (index !== -1) {
            this.saveJson('set-algorithmName', {
                modelVersionName: this.props.createModel.modelVersionName,
                algorithmName: this.state.task_type_list[index].value
            })

            this.setState({
                task_type_act: index
            }, () => {
                this.props.onSetCreateModel({
                    show: true,
                    algorithmName: this.state.task_type_list[index].value
                })
            })
        }
    }
    selectDataSet(type) {
        this.props.onSetBlocklyDialog({
            show: true,
            project: 'blocks',
            target: null,
            type: 'DATASET_' + type.toUpperCase() + '_IMPORT'
        })
    }
    async createDataSet() {
        localStorage.removeItem('create_msg')
        let response = await getDefaultConfig({ name: 'label' })
        if (response.success) {
            window.globalMsg.demiension = {
                show: true,
                url: response.payload.settings[0].value,
                date: new Date()
            }
        }
    }
    /* 训练模型 */
    menuClick(e) {
        let {type} =  this.props.createModel
        this.setState({
            [type.replace('-', '_') + '_type']: e.key
        })
    }
    changeModeType(type) {
        let new_type = this.state[type + '_model_type'] === 'ordinary' ? 'senior' : 'ordinary'
        this.setState({
            [type + '_model_type']: new_type
        })
    }
    changeSource(e) {
        let {type} =  this.props.createModel
        this.setState({
            [type.replace('-model', '') + '_source_val']: e
        })
    }
    changeSpecs(e) {
        let {type} =  this.props.createModel
        this.setState({
            [type.replace('-model', '') + '_specs_val']: e
        })
    }
    changeReasonSource(e) {
        this.setState({
            reason_source_val: e
        })
    }
    changeReasonSpecs(e) {
        this.setState({
            reason_specs_val: e
        })
    }
    async changeModel(e, option) {
        // if (this.state.model_list_val === e) return
        clearTimeout(this.train_timer)
        let data
        try {
            data = await JSON.parse(localStorage.getItem('mode_extend_variable')) || []
        } catch(err) {
            data = []
        }
        let item = data.filter((item) => item.modelVersionName === e)
        let train_detail = null, 
            train_model = null, 
            compile_detail = null, 
            compile_model = null,
            modelVersionName = e,
            is_select = false

        if (Array.isArray(item)) {
            if (item[0]?.jobId && !item[0]?.is_select) {
                // typeof option === 'boolean' &&
                if (this.props.createModel.type === 'train-model') {
                    this.setState({
                        task_type: 'train',
                    })
                    this.trainStatus(item[0].jobId)
                } else {
                    train_detail = await getTrainDetail(item[0].jobId)
                    if (train_detail.success) {
                        train_model = {
                            modelId: train_detail.payload.modelVersion ? train_detail.payload.modelVersion.modelId : null,
                            modelVersion: train_detail.payload.modelVersion ? train_detail.payload.modelVersion.version : null,
                            modelVersionName: train_detail.payload.modelVersion ? train_detail.payload.modelVersion.versionName : null,
                            modelDes: train_detail.payload.modelVersion ? train_detail.payload.modelVersion.descript : null,
                            algorithmId: train_detail.payload.model ? train_detail.payload.model.algorithmId : null
                        }
                    }
                }
            } else {
                if (item[0].train_detail) train_model = item[0].train_detail
            }

            if (item[0]?.compileId && !item[0]?.is_select) {
                // typeof option === 'boolean' &&
                if (this.props.createModel.type === 'compile-model') {
                    this.compileStatus(item[0].compileId)
                    // if (item[0].train_detail) train_model = item[0].train_detail
                } else {
                    compile_detail = await getCompileDetail(item[0].compileId)
                    if (compile_detail.success) {
                        compile_model = {
                            tpuModelId: compile_detail.payload.modelVersion ? compile_detail.payload.modelVersion.tpuModelId : null,
                            tpuModelVersion: compile_detail.payload.modelVersion ? compile_detail.payload.modelVersion.version : null
                        }
                    }   
                }
            } else {
                if (item[0].compile_detail) compile_model = item[0].compile_detail
            }

            if (item[0]?.is_select) {
                train_model = item[0]?.train_detail || null
                compile_model = item[0]?.compile_detail || null
                is_select = true
            }
        }

        this.saveJson('set-trainDetail', {
            modelVersionName,
            train_detail: train_model,
            compile_detail: compile_model,
            is_select
        })
        this.setState({
            model_list_val: e,
            train_suc_data: train_model,
            train_status: (train_model?.modelId && train_model?.modelVersion) ? 'succeeded' : '',
            compile_suc_data: compile_model,
            compile_status: (compile_model?.tpuModelId && compile_model?.tpuModelVersion) ? 'succeeded' : '',
            train_status_text: '',
            deployment_status_text: '',
            compile_status_text: ''
        }, () => {
            this.props.onSetCreateModel({
                show: true,
                modelVersionName: e,
                algorithmName: item[0]?.algorithmName || '',
                train_detail: train_model,
                compile_detail: compile_model,
                is_select: is_select
            })      
        })
    }
    // 创建训练任务
    // imgsz图像大小
    // epochs训练轮次
    // lr学习速率
    // batch-size批样本数
    async createTrainTask() {
        if (this.state.is_load) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.running),
                type: 'fail'
            })
            return
        }
        if (this.state.train_source.length === 0 || this.state.train_specs.length === 0) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.invalidConfiguration),
                type: 'fail'
            })
            return 
        }

        let {modelVersionName, data_set, algorithmName} = this.props.createModel
        if (!algorithmName) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.noTaskType),
                type: 'fail'
            })
            return 
        }
        if (!data_set) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.pleaseSelectDataset),
                type: 'fail'
            })
            return
        }

        // 自定参数校验
        let err_custom = [], custom_str = '', custom;
        let name = algorithmName.split('-')[1]
        if (this.state.custom_parame.length > 0) {
            custom = this.state.custom_parame.split('\n').map(itm => {
                let arr = itm.split('=')
                let alg = (name === '检测') ? detection : categorize
                if (alg.indexOf(arr[0]) != -1) {
                    custom_str += `--${arr[0]} ${arr.length > 1 ? arr[1] : ''} `
                    return arr
                } else {
                    if (arr[0]) err_custom.push(arr[0])
                    return
                }
            }).filter(Boolean)
        }
        if (err_custom.length > 0) {
            let str = err_custom.join(',')
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.matchingUnsuccessful, {str}),
                type: 'fail'
            })
            return
        }

        this.setState({
            task_type: 'train',
            deployment_status_text: '',
            is_load: true
        })

        let task = await getTrainList(1, 100, 'preparing', 'pending', 'running')
        if (task.success) {
            // 创建任务
            let create = async () => {
                clearTimeout(this.train_timer)
                let speces = this.state.train_specs.filter(item => item.id === this.state.train_specs_val)

                let obj = {
                    name: randomStr(),
                    desc: '',
                    versionName: modelVersionName,
                    dataSetId: data_set.id,
                    dataSetVersion: data_set.version,
                    weightId: '',
                    weightVersion: '',
                    stopMin: 0,
                    isDistributed: false,
                    resourcePool: this.state.train_source_val,
                    config: [{
                        command: '',
                        resourceSpecId: speces[0].id,
                        resourceSpecName: speces[0].name,
                        resourceSpecPrice: speces[0].price,
                        taskNumber: 1,
                        minFailedTaskCount: 1,
                        minSucceededTaskCount: 1,
                        replicaStates: null,
                        subTaskState: '',
                        envs: null,
                        parameters: [{
                            key: '',
                            value: ''
                        }]
                    }]
                }
                let command = null
                // 获取默认配置
                let config = await getDefaultConfig({name: this.props.createModel.algorithmName})
                if (config.success) {
                    config.payload.settings.forEach((item, index) => {
                        if (item.key === 'algorithmId') obj.algorithmId = item.value
                        if (item.key === 'algorithmVersion') obj.algorithmVersion = item.value
                        if (item.key === 'trainImageId') obj.imageId = item.value
                        if (item.key === 'trainCommand') command = item.value
                    })
                }

                // 模式处理
                let str = '', str1 = ''
                if (this.state.train_model_type === 'senior') { // 高级
                    str = `--epochs ${this.state.round} ` + 
                        `--batch-size ${this.state.batch_sample} ` +
                        `--lr ${this.state.learning_rate} ` +
                        `--imgsz ${this.state.image_size} ` + custom_str

                    str1 = `--imgsz ${this.state.image_size} `
                } else if (this.state.train_model_type === 'ordinary') { // 普通
                    str = `--epochs ${this.state.round} ` +
                    `--batch-size 2 `
                }
                command = command.replaceAll('{weights}', ' yolov5s.pt ')
                .replaceAll('{train-param}', str)
                .replaceAll('{export-param}', str1)
                .replaceAll('\\n', '\n')

                obj.config[0].command = command

                // 创建任务
                let response = await createJob(obj)

                if (response.success) {
                    this.trainStatus(response.payload.jobId)
                    this.saveJson('set-jobId', {
                        modelVersionName,
                        jobId: response.payload.jobId,
                        is_select: false
                    })
        
                    this.setState({
                        train_job_id: response.payload.jobId,
                        is_load: false
                    }, () => {
                        this.props.onSetCreateModel({
                            show: true,
                            jobId: response.payload.jobId,
                            is_select: false
                        })
                    })
                } else {
                    this.setState({
                        is_load: false
                    })
                }
            }

            if (task.payload.totalSize !== 0) {
                let ids = task.payload.trainJobs.map(item => {
                    return item.id
                })

                let message = this.props.intl.formatMessage(LANG_MESSAGE.endTraining)
                let person = window.confirm(message)
                if (person) {
                    let result = await stopAllTranin({ids})
                    if (result.success) {
                        create()
                    } else {
                        this.setState({
                            is_load: false
                        })
                    }
                } else {
                    this.setState({
                        is_load: false
                    })
                }
            } else {
                create()
            }
        } else {
            this.setState({
                is_load: false
            })
        }
    }
    async trainStatus(jobId) {
        let response = await getTrainStatus(jobId)
        if (response.success) {
            let {algorithmName} = this.props.createModel

            let scv, key, value, cur_round, accuracy_rate, loss_rate
            if (response.payload.trainJob.result) {
                scv = response.payload.trainJob.result.split('\n')
                key = scv[0].split(',').map(item => item.trim())
                
                value = scv[scv.length - 1].length > 0 ? 
                scv[scv.length - 1].split(',').map(item => item.trim()) :
                scv[scv.length - 2].split(',').map(item => item.trim())

                // 检测 epoch轮次 train/box_loss损失率 metrics/mAP_0.5平均精度（正确率）
                // 分类
                if (algorithmName.indexOf('检测') !== -1) {
                    cur_round = value[key.findIndex(item => item === 'epoch')]
                    accuracy_rate = value[key.findIndex(item => item === 'metrics/mAP_0.5')]
                    loss_rate = value[key.findIndex(item => item === 'train/box_loss')]
                } else if (algorithmName.indexOf('分类') !== -1) {
                    cur_round = value[key.findIndex(item => item === 'epoch')]
                    accuracy_rate = value[key.findIndex(item => item === 'metrics/accuracy_top1')]
                    loss_rate = value[key.findIndex(item => item === 'train/loss')]
                }
            }

            let status = this.changeStatus('train', response.payload.trainJob.status)
            this.setState({
                train_status: response.payload.trainJob.status,
                train_status_text: status,
                cur_round: cur_round || 0,
                accuracy_rate: accuracy_rate || 0,
                loss_rate: loss_rate || 0,
                train_time: formatDuring(response.payload.trainJob.runSec)
            })

            if (['succeeded', 'failed', 'stopped'].includes(response.payload.trainJob.status)) { 
                if (response.payload.trainJob.status === 'succeeded') {
                    
                    let detail = await getTrainDetail(jobId)
                    let model = null
                    if (detail.success) {
                        model = {
                            modelId: detail.payload.modelVersion.modelId,
                            modelVersion: detail.payload.modelVersion.version,
                            modelVersionName: detail.payload.modelVersion.versionName,
                            modelDes: detail.payload.modelVersion.descript,
                            algorithmId: response.payload.trainJob.algorithmId
                        }
                    }
                    this.saveJson('set-trainDetail', {
                        modelVersionName: detail.payload.modelVersion.versionName,
                        train_detail: model
                    })
                    
                    this.setState({
                        train_suc_data: model
                    }, () => {
                        this.props.onSetCreateModel({
                            show: true,
                            train_detail: model
                        })
                    })
                }
                clearTimeout(this.train_timer)
                return
            } else {
                this.train_timer = setTimeout(() => {
                    this.trainStatus(jobId)
                }, 1000 * 10)
            }
        } else {
            clearTimeout(this.train_timer)
        }
    }
    openCompileModel() {
        this.setState({
            conf_thres: 0.5,
            iou_thres: 0.45,
            upload_image: null,
            result_image: null,
            result_list: []
        })

        this.props.onSetCreateModel({
            show: true,
            type: 'compile-model'
        })

        this.getTpuList()
        this.getTpuDefaultList()
    }
    // 训练模型
    selectModel(type) {
        this.props.onSetBlocklyDialog({
            show: true,
            target: null,
            project: 'blocks',
            type: type.toUpperCase() + '_IMPORT'
        })
    }

    // 部署模型
    async deploymentModel() {
        if (this.state.is_load) return
        if (this.state.reason_source.length === 0 || this.state.reason_specs.length === 0) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.invalidConfiguration),
                type: 'fail'
            })
            return 
        }
        this.setState({
            task_type: 'cloud',
            train_status_text: '',
            is_load: true
        })

        let task = await getCloudList(1, 100, 'Preparing', 'Creating', 'Available')
        if (task.success) {
            // 创建任务
            let create = async () => {
                clearTimeout(this.train_timer)
                let str = randomStr()
                let speces = this.state.reason_specs.filter(item => item.id === this.state.reason_specs_val)
                let obj = {
                    name: str,
                    desc: str,
                    serviceType: 'http', // 暂时写死
                    modelFrame: 'pytorch', // 暂时写死

                    resourcePool: this.state.reason_source_val,
                    resourceType: 'cpu', // 暂时写死
                    resourceSpecId: speces[0].id,
                    modelId: this.state.train_suc_data.modelId,
                    modelVersion: this.state.train_suc_data.modelVersion,
                    domain: '',
                }
                
                // 获取默认配置
                let config = await getDefaultConfig({ name: 'tpusp' })
                if (config.success) {
                    obj.domain = config.payload.settings[0].value
                }

                let response = await createCloud(obj)
                if (response.success) {
                    let deployment = {
                        cloud_id: response.payload.serviceId,
                        cloud_url: response.payload.serviceUrl
                    }
                    this.deploymentStatus(response.payload.serviceId)
                    let {modelVersionName} = this.props.createModel
                    this.saveJson('set-deployment', {
                        modelVersionName,
                        deployment
                    })

                    this.setState({
                        deployment,
                        is_load: false
                    }, () => {
                        this.props.onSetCreateModel({
                            show: true,
                            deployment
                        })
                    })
                } else {
                    this.setState({
                        is_load: false
                    })
                }
            }

            if (task.payload.totalSize !== 0) {
                let ids = task.payload.depInfos.map(item => {
                    return item.id
                })
                let message = this.props.intl.formatMessage(LANG_MESSAGE.endReasoning)
                let person = window.confirm(message)
                if (person) {
                    let result = await stopAllClound({ids})
                    if (result.success) {
                        create()
                    } else {
                        this.setState({
                            is_load: false
                        })
                    }
                } else {
                    this.setState({
                        is_load: false
                    })
                }
            } else {
                create()
            }
        } else {
            this.setState({
                is_load: false
            })
        }
    }
    async deploymentStatus(id) {
        let response = await getCloudStatus(id)
        if (response.success) {
            let status = this.cloudTaskStatus(response.payload.depInfo.status)
            this.setState({
                deployment_status: response.payload.depInfo.status,
                deployment_status_text: status,
                deployment_time: formatDuring(response.payload.depInfo.runSec)
            })

            if (['Failed', 'Stopped', 'Available'].includes(response.payload.depInfo.status)) { 
                clearTimeout(this.train_timer)
                return
            } else {
                this.train_timer = setTimeout(() => this.deploymentStatus(id), 1000 * 10)
            }
        }
    }
    // 云推理图片
    async cloudResult() {
        let response = await getCloudResult({
            serviceUrl: this.state.deployment.cloud_url,
            image: this.state.upload_image.replace(/^data:image\/\w+;base64,/, ""),
            width: +this.state.image_size,
            height: +this.state.image_size,
            confThres: +this.state.conf_thres,
            iouThres: +this.state.iou_thres
        })
        if (response.success) {
            let res = this.initData(JSON.parse(response.payload.response))
            let data
            try {
                data = JSON.parse(res)
            } catch(err) { console.error('格式不正确')}
            this.drawResultImg('cloud', data)
            this.setState({
                result_list: data,
                result_state: false
            })
        } else {
            this.setState({
                result_list: [],
                result_image: this.state.upload_image,
                result_state: false
            })
        }
    }

    
    // 编译模型
    async createCompileTask() {
        if (this.state.is_load) return
        if (this.state.compile_source.length === 0 || this.state.compile_specs.length === 0) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.invalidConfiguration),
                type: 'fail'
            })
            return 
        }
        let { modelVersionName, train_detail, data_set } = this.props.createModel

        if (!data_set) {
            this.props.onChangeMessgae({
                visible: true,
                content: '请选择编译的数据集',
                type: 'fail'
            })
            return
        }

        this.setState({
            is_load: true
        })
        let list = await getCompileList(1, 100, 'preparing', 'pending', 'running')
        if (list.success) {
            let create = async () => {
                clearTimeout(this.train_timer)
                let speces = this.state.compile_specs.filter(item => item.id === this.state.compile_specs_val)

                let obj = {
                    isDistributed: false,
                    name: randomStr(),
                    resourcePool: this.state.compile_source_val,
                    modelId: train_detail.modelId,
                    modelVersion: train_detail.modelVersion,
                    versionName: train_detail.modelVersionName,
                    imageId: '',
                    desc: train_detail.modelDes,
                    datasetId: data_set.id,
                    datasetVersion: data_set.version,
                    config: [
                        {
                            envs: null,
                            name: '',
                            command: '',
                            isMainRole: false,
                            parameters: [
                                {
                                    key: 'onnx_model',
                                    value: '/model/best.onnx'
                                }
                            ],
                            taskNumber: 1,
                            shareMemory: null,
                            minFailedTaskCount: 1,
                            minSucceededTaskCount: 1,
                            resourceSpecPrice: 0,
                            resourceSpecId: speces[0].id,
                            resourceSpecName: speces[0].name
                        }
                    ]
                }

                // 筛选算法类型
                let algorithmId_type
                let algorithm = await getDefaultConfig({ name: '训练' })
                if (algorithm.success) {
                    algorithmId_type = algorithm.payload.settings[0].value
                }

                // 获取默认配置
                let config = await getDefaultConfig({ name: algorithmId_type })
                if (config.success) {
                    config.payload.settings.forEach((item, index) => {
                        if (item.key === this.state.tpu_type_val + '-compileCommand') obj.config[0].command = item.value
                        if (item.key === this.state.tpu_type_val + '-compileImageId') obj.imageId = item.value
                    })
                }

                let str, str1
                if (this.state.compile_model_type === 'senior') {
                    str = '--model_input1_shape3=' + this.state.compile_size_width
                    str1 = '--model_input1_shape4=' + this.state.compile_size_height
                } else if (this.state.compile_model_type === 'ordinary') {
                    str = '--model_input1_shape3=' + 640
                    str1 = '--model_input1_shape4=' + 640
                }
                obj.config[0].command = obj.config[0].command
                    .replaceAll('{compile-param1}', str)
                    .replaceAll('{compile-param2}', str1)
                    .replaceAll('\\n', '\n')

                let response = await createCompilationTask(obj)
                if (response.success) {
                    let compileId = response.payload.jobId
                    this.compileStatus(compileId)
                    this.saveJson('set-compileId', {
                        modelVersionName,
                        compileId,
                        compile_algorithmName: this.state.tpu_type_val,
                        is_select: false
                    })

                    this.setState({
                        compile_id: compileId,
                        is_load: false
                    }, () => {
                        this.props.onSetCreateModel({
                            show: true,
                            compileId,
                            compile_algorithmName: this.state.tpu_type_val,
                            is_select: false
                        })
                    })
                } else {
                    this.setState({
                        is_load: false
                    })
                }
            }
            if (list.payload.totalSize !== 0) {
                let ids = list.payload.compileJobs.map(item => {
                    return item.id
                })
                let message = this.props.intl.formatMessage(LANG_MESSAGE.endCompilation)
                let person = window.confirm(message)
                if (person) {
                    let result = await stopAllCompile({ids})
                    if (result.success) {
                        create()
                    }
                    else {
                        this.setState({
                            is_load: false
                        })
                    }
                } else {
                    this.setState({
                        is_load: false
                    })
                }
            } else {
                create()
            }
        } else {
            this.setState({
                is_load: false
            })
        }
    }
    async compileStatus(jobId) {
        let response = await getCompileStatus(jobId)
        if (response.success) {
            let status = this.changeStatus('compile', response.payload.compileJob.status)
            this.setState({
                compile_status: response.payload.compileJob.status,
                compile_status_text: status,
                compile_time: formatDuring(response.payload.compileJob.runSec)
            })

            if (['succeeded', 'failed', 'stopped'].includes(response.payload.compileJob.status)) { 
                if (response.payload.compileJob.status === 'succeeded') {
                    getCompileLog(jobId)
                        .then((response) => {
                            let data = response.substr(response.lastIndexOf('similarity: '), 16).split(':')[1]
                            this.setState({
                                compile_similarity: data.trim() * 100
                            })
                        })
                        .catch(() => {})


                    let { modelVersionName } = this.props.createModel
                    let detail = await getCompileDetail(jobId)
                    let model = null
                    if (detail.success) {
                        model = {
                            tpuModelId: detail.payload.modelVersion.tpuModelId,
                            tpuModelVersion: detail.payload.modelVersion.version
                        }
                    }
                    this.saveJson('set-compileDetail', {
                        modelVersionName,
                        compile_detail: model
                    })
                    
                    this.setState({
                        compile_suc_data: model
                    }, () => {
                        this.props.onSetCreateModel({
                            show: true,
                            compile_detail: model
                        })
                    })
                }
                clearTimeout(this.train_timer)
                return
            } else {
                this.train_timer = setTimeout(() => {
                    this.compileStatus(jobId)
                }, 1000 * 10)
            }
        }
    }

    // 存储数据
    async saveJson(type, params) {
        let data = []
        try {
            data = await JSON.parse(localStorage.getItem('mode_extend_variable')) || []
        } catch(error) {
            data = []
        }

        if (type === 'set-name') {
            if (data.length > 0) {
                let index = data.findIndex(item => item.modelVersionName === params.modelVersionName)
                if (index !== -1) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.modelNameIsDuplicate),
                        type: 'fail'
                    })
                    return false
                }
            }
            data.push({
                modelVersionName: params.modelVersionName
            })
        } else if (type === 'edit-name')  {
            if (data.length > 0) {
                let index = data.findIndex(item => item.modelVersionName === params.modelVersionName)
                if (index !== -1) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.modelNameIsDuplicate),
                        type: 'fail'
                    })
                    return false
                }
                let idx = data.findIndex(item => item.modelVersionName === '-1')
                data[idx].modelVersionName = params.modelVersionName
                if (this.state.action.indexOf('train') !== -1) {
                    data[idx].train_detail.modelVersionName = params.modelVersionName
                }
            }
        } else if (type === 'remove') {
            if (data.length > 0) {
                let index = data.findIndex(item => item.modelVersionName === '-1')
                data.splice(index, 1)
            }
        } else if ([
            'set-algorithmName', 
            'set-jobId',
            'set-trainDetail',
            'set-deployment',
            'set-compile-algorithmName',
            'set-compileId',
            'set-compileDetail'
        ].includes(type)) {
            data.forEach(item => {
                if (item.modelVersionName === params.modelVersionName) {
                    if (params?.algorithmName) item['algorithmName'] = params.algorithmName
                    if (params?.jobId) item['jobId'] = params.jobId
                    if (params?.train_detail) item['train_detail'] = params.train_detail
                    if (params?.deployment) item['deployment'] = params.deployment
                    if (params?.compile_algorithmName) item['compile_algorithmName'] = params.compile_algorithmName
                    if (params?.compileId) item['compileId'] = params.compileId
                    if (params?.compile_detail) item['compile_detail'] = params.compile_detail
                    if (params.hasOwnProperty('is_select')) item['is_select'] = params.is_select
                }
            })
        }

        localStorage.setItem('mode_extend_variable', JSON.stringify(data))
        return true
    }
    // 训练任务状态
    changeStatus(type, status) {
        switch(status) {
            case 'preparing':
                return type === 'train' ? 
                    this.props.intl.formatMessage(LANG_MESSAGE.trainingInitialization) : 
                    this.props.intl.formatMessage(LANG_MESSAGE.compileInitialization)
            case 'pending':
                return type === 'train' ? 
                    this.props.intl.formatMessage(LANG_MESSAGE.trainingWaiting) : 
                    this.props.intl.formatMessage(LANG_MESSAGE.compilationWaiting)
            case 'running':
                return type === 'train' ? 
                    this.props.intl.formatMessage(LANG_MESSAGE.trainingInProgress) : 
                    this.props.intl.formatMessage(LANG_MESSAGE.compiling)
            case 'failed':
                return type === 'train' ? 
                    this.props.intl.formatMessage(LANG_MESSAGE.trainingFailed) : 
                    this.props.intl.formatMessage(LANG_MESSAGE.compilationFailed)
            case 'succeeded':
                return type === 'train' ? 
                    this.props.intl.formatMessage(LANG_MESSAGE.trainingSuccessful) : 
                    this.props.intl.formatMessage(LANG_MESSAGE.compilationSuccessful)
            case 'stopped':
                return type === 'train' ? 
                    this.props.intl.formatMessage(LANG_MESSAGE.trainingStopped) : 
                    this.props.intl.formatMessage(LANG_MESSAGE.compilationStopped)
            default:
                return ''
        }
    }
    // 云部署任务状态
    cloudTaskStatus(type) {
        switch(type) {
            case 'Preparing':
                return this.props.intl.formatMessage(LANG_MESSAGE.cloudDeploymentInitial)
            case 'Creating':
                return this.props.intl.formatMessage(LANG_MESSAGE.cloudDeploymentCreation)
            case 'Available':
                return this.props.intl.formatMessage(LANG_MESSAGE.cloudDeploymentAndOperation)
            case 'Failed':
                return this.props.intl.formatMessage(LANG_MESSAGE.cloudDeploymentFailed)
            case 'Stopped':
                return this.props.intl.formatMessage(LANG_MESSAGE.cloudDeploymentStopped)
            default:
                return ''
        }
    }
    async downloadModelConfirm() {
        if (this.state.is_load) return
        if (!this.state.down_model_name) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.enterName),
                type: 'fail'
            })
            return
        }
        if (
            (this.state.confThres.length === 0 || +this.state.confThres < 0 || +this.state.confThres > 1) ||
            (this.state.iouThres === 0 || +this.state.iouThres < 0 || +this.state.iouThres > 1)
        ) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.range),
                type: 'fail'
            })
            return
        }
        if (
            (this.state.down_model_width.length === 0 || +this.state.down_model_width < 0) ||
            (this.state.down_model_height.length === 0 || +this.state.down_model_height < 0)
        ) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.enterNumber),
                type: 'fail'
            })
            return
        }
        this.setState({
            is_load: true
        })
        let { modelVersionName, compile_detail } = this.props.createModel

        let config = await getDefaultConfig({ name: 'tpusp' })
        if (config.success) {
            let response = await getDownloadModel(
                compile_detail.tpuModelId,
                compile_detail.tpuModelVersion,
                config.payload.settings[0].value
            )

            if (response.success) {
                this.setState({
                    is_down: true
                })
                axios({
                    method: 'post',
                    url: BASE_URL + `/transfer`,
                    timeout: '',
                    data: {
                        url: response.payload.downloadUrl,
                        modelName: this.state.down_model_name,
                        isCover: this.state.is_cover,
                        conf: {
                            imgsz: [this.state.down_model_width, this.state.down_model_height],
                            confThres: +this.state.confThres,
                            iouThres: +this.state.iouThres
                        },
                        modelId: compile_detail.tpuModelId,
                        modelVersion: compile_detail.tpuModelVersion
                    }
                })
                    .then((res) => {
                        if (res.data.msg === '固化成功！') {
                            this.getTpuDefaultList()

                            this.setState({
                                down_model_show: false,
                                down_model_name: '',
                                down_model_width: 640,
                                down_model_height: 640,
                                is_cover: true,

                                is_down: false,
                                down_state: true,
                                down_state_show: true,
                                is_load: false
                            })
                        } else {
                            this.setState({
                                is_down: false,
                                down_state: false,
                                down_state_show: true,
                                is_load: false
                            })
                        }
                    })
                    .catch(() => {
                        this.setState({
                            is_down: false,
                            down_state: false,
                            down_state_show: true,
                            is_load: false
                        })
                    })
            } else {
                this.setState({
                    is_load: false
                })
            }
        } else {
            this.setState({
                is_load: false
            })
        }
    }
    // tpu推理
    tpuResult() {
        axios({
            method: 'post',
            timeout: '',
            url: BASE_URL + `/predict`,
            data: {
                data: this.state.upload_image.replace(/^data:image\/\w+;base64,/, ""),
                confThres: +this.state.conf_thres,
                iouThres: +this.state.iou_thres,
                width: +this.state.compile_size_width,
                height: +this.state.compile_size_height,
                modelName: this.state.tpu_test_val
            }
        })
            .then(response => {
                if (response.data.code === 200 && response.data.data.length > 0) {
                    let res = this.initData(response.data.data)
                    let data
                    try {
                        data = JSON.parse(res)
                    } catch(err) { console.error('格式不正确') }
                    this.drawResultImg('tpu', data)
                    this.setState({
                        result_list: data,
                        result_state: false
                    })
                } else {
                    this.setState({
                        result_list: [],
                        result_image: this.state.upload_image,
                        result_state: false
                    })
                }
            })
            .catch(() => {})
    }
    changeTpuTest(e) {
        this.setState({
            tpu_test_val: e
        })
    }
    // 推理结果画框
    drawResultImg(type, data) {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        let width = type === 'tpu' ? this.state.compile_size_width : this.state.image_size
        let height = type === 'tpu' ? this.state.compile_size_height : this.state.image_size
        canvas.width = width
        canvas.height = height

        let img = new Image()
        img.width = width
        img.height = height

        let colors = []
        img.onload = () => {
            ctx.drawImage(img, 0, 0, img.width, img.height)
            let draw_itm, draw_color
            data.forEach((item) => {
                if (item.data.length > 0) {
                    draw_itm = item.data.sort((a,b) => b.probability - a.probability)
                    let index = colors.findIndex((color) => color.label === draw_itm[0].lable)
                
                    if (index === -1) {
                        draw_color = getRandomColor()
                        colors.push({
                            tag_name: draw_itm[0].lable,
                            color: draw_color
                        })
                    } else {
                        draw_color = colors[index].color
                    }
                    ctx.strokeStyle = draw_color
                    ctx.strokeRect(
                        item.left_up_x, 
                        item.left_up_y, 
                        item.right_down_x - item.left_up_x,
                        item.right_down_y - item.left_up_y
                    );
                    ctx.font = "24px serif";
                    ctx.fillText(draw_itm[0].lable, item.left_up_x + 2,  item.left_up_y + 16)
                }
            })

            let base64 = canvas.toDataURL()
            this.setState({
                result_image: base64
            })
        }
        img.src = this.state.upload_image
    }
    // 推理数据格式化
    initData(data, num) {
        let params = [];
        var _data = JSON.stringify(data);
        _data = _data.replace(/(confidence|confidens|score)/g,'probability');
        _data = _data.replace(/(tag_name|name)/g,'lable');
        data = JSON.parse(_data);
        if (Array.isArray(data)) {
            for (let key in data) {
                let item = {
                    data: [{
                        lable: '',
                        probability: 0,
                        address: ''
                    }],
                    // probability: 0,
                    // address: '',
                    // name: '',
                    left_up_x: 0,
                    left_up_y: 0,
                    right_down_x: 0,
                    right_down_y: 0,
                    // result: false, // result 转 probability
                };
                
                if (data[key].hasOwnProperty('data')) {
                    let _datas = data[key].data;
                    if (num > 0){
                        _datas.forEach(element => {
                            element.probability = element.probability * num;
                        });
                    }
                    var list = this._sortKeyDesc(_datas, 'probability'); // 排序
                    if (list.length > 9) {
                        _datas = list.slice(0, 9);
                    } else {
                        _datas = list;
                    }
                    let _list = [];
                    for(let i = 0; i < _datas.length; i++) {
                        let _item_data = {
                            lable: '',
                            probability: 0,
                            address: ''
                        };
                        if (_datas[i].hasOwnProperty('lable')) {
                            _item_data.lable = _datas[i].lable;
                        }
                        if (_datas[i].hasOwnProperty('probability')) {
                            _item_data.probability = _datas[i].probability;
                            // _item_data.probability = _datas[i].probability.toFixed(5);
                        }
                        if (_datas[i].hasOwnProperty('address')) {
                            _item_data.address = _datas[i].address;
                        }
                        _list.push(_item_data);
                    }
                    item.data = _list;

                }
                if (data[key].hasOwnProperty('probability')) {
                    item.data[0].probability = data[key].probability;
                }
                if (data[key].hasOwnProperty('address')) {
                    item.data[0].address = data[key].address;
                }
                if (data[key].hasOwnProperty('lable')) {
                    item.data[0].lable = data[key].lable;
                }
                if (data[key].hasOwnProperty('left_up')) {
                    item.left_up_x = data[key].left_up[0];
                    item.left_up_y = data[key].left_up[1];
                }
                if (data[key].hasOwnProperty('right_down')) {
                    item.right_down_x = data[key].right_down[0];
                    item.right_down_y = data[key].right_down[1];
                }
                params.push(item);
            }
        } else {
            let item = {
                data: [{
                    lable: '',
                    probability: 0,
                    address: ''
                }],
                // probability: 0,
                // address: '',
                // name: '',
                left_up_x: 0,
                left_up_y: 0,
                right_down_x: 0,
                right_down_y: 0,
                // result: false,
            };
            if (data.hasOwnProperty('left_up')) {
                item.left_up_x = data.left_up[0];
                item.left_up_y = data.left_up[1];
                delete data.left_up;
            }
            if (data.hasOwnProperty('right_down')) {
                item.right_down_x = data.right_down[0];
                item.right_down_y = data.right_down[1];
                delete data.right_down;
            }
            if (data.hasOwnProperty('result')) {
                if (data.result == "true") {
                    item.data[0].probability = 1;
                } else {
                    item.data[0].probability = 0;
                }
            }
            if (data.hasOwnProperty('probability')) {
                item.probability = data.probability;
            }
            params.push(item);
        }
        params = params.sort(function (a, b) {
            var x = a.data[0].probability;
            var y = b.data[0].probability;
            return ((x > y) ? - 1 : (x < y) ? 1 : 0)
        })
        return JSON.stringify(params);
    }
    _sortKeyDesc(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return ((x > y) ? - 1 : (x < y) ? 1 : 0);
        })
    }
    // 模型名称列表
    setModelList(is_check = true) {
        let { modelVersionName } = this.props.createModel
        let default_name = ''
        let model_list
        try {
            model_list = JSON.parse(localStorage.getItem('mode_extend_variable')) || []
        } catch(err) {
            model_list = []
        }

        if (model_list.length > 0) {
            default_name = model_list[0].modelVersionName
        }
        if (modelVersionName) this.changeModel(modelVersionName, is_check)
        else if (default_name) this.changeModel(default_name)
        this.setState({
            model_list,
            model_list_val: modelVersionName || default_name || '',
        })
    }
    onDropdownModelChange(e) {
        if (e) this.setModelList(false)
    }

    // 资源池、资源池规格
    async setSource() {
        let source
        try {
            source = await JSON.parse(sessionStorage.getItem('space')) || null
        } catch(err) {
            source = null
        }
        if (source !== null && source?.resourcePools.length > 0) {

            let first_source = source.resourcePools[0]
            let train_specs = [], reason_specs = [], compile_specs = [],
                first_train_specs = '', first_reason_specs = '', first_compile_specs = ''
            let specs = await getResourceSpec({
                resourcePool: first_source
            }) 
            
            if (specs.success) {
                if (specs.payload.mapResourceSpecIdList?.train?.resourceSpecs.length > 0) {
                    train_specs = specs.payload.mapResourceSpecIdList.train.resourceSpecs
                    first_train_specs = specs.payload.mapResourceSpecIdList.train.resourceSpecs[0].id
                }

                if (specs.payload.mapResourceSpecIdList?.deploy?.resourceSpecs.length > 0) {
                    reason_specs = specs.payload.mapResourceSpecIdList.deploy.resourceSpecs
                    first_reason_specs = specs.payload.mapResourceSpecIdList.deploy.resourceSpecs[0].id
                }

                if (specs.payload.mapResourceSpecIdList?.compile?.resourceSpecs.length > 0) {
                    compile_specs = specs.payload.mapResourceSpecIdList.compile.resourceSpecs
                    first_compile_specs = specs.payload.mapResourceSpecIdList.compile.resourceSpecs[0].id
                }
            }

            this.setState({
                train_source: source?.resourcePools || [],
                train_source_val: first_source,

                train_specs,
                train_specs_val: first_train_specs,

                reason_source: source?.resourcePools || [],
                reason_source_val: first_source,

                reason_specs,
                reason_specs_val: first_reason_specs,

                compile_source: source?.resourcePools || [],
                compile_source_val: first_source,

                compile_specs,
                compile_specs_val: first_compile_specs,
            })
        } else {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.invalidConfiguration),
                type: 'fail'
            })
        }
    }
    // TPU类型
    getTpuList() {
        getDefaultConfig({ name: '编译'})
            .then((config) => {
                if (config.success && config.payload.settings.length > 0) {
                    let arr = config.payload.settings.filter(item => item.value === 'tpu')
                    let first_val = arr[0].value
                    this.setState({
                        tpu_list: config.payload.settings,
                        tpu_type_val: first_val
                    })
                } else {
                    this.setState({
                        tpu_list: []
                    })
                }
            })
            .catch(() => {})
    }
    // 插件TPU数据
    getTpuDefaultList(refresh = false) {
        axios({
            method: 'get',
            url: BASE_URL + `/getModelList`,
            timeout: ''
        })
            .then((response) => {
                if (response.data.code === 200 && response.data.data.length > 0) {
                    let first_val = response.data.data[0].model_name
                    this.setState({
                        tpu_test_list: response.data.data,
                        tpu_test_val: refresh ? tpu_test_val : first_val
                    })
                } else {
                    this.setState({
                        tpu_test_list: []
                    })
                }
            })
            .catch(() => {})
    }

    // 拍照功能
    changeTakePhoto(type) {
        this.setState({
            take_photo: type
        })
    }
    takePhotoConfirm(callback) {
        let data = this.refs.takePhoto
        if (!data.state.photo) {
            window.alert(this.props.intl.formatMessage(LANG_MESSAGE.pleaseTakePhoto))
            return
        } else {
            this.setState({
                upload_image: data.state.photo,
                result_state: true
            }, () => {
                if (callback) callback()
                this.changeTakePhoto(false)
                if (this.state.upload_type === 'train') this.cloudResult()
                else if (this.state.upload_type === 'compile') this.tpuResult()
            })
        }
    }
    // tpu插件
    onDropdownVisibleChange(e) {
        if (e) {
            this.getTpuDefaultList(true)
        }
    }

    // 基本参数设置
    setRound(e) {
        this.setState({
            round: e.target.value
        })
    }
    setImageSize(e) {
        this.setState({
            image_size: e.target.value
        })
    }
    setBatchSample(e) {
        this.setState({
            batch_sample: e.target.value
        })
    }
    setLearningRate(e) {
        this.setState({
            learning_rate: e.target.value
        })
    }
    setWidth(e) {
        this.setState({
            compile_size_width: e.target.value
        })
    }
    setHeight(e) {
        this.setState({
            compile_size_height: e.target.value
        })
    }
    setTpuType(e) {
        this.setState({
            tpu_type_val: e
        })
    }
    setConfThres(e) {
        this.setState({
            conf_thres: e.target.value
        })
    }
    setIouThres(e) {
        this.setState({
            iou_thres: e.target.value
        })
    }
    setUploadType(type) {
        this.setState({
            upload_type: type
        })
    }
    setPreview(type) {
        this.setState({
            preview: type
        })
    }
    setDownStateShow(type) {
        this.setState({
            down_state_show: type
        })
    }
    setDownModelShow(type){
        this.setState({
            down_model_show: type,
            down_model_name: '',
            down_model_width: 640,
            down_model_height: 640,
            is_cover: true
        })
    }
    setDownModelName(e) {
        this.setState({
            down_model_name: e.target.value
        })
    }
    setDownModelWidth(e) {
        this.setState({
            down_model_width: e.target.value
        }, () => {
            if (this.input_timer) clearTimeout(this.input_timer)
            this.input_timer = setTimeout(() => {
                if (this.state.down_model_width.length === 0 || +this.state.down_model_width < 0) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.enterNumber),
                        type: 'fail'
                    })
                }
            }, 500)
        })
    }
    setDownModelHeight(e) {
        this.setState({
            down_model_height: e.target.value
        }, () => {
            if (this.input_timer) clearTimeout(this.input_timer)
            this.input_timer = setTimeout(() => {
                if (this.state.down_model_height.length ===0 || +this.state.down_model_height < 0) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.enterNumber),
                        type: 'fail'
                    })
                }
            }, 500)
        })
    }
    setCover(e) {
        this.setState({
            is_cover: e.target.value
        })
    }
    setCustomParame(e, type) {
        this.setState({
            custom_parame: e.target.value
        })
    }
    setDownModelTh(e) {
        this.setState({
            confThres: e.target.value
        }, () => {
            if (this.input_timer) clearTimeout(this.input_timer)
            this.input_timer = setTimeout(() => {
                if (this.state.confThres.length === 0 || +this.state.confThres > 1 || +this.state.confThres < 0) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.range),
                        type: 'fail'
                    })
                }
            }, 500)
        })
    }
    setDownModelIo(e) {
        this.setState({
            iouThres: e.target.value
        }, () => {
            if (this.input_timer) clearTimeout(this.input_timer)
            this.input_timer = setTimeout(() => {
                if (this.state.iouThres.length === 0 || +this.state.iouThres > 1 || +this.state.iouThres < 0) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.range),
                        type: 'fail'
                    })
                }
            }, 500)
        })
    }

    render() {
        return (
            <div className={styles.createModelContainer}>
                {/* 创建模型 */}
                {
                    (
                        this.props.createModel.type === 'create-model' ||
                        this.state.action != null
                    )
                ? (
                    <Create 
                        model_name={this.state.model_name}
                        closeDialog={this.closeDialog}
                        setModelName={this.setModelName}
                        cancle={this.cancle}
                        confirm={this.confirm}
                        intl={this.props.intl}
                        LANG_MESSAGE={LANG_MESSAGE}

                        task_type_list={this.state.task_type_list}
                        task_type_act={this.state.task_type_act}
                        action={this.state.action}
                        taskListEvent={this.taskListEvent}
                    />
                ) : null}
                {/* 任务类型 */}
                {this.props.createModel.type === 'task-type' ? (
                    <TaskType 
                        task_type_list={this.state.task_type_list}
                        task_type_act={this.state.task_type_act}
                        intl={this.props.intl}
                        LANG_MESSAGE={LANG_MESSAGE}
                        closeDialog={this.closeDialog}
                        taskListEvent={this.taskListEvent}
                        selectDataSet={this.selectDataSet}
                        createDataSet={this.createDataSet}
                        // onSetBlocklyDialog={this.props.onSetBlocklyDialog}
                    />
                ) : null}
                {/* 训练模型 */}
                {this.props.createModel.type === 'train-model' ? (
                    <TrainModel 
                        data={this.props.createModel}
                        type={this.state.train_model_type}
                        /* 训练参数 */
                        round={this.state.round}
                        image_size={this.state.image_size}
                        batch_sample={this.state.batch_sample}
                        learning_rate={this.state.learning_rate}
                        /* 模型名称列表 */
                        model_list={this.state.model_list}
                        model_list_val={this.state.model_list_val}
                        /* 训练资源池，资源池规格 */
                        train_source={this.state.train_source}
                        train_source_val={this.state.train_source_val}
                        train_specs={this.state.train_specs}
                        train_specs_val={this.state.train_specs_val}
                        /* 部署资源池，资源池规格 */
                        reason_source={this.state.reason_source}
                        reason_source_val={this.state.reason_source_val}
                        reason_specs={this.state.reason_specs}
                        reason_specs_val={this.state.reason_specs_val}
                        /* 训练状态 */
                        train_status={this.state.train_status}
                        train_status_text={this.state.train_status_text}
                        /* 部署状态 */
                        deployment_status={this.state.deployment_status}
                        deployment_status_text={this.state.deployment_status_text}
                        /* 部署参数 */
                        upload_image={this.state.upload_image}
                        conf_thres={this.state.conf_thres}
                        iou_thres={this.state.iou_thres}
                        /* 推理结果 */
                        cur_round={this.state.cur_round}
                        accuracy_rate={this.state.accuracy_rate}
                        loss_rate={this.state.loss_rate}
                        result_image={this.state.result_image}
                        result_list={this.state.result_list}
                        result_state={this.state.result_state}

                        train_time={this.state.train_time}
                        deployment_time={this.state.deployment_time}
                        task_type={this.state.task_type}
                        custom_parame={this.state.custom_parame}
                        is_load={this.state.is_load}
                        intl={this.props.intl}
                        LANG_MESSAGE={LANG_MESSAGE}

                        closeDialog={this.closeDialog}
                        menuClick={this.menuClick}
                        changeModeType={this.changeModeType}
                        selectDataSet={this.selectDataSet}
                        setRound={this.setRound}
                        setImageSize={this.setImageSize}
                        setBatchSample={this.setBatchSample}
                        setLearningRate={this.setLearningRate}
                        changeModel={this.changeModel}
                        changeSource={this.changeSource}
                        changeSpecs={this.changeSpecs}
                        changeReasonSource={this.changeReasonSource}
                        changeReasonSpecs={this.changeReasonSpecs}
                        createTrainTask={this.createTrainTask}
                        deploymentModel={this.deploymentModel}
                        setUploadType={this.setUploadType}
                        openCompileModel={this.openCompileModel}
                        setConfThres={this.setConfThres}
                        setIouThres={this.setIouThres}
                        setPreview={this.setPreview}
                        changeTakePhoto={this.changeTakePhoto}
                        setCustomParame={this.setCustomParame}
                        selectModel={this.selectModel}
                        onDropdownModelChange={this.onDropdownModelChange}
                    />
                ) : null}
                {/* TPU测试 */}
                {this.props.createModel.type === 'compile-model' ? (
                    <CompileModel 
                        data={this.props.createModel}
                        type={this.state.compile_model_type}
                        width={this.state.compile_size_width}
                        height={this.state.compile_size_height}
                        model_list={this.state.model_list}
                        model_list_val={this.state.model_list_val}

                        source={this.state.compile_source}
                        compile_source_val={this.state.compile_source_val}
                        specs={this.state.compile_specs}
                        compile_specs_val={this.state.compile_specs_val}

                        tpu_list={this.state.tpu_list}
                        tpu_type_val={this.state.tpu_type_val}

                        compile_status={this.state.compile_status}
                        compile_status_text={this.state.compile_status_text}
                        compile_similarity={this.state.compile_similarity}

                        tpu_test_list={this.state.tpu_test_list}
                        tpu_test_val={this.state.tpu_test_val}

                        conf_thres={this.state.conf_thres}
                        iou_thres={this.state.iou_thres}
                        tpu_state={this.props.tpu_state}

                        upload_image={this.state.upload_image}
                        result_list={this.state.result_list}
                        result_image={this.state.result_image}
                        train_status={this.state.train_status}
                        result_state={this.state.result_state}

                        compile_time={this.state.compile_time}
                        intl={this.props.intl}
                        LANG_MESSAGE={LANG_MESSAGE}

                        closeDialog={this.closeDialog}
                        menuClick={this.menuClick}
                        changeModeType={this.changeModeType}
                        selectDataSet={this.selectDataSet}
                        changeModel={this.changeModel}
                        setWidth={this.setWidth}
                        setHeight={this.setHeight}
                        changeSource={this.changeSource}
                        changeSpecs={this.changeSpecs}
                        createCompileTask={this.createCompileTask}
                        setTpuType={this.setTpuType}
                        setDownModelShow={this.setDownModelShow}
                        setConfThres={this.setConfThres}
                        setIouThres={this.setIouThres}
                        setUploadType={this.setUploadType}
                        setPreview={this.setPreview}
                        changeTpuTest={this.changeTpuTest}
                        changeTakePhoto={this.changeTakePhoto}
                        onDropdownVisibleChange={this.onDropdownVisibleChange}
                        selectModel={this.selectModel}
                        onDropdownModelChange={this.onDropdownModelChange}
                    />
                ) : null}
                {/* 下载模型 */}
                {this.state.down_model_show ? (
                    <DownloadModel
                        name={this.state.down_model_name}
                        width={this.state.down_model_width}
                        height={this.state.down_model_height}
                        confThres={this.state.confThres}
                        iouThres={this.state.iouThres}
                        is_cover={this.state.is_cover}
                        is_down={this.state.is_down}
                        tpu_test_list={this.state.tpu_test_list}
                        intl={this.props.intl}
                        LANG_MESSAGE={LANG_MESSAGE}
                        setDownModelShow={this.setDownModelShow}
                        setDownModelName={this.setDownModelName}
                        setDownModelWidth={this.setDownModelWidth}
                        setDownModelHeight={this.setDownModelHeight}
                        setDownModelTh={this.setDownModelTh}
                        setDownModelIo={this.setDownModelIo}
                        setCover={this.setCover}
                        downloadModelConfirm={this.downloadModelConfirm}
                    />
                ) : null}
                {/* 下载模型状态 */}
                {this.state.down_state_show ? (
                    <DownloadState
                        state={this.state.down_state}
                        intl={this.props.intl}
                        LANG_MESSAGE={LANG_MESSAGE}
                        setDownStateShow={this.setDownStateShow}
                    />
                ) : null}
                {/* 图片预览 */}
                {this.state.preview ? (
                    <PreviewBox 
                        result_image= {this.state.result_image}
                        setPreview={this.setPreview}
                    />
                ) : null}
                {/* 上传/拍照 */}
                {this.state.take_photo ? (
                    <TakePhoto 
                        vm={this.props.vm}
                        ref="takePhoto"
                        intl={this.props.intl}
                        changeTakePhoto={this.changeTakePhoto}
                        takePhotoConfirm={this.takePhotoConfirm}
                    />
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tpu_state: state.scratchGui.status.tpu_state,
})

const mapDispatchToProps = dispatch => ({})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateModel))
