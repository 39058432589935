import React from 'react';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import styles from './select-model.css'

import { Radio } from 'antd';

class SelectModel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 'train' // train compile
        }
        bindAll(this, [
            'onChange',
            'onClose',
            'onConfirm'
        ])
    }
    componentDidMount() {}
    componentDidUpdate() {}
    onChange(e) {
        this.setState({
            value: e.target.value
        })
    }
    onClose() {
        this.props.onSetSelectModel({
            show: false,
            target: null,
            project: null,
            type: null
        })
    }
    onConfirm() {
        let {target} = this.props.selectModel
        let type = 'SELECT_' + this.state.value.toUpperCase()
        this.onClose()

        this.props.onSetBlocklyDialog({
            show: true,
            project: 'blocks',
            target,
            type
        })
    }
    render() {
        return (
            <div className={styles.comDialogContainer}>
                <div className={styles.box}>
                    <div className={styles.header}>{this.props.header}</div>
                    <div className={styles.content}>
                        <Radio.Group onChange={this.onChange} value={this.state.value}>
                            <Radio value={'train'}>训练模型</Radio>
                            <Radio value={'compile'}>编译模型</Radio>
                        </Radio.Group>
                    </div>
                    <div className={styles.footer}>
                        <input
                            className={classNames(styles.cancle, styles.comBtn)}
                            type="button"
                            value="取消"
                            onClick={this.onClose}
                        />
                        <input
                            className={classNames(styles.comfirm, styles.comBtn)}
                            type="button"
                            value="确定"
                            onClick={this.onConfirm}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
export default SelectModel