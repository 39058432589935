import { defineMessages } from "react-intl";

const LANG_MESSAGE = defineMessages({
    categorize: {
        id: 'gui.dimesion.categorize',
        description: 'Label for the name of the categorize',
        defaultMessage: 'categorize'
    },
    renameClass: {
        id: 'gui.dimesion.renameClass',
        description: 'Label for the name of the renameClass',
        defaultMessage: 'renameClass'
    },
    deleteThisClass: {
        id: 'gui.dimesion.deleteThisClass',
        description: 'Label for the name of the deleteThisClass',
        defaultMessage: 'deleteThisClass'
    },
    removeAllSampleData: {
        id: 'gui.dimesion.removeAllSampleData',
        description: 'Label for the name of the removeAllSampleData',
        defaultMessage: 'removeAllSampleData'
    },
    all: {
        id: 'gui.dimesion.all',
        description: 'Label for the name of the all',
        defaultMessage: 'all'
    },
    marked: {
        id: 'gui.dimesion.marked',
        description: 'Label for the name of the marked',
        defaultMessage: 'marked'
    },
    notmarked: {
        id: 'gui.dimesion.notmarked',
        description: 'Label for the name of the notmarked',
        defaultMessage: 'notmarked'
    },
    delete: {
        id: 'paint.modeTools.delete',
        description: 'Label for the name of the delete',
        defaultMessage: 'delete'
    },
    getPic: {
        id: 'skai.getPic',
        description: 'Label for the name of the getPic',
        defaultMessage: 'getPic'
    },
    localUpload: {
        id: 'gui.dimesion.localUpload',
        description: 'Label for the name of the localUpload',
        defaultMessage: 'localUpload'
    },
    taskType: {
        id: 'gui.dimesion.taskType',
        description: 'Label for the name of the taskType',
        defaultMessage: 'taskType'
    },
    pictures: {
        id: 'gui.dimesion.pictures',
        description: 'Label for the name of the pictures',
        defaultMessage: 'pictures'
    },
    dataAnnotations: {
        id: 'gui.dimesion.dataAnnotations',
        description: 'Label for the name of the dataAnnotations',
        defaultMessage: 'dataAnnotations'
    },
    audioFrequency: {
        id: 'gui.dimesion.audioFrequency',
        description: 'Label for the name of the audioFrequency',
        defaultMessage: 'audioFrequency'
    },
    image: {
        id: 'gui.dimesion.image',
        description: 'Label for the name of the image',
        defaultMessage: 'image'
    },
    attitude: {
        id: 'gui.dimesion.attitude',
        description: 'Label for the name of the attitude',
        defaultMessage: 'attitude'
    },
    imageClassification: {
        id: 'gui.dimesion.imageClassification',
        description: 'Label for the name of the imageClassification',
        defaultMessage: 'imageClassification'
    },
    imageDetection: {
        id: 'gui.dimesion.imageDetection',
        description: 'Label for the name of the imageDetection',
        defaultMessage: 'imageDetection'
    },
    imageSegmentation: {
        id: 'gui.dimesion.imageSegmentation',
        description: 'Label for the name of the imageSegmentation',
        defaultMessage: 'imageSegmentation'
    },
    training: {
        id: 'gui.dimesion.training',
        description: 'Label for the name of the training',
        defaultMessage: 'training'
    },
    createProjectTip: {
        id: 'gui.dimesion.createProjectTip',
        description: 'Label for the name of the createProjectTip',
        defaultMessage: 'createProjectTip'
    },
    createdSuccessfully: {
        id: 'gui.dimesion.createdSuccessfully',
        description: 'Label for the name of the createdSuccessfully',
        defaultMessage: 'createdSuccessfully'
    },
    isRemoveImg: {
        id: 'gui.dimesion.isRemoveImg',
        description: 'Label for the name of the isRemoveImg',
        defaultMessage: 'isRemoveImg'
    },
    isClearTag: {
        id: 'gui.dimesion.isClearTag',
        description: 'Label for the name of the isClearTag',
        defaultMessage: 'isClearTag'
    },
    inputDataSetName: {
        id: 'gui.dimesion.inputDataSetName',
        description: 'Label for the name of the inputDataSetName',
        defaultMessage: 'inputDataSetName'
    },
    selectDataSetType: {
        id: 'gui.dimesion.selectDataSetType',
        description: 'Label for the name of the selectDataSetType',
        defaultMessage: 'selectDataSetType'
    },
    exportSuccessful: {
        id: 'gui.dimesion.exportSuccessful',
        description: 'Label for the name of the exportSuccessful',
        defaultMessage: 'exportSuccessful'
    },
    exportFailed: {
        id: 'gui.dimesion.exportFailed',
        description: 'Label for the name of the exportFailed',
        defaultMessage: 'exportFailed'
    },
    failedToCreateDataset: {
        id: 'gui.dimesion.failedToCreateDataset',
        description: 'Label for the name of the failedToCreateDataset',
        defaultMessage: 'failedToCreateDataset'
    },
    selectFirst: {
        id: 'gui.dimesion.selectFirst',
        description: 'Label for the name of the selectFirst',
        defaultMessage: 'selectFirst'
    },
    deleteCurTag: {
        id: 'gui.dimesion.deleteCurTag',
        description: 'Label for the name of the deleteCurTag',
        defaultMessage: 'deleteCurTag'
    },
    inputClassificationName: {
        id: 'gui.dimesion.inputClassificationName',
        description: 'Label for the name of the inputClassificationName',
        defaultMessage: 'inputClassificationName'
    },
    classificationNameExists: {
        id: 'gui.dimesion.classificationNameExists',
        description: 'Label for the name of the classificationNameExists',
        defaultMessage: 'classificationNameExists'
    },
    mustIncludeAClassification: {
        id: 'gui.dimesion.mustIncludeAClassification',
        description: 'Label for the name of the mustIncludeAClassification',
        defaultMessage: 'mustIncludeAClassification'
    },
    train: {
        id: 'gui.dimesion.train',
        description: 'Label for the name of the train',
        defaultMessage: 'train'
    },
    saveToMyDataset: {
        id: 'gui.dimesion.saveToMyDataset',
        description: 'Label for the name of the saveToMyDataset',
        defaultMessage: 'saveToMyDataset'
    },
    downloadDatasetToLocal: {
        id: 'gui.dimesion.downloadDatasetToLocal',
        description: 'Label for the name of the downloadDatasetToLocal',
        defaultMessage: 'downloadDatasetToLocal'
    },
    addClassification: {
        id: 'gui.dimesion.addClassification',
        description: 'Label for the name of the addClassification',
        defaultMessage: 'addClassification'
    },
    switchPictures: {
        id: 'gui.dimesion.switchPictures',
        description: 'Label for the name of the switchPictures',
        defaultMessage: 'switchPictures'
    },
    clearAnnotation: {
        id: 'gui.dimesion.clearAnnotation',
        description: 'Label for the name of the clearAnnotation',
        defaultMessage: 'clearAnnotation'
    },
    newLabel: {
        id: 'gui.dimesion.newLabel',
        description: 'Label for the name of the newLabel',
        defaultMessage: 'newLabel'
    },
    allLabels: {
        id: 'gui.dimesion.allLabels',
        description: 'Label for the name of the allLabels',
        defaultMessage: 'allLabels'
    },
    create: {
        id: 'gui.dimesion.create',
        description: 'Label for the name of the create',
        defaultMessage: 'create'
    },
    back: {
        id: 'gui.dimesion.back',
        description: 'Label for the name of the back',
        defaultMessage: 'back'
    },
    nextStep: {
        id: 'gui.dimesion.nextStep',
        description: 'Label for the name of the nextStep',
        defaultMessage: 'nextStep'
    },
    newBuilt: {
        id: 'gui.dimesion.newBuilt',
        description: 'Label for the name of the newBuilt',
        defaultMessage: 'newBuilt'
    },
    trainingProgram: {
        id: 'gui.dimesion.trainingProgram',
        description: 'Label for the name of the trainingProgram',
        defaultMessage: 'trainingProgram'
    },
    name: {
        id: 'gui.dimesion.name',
        description: 'Label for the name of the name',
        defaultMessage: 'name'
    },
    enterProjectName: {
        id: 'gui.dimesion.enterProjectName',
        description: 'Label for the name of the enterProjectName',
        defaultMessage: 'enterProjectName'
    },
    describe: {
        id: 'gui.dimesion.describe',
        description: 'Label for the name of the describe',
        defaultMessage: 'describe'
    },
    enterDescriptionContent: {
        id: 'gui.dimesion.enterDescriptionContent',
        description: 'Label for the name of the enterDescriptionContent',
        defaultMessage: 'enterDescriptionContent'
    },
    cancel: {
        id: 'gui.customProcedures.cancel',
        description: 'Label for the name of the cancel',
        defaultMessage: 'cancel'
    },
    ok: {
        id: 'gui.prompt.ok',
        description: 'Label for the name of the ok',
        defaultMessage: 'ok'
    },
    rename: {
        id: 'gui.dimesion.rename',
        description: 'Label for the name of the rename',
        defaultMessage: 'rename'
    },
    nameOfClassification: {
        id: 'gui.dimesion.nameOfClassification',
        description: 'Label for the name of the nameOfClassification',
        defaultMessage: 'nameOfClassification'
    },
    enterTheNameOfTheClassification: {
        id: 'gui.dimesion.enterTheNameOfTheClassification',
        description: 'Label for the name of the enterTheNameOfTheClassification',
        defaultMessage: 'enterTheNameOfTheClassification'
    },
    uploadTips: {
        id: 'gui.dimesion.uploadTips',
        description: 'Label for the name of the uploadTips',
        defaultMessage: 'uploadTips'
    },
    capture: {
        id: 'gui.dimesion.capture',
        description: 'Label for the name of the capture',
        defaultMessage: 'capture'
    },
    cameraAbnormality: {
        id: 'gui.dimesion.cameraAbnormality',
        description: 'Label for the name of the cameraAbnormality',
        defaultMessage: 'cameraAbnormality'
    },
    search: {
        id: 'gui.connection.search',
        description: 'Label for the name of the search',
        defaultMessage: 'search'
    },
    stop: {
        id: 'gui.controls.stop',
        description: 'Label for the name of the stop',
        defaultMessage: 'stop'
    },
    perSecond: {
        id: 'gui.dimesion.perSecond',
        description: 'Label for the name of the perSecond',
        defaultMessage: 'perSecond'
    },
    nervous: {
        id: 'gui.dimesion.nervous',
        description: 'Label for the name of the nervous',
        defaultMessage: 'nervous'
    },
    empty: {
        id: 'gui.dimesion.empty',
        description: 'Label for the name of the empty',
        defaultMessage: 'empty'
    },
    addLabelName: {
        id: 'gui.dimesion.addLabelName',
        description: 'Label for the name of the addLabelName',
        defaultMessage: 'addLabelName'
    },
    add: {
        id: 'gui.dimesion.add',
        description: 'Label for the name of the add',
        defaultMessage: 'add'
    },
    label: {
        id: 'gui.dimesion.label',
        description: 'Label for the name of the label',
        defaultMessage: 'label'
    },
    datasetName: {
        id: 'gui.dimesion.datasetName',
        description: 'Label for the name of the datasetName',
        defaultMessage: 'datasetName'
    },
    datasetType: {
        id: 'gui.dimesion.datasetType',
        description: 'Label for the name of the datasetType',
        defaultMessage: 'datasetName'
    },
    noDataAvailable: {
        id: 'gui.dimesion.noDataAvailable',
        description: 'Label for the name of the noDataAvailable',
        defaultMessage: 'noDataAvailable'
    },
    labelType: {
        id: 'gui.dimesion.labelType',
        description: 'Label for the name of the labelType',
        defaultMessage: 'labelType'
    },
    datasetDescription: {
        id: 'gui.dimesion.datasetDescription',
        description: 'Label for the name of the datasetDescription',
        defaultMessage: 'datasetDescription'
    },
    export: {
        id: 'gui.monitor.contextMenu.export',
        description: 'Label for the name of the export',
        defaultMessage: 'export'
    },
    prompt: {
        id: 'gui.dimesion.prompt',
        description: 'Label for the name of the prompt',
        defaultMessage: 'prompt'
    },
    newBack: {
        id: 'gui.dimesion.newBack',
        description: '返回数据工程',
        defaultMessage: '返回数据工程'
    },
    newCloseProject: {
        id: 'gui.dimesion.newCloseProject',
        description: '关闭工程',
        defaultMessage: '关闭工程'
    },
    close: {
        id: 'gui.cards.close',
        description: '关闭',
        defaultMessage: '关闭'
    },
    errorCode19001: {
        id: 'gui.error.errorCode19001',
        description: '数据集文件不存在',
        defaultMessage: '数据集文件不存在'
    },
    errorCode19002: {
        id: 'gui.error.errorCode19002',
        description: '数据集已分享',
        defaultMessage: '数据集已分享'
    },
    errorCode19003: {
        id: 'gui.error.errorCode19003',
        description: '没有权限操作',
        defaultMessage: '没有权限操作'
    },
    errorCode19004: {
        id: 'gui.error.errorCode19004',
        description: '数据集重复',
        defaultMessage: '数据集重复'
    },
    errorCode19005: {
        id: 'gui.error.errorCode19005',
        description: '状态不允许操作',
        defaultMessage: '状态不允许操作'
    },
    download: {
        id: 'gui.alerts.download',
        description: '下载',
        defaultMessage: '下载'
    },
    downloadSuccessful: {
        id: 'gui.dimesion.downloadSuccessful',
        description: '下载成功',
        defaultMessage: '下载成功'
    },
    continueEditing: {
        id: 'gui.dimesion.continueEditing',
        description: '继续编辑',
        defaultMessage: '继续编辑'
    },
    nameModificationSuccessful: {
        id: 'gui.dimesion.nameModificationSuccessful',
        description: '名称修改成功',
        defaultMessage: '名称修改成功'
    },
    resourceLoading: {
        id: 'gui.dimesion.resourceLoading',
        description: '资源加载中...',
        defaultMessage: '资源加载中...'
    },
    uploadingResources: {
        id: 'gui.dimesion.uploadingResources',
        description: '资源上传中...',
        defaultMessage: '资源上传中...'
    },
    modifyName: {
        id: 'gui.dimesion.modifyName',
        description: '修改名称中...',
        defaultMessage: '修改名称中...'
    },
    valiadteNames: {
        id: 'gui.dimesion.validateNames',
        description: '请输入3个字符以上的名称',
        defaultMessage: '请输入3个字符以上的名称'
    },
    noCamera: {
        id: 'gui.dimesion.noCamera',
        description: '无摄像头设备可以使用',
        defaultMessage: '无摄像头设备可以使用'
    },
    downloadFailed: {
        id: 'gui.dimesion.downloadFailed',
        description: '下载失败，网络不稳定',
        defaultMessage: '下载失败，网络不稳定'
    }
})

export default LANG_MESSAGE
