import bindAll from "lodash.bindall";
import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';
import VM from 'scratch-vm';
import styles from './dialog.css';

import cancelIcon from './cancel.png';
import { setDialogVisibleZhiya, setProgramId, setWorkId, setWorkCover, setWorkPath, setUserId, setTitle, setSort, setRemark } from "../../reducers/work-zhiya";
import { deleteFiles, saveWork, updateWork, uploadWork } from "../../api/zhiya";

import addIcon from './file-add.png'

const messages = defineMessages({
    cannotBeEmpty: {
        id: 'gui.workDialog.cannotBeEmpty',
        description: 'Label for the name of the cannotBeEmpty',
        defaultMessage: 'cannotBeEmpty'
    },
    descriptionCannotBeEmpty: {
        id: 'gui.workDialog.descriptionCannotBeEmpty',
        description: 'Label for the name of the descriptionCannotBeEmpty',
        defaultMessage: 'descriptionCannotBeEmpty'
    },
    successfullyEdited: {
        id: 'gui.menuBar.successfullyEdited',
        description: 'Label for the name of the successfullyEdited',
        defaultMessage: 'successfullyEdited'
    },
    editingFailed: {
        id: 'gui.menuBar.editingFailed',
        description: 'Label for the name of the editingFailed',
        defaultMessage: 'editingFailed'
    },
    createdSuccessfully: {
        id: 'gui.dimesion.createdSuccessfully',
        description: 'Label for the name of the createdSuccessfully',
        defaultMessage: 'createdSuccessfully'
    },
    creationFailed: {
        id: 'gui.menuBar.creationFailed',
        description: 'Label for the name of the creationFailed',
        defaultMessage: 'creationFailed'
    },
    editingWorks: {
        id: 'gui.workDialog.editingWorks',
        description: 'Label for the name of the editingWorks',
        defaultMessage: 'editingWorks'
    },
    createAWork: {
        id: 'gui.workDialog.createAWork',
        description: 'Label for the name of the createAWork',
        defaultMessage: 'createAWork'
    },
    name: {
        id: 'gui.workDialog.name',
        description: 'Label for the name of the name',
        defaultMessage: 'name'
    },
    workDescription: {
        id: 'gui.workDialog.workDescription',
        description: 'Label for the name of the workDescription',
        defaultMessage: 'workDescription'
    },
    workCover: {
        id: 'gui.workDialog.workCover',
        description: 'Label for the name of the workCover',
        defaultMessage: 'workCover'
    },
    replaceCoverLocally: {
        id: 'gui.workDialog.replaceCoverLocally',
        description: 'Label for the name of the replaceCoverLocally',
        defaultMessage: 'replaceCoverLocally'
    },
    cancel: {
        id: 'gui.customProcedures.cancel',
        description: 'Label for the name of the cancel',
        defaultMessage: 'cancel'
    },
    ok: {
        id: 'gui.prompt.ok',
        description: 'Label for the name of the ok',
        defaultMessage: 'ok'
    }
})

class Dialog extends React.Component {
    constructor(props) {
        super(props)
        bindAll(this, [
            'changeDialogVisible',
            'changeTitle',
            'changeRemark',
            'changeWorkCover',
            'onDeleteFiles',
            'submitHandle',
            'changeFileName'
        ])
    }
    // 关闭弹窗
    changeDialogVisible() {
        this.props.onSetDialogVisibleZhiya(false)
    }
    // 修改作品名称
    changeTitle(e) {
        this.props.onSetTitle(e.target.value)
    }
    // 修改作品描述
    changeRemark (e) {
        this.props.onSetRemark(e.target.value)
    }
    // 修改资源文件名
    changeFileName(e){
        var fileData = this.props.fileData
        fileData.fileName = e.target.value
        this.props.onSetFileData(fileData)

    }
    // 根据路径删除文件
    onDeleteFiles(path) {
        let formData = new FormData()
        formData.append('path', path)
        deleteFiles(formData)
            .then((response) => {
                console.log(response, '删除文件')
            })
            .catch(() => {})
    }
    // 修改封面
    async changeWorkCover(event) {
        const old_workCover = this.props.workCover
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData()
            const newFile = new File([file], file.name, {type: file.type})
            formData.append('file', newFile)
            formData.append('path', '/work')
            let response1 = await uploadWork(formData)
            if (response1.msg === '操作成功') {
                this.props.onSetWorkCover(response1.path)
                this.onDeleteFiles(old_workCover)
            }
        }
    }
    // 保存提交
    submitHandle() {
        if (this.props.title === '') {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(messages.cannotBeEmpty),
                type: 'fail'
            })
            return
        }
        if (this.props.remark === '') {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(messages.descriptionCannotBeEmpty),
                type: 'fail'
            })
            return
        }
        let data = {
            programId: this.props.programId,
            workCover: this.props.workCover,
            workPath: this.props.workPath,
            userId: this.props.userId,
            title: this.props.title,
            sort: this.props.sort,
            remark: this.props.remark
        }
        console.log('data:', data)
        let old_workPath = this.props.workPath
        this.props.saveProjectSb3()
            .then(async connect => {
                let formData = new FormData()
                const file = new File([connect], '1.sk', { type: 'text/plain', lastModified: Date.now() })
                formData.append('file', file)
                formData.append('path', '/work')
                let response1 = await uploadWork(formData)
                if (response1.msg === '操作成功') {
                    // 保存上传后的作品地址
                    this.props.onSetWorkPath(response1.path)

                    // 保存作品
                    let response;

                    if (this.props.workId) {
                        response = await updateWork({
                            workId: this.props.workId,
                            programId: this.props.programId,
                            workCover: this.props.workCover,
                            workPath: this.props.workPath,
                            userId: this.props.userId,
                            title: this.props.title,
                            sort: this.props.sort,
                            remark: this.props.remark
                        })
                        if (response.msg === '操作成功') {
                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(messages.successfullyEdited),
                                type: 'success'
                            })
    
                            this.onDeleteFiles(old_workPath)
                            this.changeDialogVisible()
                        } else {

                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(messages.editingFailed),
                                type: 'fail'
                            })
                            this.onDeleteFiles(response1.path)
                        }
                    } else {
                        response = await saveWork({
                            programId: this.props.programId,
                            workCover: this.props.workCover,
                            workPath: this.props.workPath,
                            userId: this.props.userId,
                            title: this.props.title,
                            sort: this.props.sort,
                            remark: this.props.remark
                        })
                        if (response.msg === '操作成功') {
                            this.props.onSetWorkId(response.data.workId)
                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(messages.createdSuccessfully),
                                type: 'success'
                            })
                            this.changeDialogVisible()
                        } else {
                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(messages.creationFailed),
                                type: 'fail'
                            })
                            this.onDeleteFiles(response1.path)
                        }
                    }
                }
            })
    }
    render() {
        return (
            <div className={styles.workDialog}>
                <div className={styles.dialogBody}>
                    <div className={styles.dialogHead}>
                        <div className={styles.headTitle}>
                            {this.props.workId ? this.props.intl.formatMessage(messages.editingWorks) : this.props.intl.formatMessage(messages.createAWork)}
                            </div>
                        <div className={styles.headCancel} >
                            <img src={cancelIcon} alt="" onClick={this.changeDialogVisible}/>
                        </div>
                    </div>
                    <div className={styles.dialogContent}>
                        <div className={styles.fromItem}>
                            <div className={styles.fromItemLab}><span className={styles.isRequired}>*</span>{this.props.intl.formatMessage(messages.name)} </div>
                            <div className={styles.fromItemVal}>
                                <input type="text" className={styles.input} value={this.props.title} onChange={this.changeTitle}/>
                            </div>
                        </div>
                        <div className={styles.fromItem}>
                            <div className={styles.fromItemLab}><span className={styles.isRequired}>*</span>{this.props.intl.formatMessage(messages.workDescription)}</div>
                            <div className={styles.fromItemVal}>
                            <input type="text" className={styles.input} value={this.props.remark} onChange={this.changeRemark}/>
                        </div>
                        </div>
                        <div className={styles.fromItem}>
                            <div className={styles.fromItemLab}>{this.props.intl.formatMessage(messages.workCover)}</div>
                            <div className={styles.fromItemVal}>
                                {(this.props.workCover) ? (
                                    <div className={styles.emptyImage}>
                                        <img src={ '/zhiya' + this.props.workCover } alt="" className={styles.workCoverIcon}/>
                                    </div>
                                ) : (
                                    <div>
                                        <input type="file" accept="image/*" className={styles.fileInput} id="emptyFile"  onChange={this.changeWorkCover} />
                                        <label htmlFor="emptyFile">
                                            <div className={styles.emptyImage}>
                                                <img src={addIcon} alt="" />
                                            </div>
                                        </label>
                                    </div>
                                )}
                                
                                {(this.props.workCover) ? (
                                    <div className={styles.changeFile}>
                                        <input type="file" accept="image/*" className={styles.fileInput} id="fileInput" onChange={this.changeWorkCover} />
                                        <label htmlFor="fileInput">{this.props.intl.formatMessage(messages.replaceCoverLocally)}</label>
                                    </div>
                                ): null}
                            </div>
                        </div>
                    </div>
                    <div className={styles.dialogFoot}>
                        <div className={styles.buttonCancel} onClick={this.changeDialogVisible}>{this.props.intl.formatMessage(messages.cancel)}</div>
                        <div className={styles.buttonSubmit} onClick={this.submitHandle}>{this.props.intl.formatMessage(messages.ok)}</div>
                    </div>
                </div>
            </div>
        )
    }
}

Dialog.propTypes = {
    vm: PropTypes.instanceOf(VM),
    onChangeMessgae: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
    return {
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        dialogVisibleZhiya: state.scratchGui.workZhiya.dialogVisibleZhiya,
        programId: state.scratchGui.workZhiya.programId,
        workId: state.scratchGui.workZhiya.workId,
        workCover: state.scratchGui.workZhiya.workCover,
        workPath: state.scratchGui.workZhiya.workPath,
        userId: state.scratchGui.workZhiya.userId,
        title: state.scratchGui.workZhiya.title,
        sort: state.scratchGui.workZhiya.sort,
        remark: state.scratchGui.workZhiya.remark
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSetDialogVisibleZhiya: (params) => dispatch(setDialogVisibleZhiya(params)),
        onSetProgramId: (params) => dispatch(setProgramId(params)),
        onSetWorkCover: (params) => dispatch(setWorkCover(params)),
        onSetWorkId: (params) => dispatch(setWorkId(params)),
        onSetWorkPath: (params) => dispatch(setWorkPath(params)),
        onSetUserId: (params) => dispatch(setUserId(params)),
        onSetTitle: (params) => dispatch(setTitle(params)),
        onSetSort: (params) => dispatch(setSort(params)),
        onSetRemark: (params) => dispatch(setRemark(params))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dialog)