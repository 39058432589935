import { asignmentDetails } from "../api/manage"

const ROLE_STATUS = 'roleStatus'
const ADD_ROLE = 'addRole'
const SET_COSTUME_SOURCE = 'setCostumeSource'

const WORK_STATUS = 'workStatus'
const WORK_TYPE = 'workType'
const TASK_INDEX = 'taskIndex'
const TASK_REMARK = 'taskRemark'
const SET_TASK = 'setTask'

const GET_TASK_DETAIL = 'getTaskDetail'
const SET_TASK_DETAIL = 'setTaskDetail'
const SET_STUDENT_HOMEWORK = 'setStudentHomework'

const SET_CORRECT_INFO = 'setCorrectInfo'

const SET_MESSAGE = 'setMessage'

const SET_DIMENSION = 'setDimension'

const SOURCE_MATERIAL_STATUS = 'sourceMaterial'
const SET_RICH_TEXT_CONTENT = 'setRichTextContent'

const SET_TPU_STATUS = 'setTpuState'
// 基础弹窗
const SET_COM_DIALOG = 'setComDialog'
const SET_DIALOG_CONFIRM = 'setDialogConfirm'

const SET_BLOCKLY_DIALOG = 'setBlocklyDialog'

const SET_CREATE_MODEL = 'setCreateModel'
const SET_SELECT_MODEL = 'setSelectModel'

// initial state
const initState = {
    role: false, // 新增角色
    add_role: {
        is_bol: false, // 单个角色绘制状态
        type: null, // 角色系列all-role 单个角色single-role
        id: null, // 修改id
        old_id: null // 旧的id，角色系列指id，单个角色/背景指index
    },
    costume_source: [], // 角色旧资源映射

    work: false, // 作业功能
    work_type: '', // e edit c create correct批改 
    task: [
        {
            file_path: '', // 作品路径
            remark: '', // 备注
            taskId: '',
            status: 1
        },
        {
            file_path: '',
            remark: '',
            taskId: '',
            status: 1
        },
        {
            file_path: '',
            remark: '',
            taskId: '',
            status: 1
        }
    ], // 当前任务 
    task_index: 1, // 当前任务下标 创建任务
    task_remark: '', // 备注 创建任务
    task_id: '', // 任务id 学生查看详情
    task_detail: {}, // 任务详情，老师发布作业详情
    student_homework: {}, // 学生作业详情
    rich_text_content: null, // 富文本内容

    correct_info: { // 批改信息
        correctFile: '',
        correctGrade: 60, // 分数
        correctRemark: '', // 评语
        studentId: '',
        classId: '',
        taskId: ''
    }, 

    message: { // 消息提示
        visible: false,
        content: '',
        type: '' // success fail
    },

    dimension: false, // 标注功能

    source_material: false, // 素材库功能
    source_type: 0, // 素材库类型 0角色一整套  1角色单个  2背景  3声音

    tpu_state: null, // tpu状态
    
    // 通用弹框
    com_dialog: {
        show: false, // 是否显示
        header: null, // 标题
        content: null, // 内容
        type: null, // 所有角色all 单个角色/背景single role/stage
        id: null // 删除目标id
    },
    dialog_confirm: { // 弹框是否确定
        bol: false,
        type: null,
        id: null
    },

    // blocks弹窗
    blockly_dialog: {
        show: false,
        target: null,
        project: null,
        type: null
    },

    // 创建模型
    create_model: {
        show: false,
        type: '', // create-model task-type train-model compile-model
        modelVersionName: '',
        algorithmName: '', // 算法类型
        data_set: null, // 数据集
        jobId: '', // 创建训练成功id
        train_detail: null, // 训练成功详情
        deployment: null, // 创建部署成功
        compile_algorithmName: '', // tpu类型
        compileId: '', // 创建编译成功id
        compile_detail: null, // 编译成功详情
        is_select: false, // 是否选择
        compile_data_set: null, // 编译数据集
    },

    // 选择模型
    select_model: {
        show: false,
        target: null,
        project: null,
        type: null
    }
}

// reducer
const reducer = function(state = initState, action) {
    if (typeof state === 'undefined') state = initState;
    switch(action.type) {
        case ROLE_STATUS: 
            return Object.assign({}, state, {
                role: action.param
            })
        case ADD_ROLE:
            return Object.assign({}, state, {
                add_role: action.param
            })
        case SET_COSTUME_SOURCE:
            return Object.assign({}, state, {
                costume_source: action.param
            })

        case WORK_STATUS:
            return Object.assign({}, state, {
                work: action.param
            })
        case WORK_TYPE:
            return Object.assign({}, state, {
                work_type: action.param
            })
        case TASK_INDEX:
            return Object.assign({}, state, {
                task_index: action.param
            })
        case TASK_REMARK:
            return Object.assign({}, state, {
                task_remark: action.param
            })
        case SET_TASK:
            let data = JSON.parse(JSON.stringify(state.task))
            if (action.index || action.index === 0) {
                data[action.index] = {
                    file_path: action.param.hasOwnProperty('file_path') ? action.param.file_path : data[action.index].file_path,
                    remark: action.param.hasOwnProperty('remark') ? action.param.remark : data[action.index].remark,
                    taskId: action.param.hasOwnProperty('taskId') ? action.param.taskId : data[action.index].taskId,
                    status: action.param.hasOwnProperty('status') ? action.param.status : data[action.index].status
                }

                return Object.assign({}, state, {
                    task: data
                })
            } else {
                action.param.forEach((item, index) => {
                    data[index] = item
                })

                return Object.assign({}, state, {
                    task: data
                })
            }
        case GET_TASK_DETAIL:
            asignmentDetails(action.param)
                .then((response) => {
                    if (response.msg === '操作成功') {
                        action.callback(response.data)
                        // let obj = response.data
                        // return Object.assign({}, state, {
                        //     task_detail: obj
                        // })
                    }
                })
                .catch(() => {
                    return state
                })
        case SET_TASK_DETAIL:
            return Object.assign({}, state, {
                task_detail: action.param
            })
        case SET_STUDENT_HOMEWORK:
            if (action.types) {
                return Object.assign({}, state, {
                    student_homework: {...action.param}
                })
            } 
            let obj = JSON.parse(JSON.stringify(state.student_homework))
            obj = {
                taskFile: action.param.hasOwnProperty('taskFile') ? action.param.taskFile : obj.taskFile,
                classId: action.param.hasOwnProperty('classId') ? action.param.classId : obj.classId,
                taskId: action.param.hasOwnProperty('taskId') ? action.param.taskId : obj.taskId,
                status: action.param.hasOwnProperty('status') ? action.param.status : obj.status,
            }

            return Object.assign({}, state, {
                student_homework: obj
            })
        case SET_CORRECT_INFO:
            let obj1 = JSON.parse(JSON.stringify(state.correct_info))
            obj1 = {
                correctFile: action.param.hasOwnProperty('correctFile') ? action.param.correctFile : obj1.correctFile,
                correctGrade: action.param.hasOwnProperty('correctGrade') ? action.param.correctGrade : obj1.correctGrade,
                correctRemark: action.param.hasOwnProperty('correctRemark') ? action.param.correctRemark : obj1.correctRemark,
                studentId: action.param.hasOwnProperty('studentId') ? +action.param.studentId : +obj1.studentId,
                classId: action.param.hasOwnProperty('classId') ? +action.param.classId : +obj1.classId,
                taskId: action.param.hasOwnProperty('taskId') ? +action.param.taskId : +obj1.taskId
            }

            return Object.assign({}, state, {
                correct_info: obj1
            })
        case SET_MESSAGE:
            return Object.assign({}, state, {
                message: action.param
            })
        case SET_DIMENSION:
            return Object.assign({}, state, {
                dimension: action.param
            })
        case SOURCE_MATERIAL_STATUS: 
            return Object.assign({}, state, {
                source_material: action.param.status,
                source_type: action.param.type
            })
        case SET_RICH_TEXT_CONTENT:
            window.rich_text_content = action.param
            return Object.assign({}, state, {
                rich_text_content: action.param
            })
        case SET_TPU_STATUS:
            return Object.assign({}, state, {
                tpu_state: action.param
            })
        case SET_DIALOG_CONFIRM:
            return Object.assign({}, state, {
                dialog_confirm: action.param
            })
        case SET_COM_DIALOG:
            return Object.assign({}, state, {
                com_dialog: action.param
            })
        case SET_BLOCKLY_DIALOG:
            return Object.assign({}, state, {
                blockly_dialog: action.param
            })
        case SET_CREATE_MODEL:
            let obj2 = JSON.parse(JSON.stringify(state.create_model))
            obj2 = {
                show: action.param.hasOwnProperty('show') ? action.param.show : obj2.show,
                type: action.param.hasOwnProperty('type') ? action.param.type : obj2.type,

                modelVersionName: action.param.hasOwnProperty('modelVersionName') ? action.param.modelVersionName : obj2.modelVersionName,
                algorithmName: action.param.hasOwnProperty('algorithmName') ? action.param.algorithmName : obj2.algorithmName,
                data_set: action.param.hasOwnProperty('data_set') ? action.param.data_set : obj2.data_set,
                jobId: action.param.hasOwnProperty('jobId') ? action.param.jobId : obj2.jobId,
                train_detail: action.param.hasOwnProperty('train_detail') ? action.param.train_detail : obj2.train_detail,
                
                deployment: action.param.hasOwnProperty('deployment') ? action.param.deployment : obj2.deployment,

                compile_algorithmName: action.param.hasOwnProperty('compile_algorithmName') ? action.compile_algorithmName : obj2.compile_algorithmName,
                compileId: action.param.hasOwnProperty('compileId') ? action.param.compileId : obj2.compileId,
                compile_detail: action.param.hasOwnProperty('compile_detail') ? action.param.compile_detail : obj2.compile_detail,
                is_select: action.param.hasOwnProperty('is_select') ? action.param.is_select : obj2.is_select,
                // compile_data_set: action.param.hasOwnProperty('compile_data_set') ? action.param.compile_data_set : obj2.compile_data_set
            }
            return Object.assign({}, state, {
                // create_model: action.param
                create_model: obj2
            })
        case SET_SELECT_MODEL:
            return Object.assign({}, state, {
                select_model: action.param
            })
        default:
            return state
    }
}

// action creator
const changeRole = (state) => ({
    type: ROLE_STATUS,
    param: state
})
const addRole = (state) => ({
    type: ADD_ROLE,
    param: state
})
const setCostumeSource = (state) => ({
    type: SET_COSTUME_SOURCE,
    param: state
})

const changeWork = (state) => ({
    type: WORK_STATUS,
    param: state
})
const setWorkType = (state) => ({
    type: WORK_TYPE,
    param: state
})
const setTaskIndex = (state) => ({
    type: TASK_INDEX,
    param: state
})
const setTaskRemark = (state) => ({
    type: TASK_REMARK,
    param: state
})
const setTask = (state, index) => ({
    type: SET_TASK,
    param: state,
    index
})
const getTaskDetail = (state, callback) => ({
    type: GET_TASK_DETAIL,
    param: state,
    callback
})
const setTaskDetail = (state) => ({
    type: SET_TASK_DETAIL,
    param: state
})
const setStudentHomewrok = (state, types) => ({
    type: SET_STUDENT_HOMEWORK,
    param: state,
    types
})
const setCorrectInfo = (state) => ({
    type: SET_CORRECT_INFO,
    param: state
})
const setMessage = (state) => ({
    type: SET_MESSAGE,
    param: state
})
const setDimension = (state) => ({
    type: SET_DIMENSION,
    param: state
})
// 素材库
const changeSourceMaterial = (state) => ({
    type: SOURCE_MATERIAL_STATUS,
    param: state
})
const setRichTextContent = (state) => ({
    type: SET_RICH_TEXT_CONTENT,
    param: state
})
// tpu状态
const setTpuState = (state) => ({
    type: SET_TPU_STATUS,
    param: state
})
// 弹框
const setComDialog = (state) => ({
    type: SET_COM_DIALOG,
    param: state
})
const setDialogConfirm = (state) => ({
    type: SET_DIALOG_CONFIRM,
    param: state
})
// blockly弹框
const setBlocklyDialog = (state) => ({
    type: SET_BLOCKLY_DIALOG,
    param: state
})
// 创建模型
const setCreateModel = (state) => ({
    type: SET_CREATE_MODEL,
    param: state
})
// 选择模型
const setSelectModel = (state) => ({
    type: SET_SELECT_MODEL,
    param: state
})

export {
    reducer as default,
    initState as statusInitState,
    changeRole,
    addRole,
    setCostumeSource,

    changeWork,
    setWorkType,
    setTaskIndex,
    setTaskRemark,
    setTask,
    getTaskDetail,
    setTaskDetail,
    setStudentHomewrok,
    setCorrectInfo,
    setMessage,
    setDimension,
    changeSourceMaterial,
    setRichTextContent,
    setTpuState,
    setDialogConfirm,
    setComDialog,
    setBlocklyDialog,
    setCreateModel,
    setSelectModel
}
