import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ReactModal from 'react-modal';
import VM from 'scratch-vm';
import {injectIntl, intlShape, defineMessages} from 'react-intl';
import bindAll from 'lodash.bindall';

import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {
    getIsError,
    getIsShowingProject
} from '../reducers/project-state';
import {
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX
} from '../reducers/editor-tab';

import {
    closeCostumeLibrary,
    closeBackdropLibrary,
    closeTelemetryModal,
    openExtensionLibrary
} from '../reducers/modals';

import { 
    removeToken, 
    setDimensionToken, 
    setCodeUserMessage,
    setGroupData
} from '../reducers/login.js'
import { 
    changeWork, 
    setMessage, 
    setDimension,
    setBlocklyDialog
} from '../reducers/status.js'
import {setDebugState} from '../reducers/vm-status.js'
import {setCodeStatus} from '../reducers/code-status.js'
import {setCreateModel, setSelectModel} from '../reducers/status.js'
import {changeGuide, changeGuideMenu, changeGuideType} from '../reducers/guide.js'

import FontLoaderHOC from '../lib/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import SBFileUploaderHOC from '../lib/sb-file-uploader-hoc.jsx';
import ProjectFetcherHOC from '../lib/project-fetcher-hoc.jsx';
import TitledHOC from '../lib/titled-hoc.jsx';
import ProjectSaverHOC from '../lib/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';

import GUIComponent from '../components/gui/gui.jsx';
import {setIsScratchDesktop} from '../lib/isScratchDesktop.js';
import { getSpace } from '../api/login.js'
import { getDimToken } from '../api/dimension.js'

// 无用的引用
// import { object } from 'to-style';
// import {
//     projectUIStyleInitial
// } from '../reducers/project-uistyle'
// import { flex } from 'to-style/src/prefixProperties.js';

const messages = defineMessages({
    networkError: {
        id: 'gui.error.networkError',
        description: 'Label for the name of the networkError',
        defaultMessage: 'networkError'
    },
    errorCode500: {
        id: 'gui.error.errorCode500',
        description: 'Label for the name of the errorCode500',
        defaultMessage: 'errorCode500'
    },
    errorCode15001: {
        id: 'gui.error.errorCode15001',
        description: '训练使用数据集不在权限范围内',
        defaultMessage: '训练使用数据集不在权限范围内'
    },
    errorCode15002: {  
        id: 'gui.error.errorCode15002',
        description: '训练使用数据集不在权限范围内',
        defaultMessage: '训练使用数据集不在权限范围内'
    },
    errorCode15003: {
        id: 'gui.error.errorCode15003',
        description: '训练使用算法不在权限范围内',
        defaultMessage: '训练使用算法不在权限范围内'
    },
    errorCode15004: {
        id: 'gui.error.errorCode15004',
        description: '训练pipeline请求失败',
        defaultMessage: '训练pipeline请求失败'
    },
    errorCode15005: {
        id: 'gui.error.errorCode15005',
        description: '删除任务失败, 只能删除终结状态下的任务',
        defaultMessage: '删除任务失败, 只能删除终结状态下的任务'
    },
    errorCode15006: {
        id: 'gui.error.errorCode15006',
        description: '余额不足',
        defaultMessage: '余额不足'
    },
    errorCode15007: {
        id: 'gui.error.errorCode15007',
        description: '任务已终止，无需停止',
        defaultMessage: '任务已终止，无需停止'
    },
    errorCode15008: {
        id: 'gui.error.errorCode15008',
        description: 'job使用镜像,状态不允许操作',
        defaultMessage: 'job使用镜像,状态不允许操作'
    },
    errorCode15009: {
        id: 'gui.error.errorCode15009',
        description: 'job使用算法,状态不允许操作',
        defaultMessage: 'job使用算法,状态不允许操作'
    },
    errorCode15010: {
        id: 'gui.error.errorCode15010',
        description: 'job使用数据集,状态不允许操作',
        defaultMessage: 'job使用数据集,状态不允许操作'
    },
    errorCode15011: {
        id: 'gui.error.errorCode15011',
        description: 'job任务重名',
        defaultMessage: 'job任务重名'
    },
    errorCode15022: {
        id: 'gui.error.errorCode15022',
        description: '分布式子任务重名',
        defaultMessage: '分布式子任务重名'
    },
    errorCode15023: {
        id: 'gui.error.errorCode15023',
        description: '训练使用资源池不允许操作',
        defaultMessage: '训练使用资源池不允许操作'
    },
    errorCode15024: {
        id: 'gui.error.errorCode15024',
        description: '用户不允许外部挂载',
        defaultMessage: '用户不允许外部挂载'
    },
    errorCode15025: {
        id: 'gui.error.errorCode15025',
        description: '编译使用资源池不允许操作',
        defaultMessage: '编译使用资源池不允许操作'
    },
    errorCode15026: {
        id: 'gui.error.errorCode15026',
        description: 'job使用模型,状态不允许操作',
        defaultMessage: 'job使用模型,状态不允许操作'
    },
    errorCode16001: {
        id: 'gui.error.errorCode16001',
        description: '没有权限',
        defaultMessage: '没有权限'
    },
    errorCode25001: {
        id: 'gui.error.errorCode25001',
        description: '部署使用计算框架非TF或者PT',
        defaultMessage: '部署使用计算框架非TF或者PT'
    },
    errorCode25002: {
        id: 'gui.error.errorCode25002',
        description: '创建模型部署服务失败',
        defaultMessage: '创建模型部署服务失败'
    },
    errorCode25003: {
        id: 'gui.error.errorCode25003',
        description: '删除模型部署服务失败',
        defaultMessage: '删除模型部署服务失败'
    },
    errorCode25004: {
        id: 'gui.error.errorCode25004',
        description: '模型部署服务请求失败',
        defaultMessage: '模型部署服务请求失败'
    },
    errorCode25005: {
        id: 'gui.error.errorCode25005',
        description: '模型权限校验失败',
        defaultMessage: '模型权限校验失败'
    },
    errorCode25006: {
        id: 'gui.error.errorCode25006',
        description: '模型部署使用资源池不允许操作',
        defaultMessage: '模型部署使用资源池不允许操作'
    },
})

class GUI extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isUIStyle: false,   // 样式切换
            toolType: 1
        };
        this.timer = null
        bindAll(this, [
            'onOpenRoleTab',
            'onOpenSoundsTab',
            'onChangeMessgae',
            'clearStorage'
        ]);
    }
    componentDidMount () {
        new Promise((resolve, reject) =>{
            resolve(this.props.token)
        })
            .then(response => {
                if (!response) this.clearStorage()
                else {
                    let model_extends = localStorage.getItem('mode_extend_variable')
                    try {
                        model_extends = JSON.parse(model_extends)
                        let new_arr = model_extends.filter(item => item.modelVersionName != '-1')
                        localStorage.setItem('mode_extend_variable', JSON.stringify(new_arr))
                    } catch (err) {
                        console.log(err)
                    }
                }
            })
            .catch(err => {
                this.clearStorage()
            })

        setIsScratchDesktop(this.props.isScratchDesktop);
        this.props.onStorageInit(storage);
        this.props.onVmInit(this.props.vm);
        window.current_msg = {
            isLogin: localStorage.getItem('token') ? true : false
        }
        let that = this
        Object.defineProperty(window.current_msg, 'isLogin', {
            get() {
                return ''
            },
            set(newValue) {
                that.props.onRemoveToken()
                // that.props.onSetDimension(true)
            }
        })

        window.globalMsg = {
            demiension: {
                show: false,
                date: ''
            },
            tips: {
                show: false,
                date: ''
            },
            demiension_url: '',
            code_user_message: {
                data: ''
            },
            blockly_dialog: {
                show: false,
                target: null,
                type: null
            },
            blockly_debug: {
                date: '',
                state: true
            },
            CODE_STATUS: {
                date: '',
                type: null,
                id: null
            },
            guide_data: {
                date: '',
                data: null
            }
        }
        Object.defineProperties(window.globalMsg, {
            'demiension': { // 标注平台
                get: function() { return '' },
                set: async function(newV) {
                    if ('url' in newV) {
                        this.demiension_url = newV.url
                        localStorage.setItem('demiension_url', newV.url)
                    }

                    that.props.onSetDimension(true)
                    let token = await localStorage.getItem('token')
                    if (!that.props.dimension_token) {
                        getDimToken({token})
                            .then((response) => {
                                that.props.onSetDimensionToken(response.token)
                            })
                            .catch(() => {})
                    }
                }
            },
            'tips': { // 提示
                get: function() { return '' },
                set: function(newV) {
                    try {
                        that.onChangeMessgae({
                            visible: true,
                            content: that.props.intl.formatMessage(messages[newV.content]),
                            type: newV.type
                        })
                    } catch(err) {
                        that.onChangeMessgae({
                            visible: true,
                            content: newV.content,
                            type: newV.type
                        })
                    }
                }
            },
            'code_user_message': {
                get: function() { return '' },
                set: function(newV) {
                    if (newV.message) {
                        that.props.onSetCodeUserMessage(newV.message)
                        getSpace(newV.message.id)
                            .then((response) => {
                                let data = []
                                if (response.payload !== null) {
                                    data = response.payload.workspaces
                                    data.forEach(item => {
                                        if (item.id === 'default-workspace') {
                                            item.name = '默认群组'
                                        }
                                    })
                                }
                                that.props.onSetGroupData(data)
                            })
                            .catch()
                    }

                }
            },
            'blockly_dialog': { // blocks弹窗
                get: function() { return '' },
                set: function(newV) {
                    if (newV.project === 'blocks' || !newV.project) that.props.onSetBlocklyDialog(newV)
                    else if (newV.project === 'select') that.props.onSetSelectModel(newV)
                }
            },
            'blockly_debug': { // blocks调试按钮
                get: function() { return that.props.debugState },
                set: function(newV) {
                    that.props.vm.runtime.debug_state = newV.state
                    that.props.onSetDebugState(newV.state)
                    if (!newV.state && !that.props.vm.runtime.stage_state) that.props.vm.stopAll(true);
                }
            },
            'CODE_STATUS': { // 训练状态
                get: function() { return '' },
                set: function(newV) {
                    that.props.onSetCodeStatus(newV.type, newV.id)
                }
            },
            'guide_data': { // 引导内容加载
                get: function() {return ''},
                set: function(newV) {
                    if (newV.data) {
                        try {
                            that.props.vm.runtime.setGuideTextContent(newV.data)
                            that.props.onChangeGuide(false)
                            setTimeout(() => {
                                that.props.onChangeGuide(true)
                                that.props.onChangeGuideType('preview')
                                localStorage.removeItem('guideDetail')
                            })
                        } catch(error) {}
                    } else {
                        that.props.vm.runtime.setGuideTextContent({ops: []})
                        that.props.onChangeGuide(false)
                    }
                }
            }
        })
    }
    componentDidUpdate (prevProps) {
        if (this.props.projectId !== prevProps.projectId && this.props.projectId !== null) {
            this.props.onUpdateProjectId(this.props.projectId);
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded
            this.props.onProjectLoaded();

        }
    }
    onOpenRoleTab () {
        this.props.onActivateCostumesTab()
    }
    onOpenSoundsTab () {
        this.props.onActivateSoundsTab()
    }
    onChangeUIStyle = value => {
        // console.log(VM.runtime)
        this.setState({
            isUIStyle: !value
        })
    }
    onChangeMessgae(params) {
        this.props.onSetMessage(params)
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.props.onSetMessage({
                visible: false,
                content: '',
                type: ''
            })
        }, 1500)
    }
    clearStorage() {
       	// localStorage.clear()
        // 清除缓存
        localStorage.removeItem('DATASET')
        localStorage.removeItem('CREATEMODEL')
        localStorage.removeItem('CREATETPUMODEL')
        localStorage.removeItem('RESOURCESPEC')
        localStorage.removeItem('COMPILERESOURCESPEC')
        localStorage.removeItem('CREATECLOUDMODEL')
        localStorage.removeItem('train_parameters')
        localStorage.removeItem('tpu_parameters')
        localStorage.removeItem('cloud_parameters')
        localStorage.removeItem('mode_extend_variable')
        localStorage.removeItem('guideDetail')
    }
    render () {
        if (this.props.isError) {
            throw new Error(
                `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onVmInit,
            projectHost,
            projectId,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            ...componentProps
        } = this.props;
        return (
            <GUIComponent
                loading={fetchingProject || isLoading || loadingStateVisible}
                isUIStyle = {this.state.isUIStyle}
                onChangeUIStyle = {this.onChangeUIStyle}
                toolType = {this.state.toolType}
                onOpenRoleTab = {this.onOpenRoleTab}
                onOpenSoundsTab = {this.onOpenSoundsTab}
                onChangeMessgae={this.onChangeMessgae}
                {...componentProps}
            >
                {children}
            </GUIComponent>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    cloudHost: PropTypes.string,
    projectHost: PropTypes.string,
    fetchingProject: PropTypes.bool,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isSKPack: PropTypes.bool,
    isSKHelp: PropTypes.bool,
    isSKSetting: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    isUIStyle: PropTypes.bool,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onVmInit: PropTypes.func,
    children: PropTypes.node,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    intl: intlShape,
    vm: PropTypes.instanceOf(VM).isRequired
};

GUI.defaultProps = {
    isScratchDesktop: false,
    isSKPack: true,
    isSKHelp: true,
    isSKSetting: true,
    onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => {},
    onUpdateProjectId: () => {},
    onVmInit: (/* vm */) => {},
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
        targetIsStage: (
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
        ),
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        vm: state.scratchGui.vm,
        login_show: state.scratchGui.login.is_show,
        token: state.scratchGui.login.token,
        cas_token: state.scratchGui.login.cas_token,
        manage_token: state.scratchGui.login.manage_token,
        dimension_token: state.scratchGui.login.dimension_token,
        selected_index: state.scratchGui.panel.selected_index,
        workVisible: state.scratchGui.status.work,
        guideVisible: state.scratchGui.guide.state,
        guideMenu: state.scratchGui.guide.menu,
        workType: state.scratchGui.status.work_type,
        messageVisible: state.scratchGui.status.message.visible,
        isWork: state.scratchGui.work.isWork, // 判断是否是教学平台保存作品
        isFile: state.scratchGui.work.isFile, // 判断是否是教学平台保存课程资源
        dialogVisible: state.scratchGui.work.dialogVisible, // 作品保存弹窗
        dialogVisibleZhiya: state.scratchGui.workZhiya.dialogVisibleZhiya, // 社区保存作品弹窗标识
        dimensionVisible: state.scratchGui.status.dimension,
        debugState: state.scratchGui.vmStatus.debug
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch,
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: tab => dispatch(activateTab(tab)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal()),
    onRemoveToken: () => dispatch(removeToken()),
    onChangeWork: (status) => {
        dispatch(changeWork(!status));
        setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    },
    onSetMessage: (params) => dispatch(setMessage(params)),
    onSetDimension: (params) => dispatch(setDimension(params)),
    onSetDimensionToken: (params) => dispatch(setDimensionToken(params)),
    onSetCodeUserMessage: (params) => dispatch(setCodeUserMessage(params)),
    onSetGroupData: (params) => dispatch(setGroupData(params)),
    onSetBlocklyDialog: (params) => dispatch(setBlocklyDialog(params)),
    onSetDebugState: (debug) => dispatch(setDebugState(debug)),
    onSetCodeStatus: (type, param) => dispatch(setCodeStatus(type, param)),
    onSetCreateModel: (param) => dispatch(setCreateModel(param)),
    onSetSelectModel: (param) => dispatch(setSelectModel(param)),
    onChangeGuide: (state) => {
        dispatch(changeGuide(state));
        setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    },
    onChangeGuideMenu: (state) => {
        dispatch(changeGuideMenu(state));
        setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
    },
    onChangeGuideType: (state) => dispatch(changeGuideType(state))
});

const ConnectedGUI = injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(GUI));

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC('Top Level App'),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    TitledHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    SBFileUploaderHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
