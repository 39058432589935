import React from 'react';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import styles from './guide.css';
import IMG_CONTAINER from './img/index.js'
// import LANG_MESSAGE from './lang.js';

import QuillContainer from '../quill-container/quill-container.jsx'

import {changeGuideType} from '../../reducers/guide.js'

class Guide extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            step: [],
            step_act: 0,
            remark: JSON.stringify(props.vm.runtime.getGuideTextContent()),
        }
        this.timer = null
        bindAll(this, [
            'onGetContext',
            'changeStep',
            'eidtStep',
            'setStep'
        ])
    }
    
    componentDidMount() {
        let data = this.props.vm.runtime.getGuideTextContent()
        if (data) {
            this.setStep(data)
        }
    }
    componentDidUpdate() {}
    onGetContext(data) {
        if (this.timer !== null) clearTimeout(this.timer)
        let {ops} = data

        this.timer = setTimeout(() => {
            this.setStep(data)
        }, 1000)
    }
    changeStep(e) {
        if (!isNaN(+e.target.innerText)) {
            let step_act = +e.target.innerText - 1
            this.setState({
                step_act
            })
        }
    }
    eidtStep(type) {
        if (this.state.step.length === 0 || this.state.step.length === 1) return
        let step_act = 0
        if (type === 'left') {
            if (this.state.step_act === 0) step_act = this.state.step.length - 1
            else step_act = --this.state.step_act
        } else if (type === 'right') {
            if (this.state.step_act === this.state.step.length - 1) step_act = 0
            else step_act = ++this.state.step_act
        }
        this.setState({
            step_act
        })
    }
    setStep(data) {
        let arr = []
        let idx = -1
        // let node_list = document.getElementById('guide-edit-box').childNodes[1].childNodes[1].childNodes[1].childNodes[0].childNodes
        let node_list = document.getElementById('guide-quill-editor').childNodes[0].childNodes
        node_list.forEach((item, index) => {
            if (item.nodeName === 'H1') {
                idx++
                arr[idx] = (arr[idx] || '') + item.outerHTML
            } else {
                if (idx !== -1) {
                    arr[idx] = (arr[idx] || '') + item.outerHTML
                }
            }
        })
        this.setState({
            step: arr
        })
    }

    render() {
        return (
            <div className={styles.guideContainer}>
                <div className={styles.editBox} id="guide-edit-box">
                    <div style={{'display': (this.props.guideType === 'preview' ? 'block' : 'none')}}>
                        <div className={styles.previewBack}>
                                <img 
                                    className={styles.backIcon} 
                                    src={IMG_CONTAINER.backIcon} 
                                    alt="" 
                                    onClick={() => this.props.onChangeGuideType('edit')} 
                                />
                        </div>
                        <div className={styles.previewNav}>
                            {this.state.step.length > 0 ? (
                                <>
                                    <img src={IMG_CONTAINER.leftIcon} alt="" onClick={() => this.eidtStep('left')} />
                                    <ul className={styles.stepBox} onClick={this.changeStep}>
                                        {this.state.step.map((item, index) => {
                                            return (
                                                <li className={classNames(styles.stepItem, {
                                                    [styles.stepAct]: this.state.step_act === index
                                                })} key={index}>{index + 1}</li>
                                            )
                                        })}
                                    </ul>
                                    <img src={IMG_CONTAINER.rightIcon} alt="" onClick={() => {
                                        this.setState({
                                            step_act: 0
                                        })
                                        this.eidtStep('right')
                                    }} />
                                </>
                            ) : null}
                        </div>
                        <div
                            className={styles.stepContent}
                            dangerouslySetInnerHTML={{ 
                                __html: this.state.step[this.state.step_act] 
                            }}
                        />  
                    </div>

                    <div style={{'display': (this.props.guideType === 'edit' ? 'block' : 'none')}}>
                        <div className={styles.editNav}>
                            <img 
                                src={IMG_CONTAINER.previewIcon} 
                                alt="" 
                                onClick={() => {
                                    let length = this.state.step.length === 0 ? 0 : this.state.step.length - 1
                                    if (this.state.step_act > length) {
                                        this.setState({
                                            step_act: length
                                        })
                                    }
                                    this.props.onChangeGuideType('preview')
                                }}
                            />
                        </div>
                        <QuillContainer 
                            type="guide"
                            editorId="guide-quill-editor"
                            remark={this.state.remark}
                            readOnly={false}
                            nav={true}
                            vm={this.props.vm}
                            intl={this.props.intl}
                            onGetContext={this.onGetContext}
                            onChangeMessgae={this.props.onChangeMessgae}
                        />
                        <div id="guide-quill-editor"></div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    guideType: state.scratchGui.guide.type
})

const mapDispatchToProps = dispatch => ({
    onChangeGuideType: (type) => dispatch(changeGuideType(type))
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Guide))