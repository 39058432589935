import React from 'react';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
import IMG_CONTAINER from './img/index.js'
import LANG_MESSAGE from './lang.js'
import styles from './blockly_dialog.css'

import { 
    setBlocklyDialog
} from '../../reducers/status.js'

import { getDefaultConfig } from '../../api/login.js'

import {
    getPresetDataSet,
    getCommonDataSet,
    getMyDataSet,
    getPresetModelVersion,
    getCommonModelVersion,
    getMyModelVersion,
    getPresetTpuModelVersion,
    getCommonTpuModelVersion,
    getMyTpuModelVersion,
    getFrameList,
    getAlgorithmmList,
    downloadModel,
    tpuDownloadModel
} from '../../api/code.js'

import { parseTime } from '../../utils/utils.js';

import DataSet from './data_set/data_set.jsx';
import Model from './model/model.jsx'
import Params from './params/params.jsx'
import ParamsDialog from './params-dialog/params-dialog.jsx'

import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

class BlocklyDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: [],
            menu_img: [
                [IMG_CONTAINER.set, IMG_CONTAINER.setActive],
                [IMG_CONTAINER.mode, IMG_CONTAINER.modeActive],
                [IMG_CONTAINER.train, IMG_CONTAINER.trainActive],
                [IMG_CONTAINER.compile, IMG_CONTAINER.compileActive],
                [IMG_CONTAINER.reason, IMG_CONTAINER.reasonActive],
                [IMG_CONTAINER.data, IMG_CONTAINER.dataActive],
                [IMG_CONTAINER.trainParame, IMG_CONTAINER.trainParameActive],
            ],
            menu_act: 0,
            blockly_img: [
                [IMG_CONTAINER.setActive, IMG_CONTAINER.set],
                [IMG_CONTAINER.modeActive, IMG_CONTAINER.mode],
                IMG_CONTAINER.train,
                IMG_CONTAINER.compile,
                IMG_CONTAINER.reason,
                IMG_CONTAINER.data,
                [IMG_CONTAINER.trainParameActive, IMG_CONTAINER.trainParame]
            ],
            type: [
                'DATASET', // 数据集
                'CREATEMODEL', // 模型
                'TRAIN', // 训练
                'COMPILE', // 编译
                'CREATETPUMODEL', // tpu模型(训练模型)
                'CREATECLOUDMODEL', // 编译模型(训练模型)
                'CREATETRAINPARAMETERS', // 训练超参数
                'CREATETPUPARAMETERS', // TPU超参数
                'DATASET_TRAIN_IMPORT', // 创建模型(训练导入数据集)
                'CREATECLOUDPARAMETERS', // 部署超参数
                'TRAIN_IMPORT', // 创建模型(训练模型导入)
                'COMPILE_IMPORT', // 创建模型(编译模型导入)
                'DATASET_COMPILE_IMPORT', // 创建模型(编译导入数据集)
            ],
            cur_type: 0, // 当前type的类型
            nav: [],
            nav_act: 0,
            is_active: false,

            is_load: false, // 是否处于加载状态
            is_more: true, // 是否有下一页数据
            total: 0,
            pageNum: 1,
            pageSize: 10,
            search_value: '',

            list: [],
            list_title: [],
            list_check: -1,

            api_arr: [
                [getPresetDataSet, getCommonDataSet, getMyDataSet],
                [getPresetModelVersion, getCommonModelVersion, getMyModelVersion],
                [],
                [],
                [getPresetTpuModelVersion, getCommonTpuModelVersion, getMyTpuModelVersion],
                [getPresetModelVersion, getCommonModelVersion, getMyModelVersion],
                [],
                [],
                [getPresetDataSet, getCommonDataSet, getMyDataSet],
                [],
                [getPresetModelVersion, getCommonModelVersion, getMyModelVersion],
                [getPresetTpuModelVersion, getCommonTpuModelVersion, getMyTpuModelVersion],
                [getPresetDataSet, getCommonDataSet, getMyDataSet]
            ],

            refresh_timer: null,
            scroll_timer: null,
            distance: 30, // 触底加载边界

            // 训练参数弹框
            params_dialog: false,
            params_type: 'add',
            params_name: null,
            params_value: null,
            params_edit_value: null,
            params_edit_name: null,

            model_name: '',
            start_time: '',
            end_time: '',
            algorithm_list: [],
            frame_list: [],

            is_edit_name: false,
            edit_name_index: -1,
            edit_name: null,
            eidt_value_index: -1,
            eidt_value: null
        }
        bindAll(this, [
            'closeDialog',
            'navHandler',
            'refresh',
            'toTrainPlatform',
            'link',
            'dataSetSource',
            'checkHandle',
            'confirmHandle',
            'scrollHandle',
            'toJSON',
            'modelSource',
            'showParamsDialog',
            'editName',
            'editValue',
            'paramsDialogConfirm',
            'deleteParams',
            'setParamDialogList',
            'changeSearchValue',
            'searchHandle',
            'algorithmList',
            'changeModelName',
            'onChange',
            'onOk',
            'frameList',
            'downloadHandle',
            'doubleInput',
            'blurInput',
            'editNameChange',
            'editValueChange',
            'createModelJson'
        ])
    }
    componentDidMount() {
        moment.locale('zh-cn');
        if (this.props.blocklyDialog?.project === 'blocks') {
            let type = this.state.type.indexOf(this.props.blocklyDialog.type)
            let name = (type === 1 || type === 10) ? this.props.intl.formatMessage(LANG_MESSAGE.MODEL) : 
                (type === 4 || type === 11) ? this.props.intl.formatMessage(LANG_MESSAGE.TPU_MODEL) : 
                this.props.intl.formatMessage(LANG_MESSAGE.CLOUD_MODEL)
            let param_name = type === 6 ? this.props.intl.formatMessage(LANG_MESSAGE.TRAINING_PARAMETERS) : 
                type === 7 ? this.props.intl.formatMessage(LANG_MESSAGE.TPU_HYPERPARAMETERS) : this.props.intl.formatMessage(LANG_MESSAGE.INFERENCE_HYPERPARAMETERS)

            // 导航名称
            let menu = [
                this.props.intl.formatMessage(LANG_MESSAGE.DATA_SET), 
                name, 
                this.props.intl.formatMessage(LANG_MESSAGE.TRAIN), 
                this.props.intl.formatMessage(LANG_MESSAGE.COMPILE), 
                this.props.intl.formatMessage(LANG_MESSAGE.REASONING), 
                this.props.intl.formatMessage(LANG_MESSAGE.DATA_ENGINEERING), 
                param_name
            ]
            // 二级导航名称
            let nav = (type === 0 || type === 8 || type === 12) ? 
            [
                this.props.intl.formatMessage(LANG_MESSAGE.PRESET_DATASET),
                this.props.intl.formatMessage(LANG_MESSAGE.PUBLIC_DATASET), 
                this.props.intl.formatMessage(LANG_MESSAGE.MY_DATASET)
            ] : 
            [
                this.props.intl.formatMessage(LANG_MESSAGE.DEFAULT_MODEL), 
                this.props.intl.formatMessage(LANG_MESSAGE.COMMON_MODEL), 
                this.props.intl.formatMessage(LANG_MESSAGE.MY_MODEL)
            ]

            // 表格标题
            let table_title = {};
            switch(type) {
                case 0:
                case 8:
                case 12:
                    table_title = {
                        name: this.props.intl.formatMessage(LANG_MESSAGE.DATASET_NAME),
                        desc: this.props.intl.formatMessage(LANG_MESSAGE.DATASET_DESCRIPTION),
                        state: this.props.intl.formatMessage(LANG_MESSAGE.status),
                        createdAt: this.props.intl.formatMessage(LANG_MESSAGE.CREATION_TIME)
                    }
                    break
                case 4:
                case 11:
                    table_title = {
                        userName: this.props.intl.formatMessage(LANG_MESSAGE.PROVIDER),
                        versionName: this.props.intl.formatMessage(LANG_MESSAGE.MODEL_NAME),
                        descript: this.props.intl.formatMessage(LANG_MESSAGE.MODEL_DESCRIPTION),
                        version: this.props.intl.formatMessage(LANG_MESSAGE.VERSION),
                        createdAt: this.props.intl.formatMessage(LANG_MESSAGE.CREATION_TIME),
                        type: this.props.intl.formatMessage(LANG_MESSAGE.APPLICABLE_CHIPS),
                        operation: this.props.intl.formatMessage(LANG_MESSAGE.OPERATE)
                    }
                    break
                case 1:
                case 5:
                case 10:
                    table_title = {
                        userName: this.props.intl.formatMessage(LANG_MESSAGE.PROVIDER),
                        versionName: this.props.intl.formatMessage(LANG_MESSAGE.MODEL_NAME),
                        descript: this.props.intl.formatMessage(LANG_MESSAGE.MODEL_DESCRIPTION),
                        version: this.props.intl.formatMessage(LANG_MESSAGE.VERSION),
                        createdAt: this.props.intl.formatMessage(LANG_MESSAGE.CREATION_TIME),
                        algorithmName: this.props.intl.formatMessage(LANG_MESSAGE.ALGORITHM),
                        operation: this.props.intl.formatMessage(LANG_MESSAGE.OPERATE)
                    }
                    break
                default:
                    break
            }

            this.setState({
                cur_type: type,
                menu: menu,
                menu_act: [1, 4, 5, 10, 11].includes(type) ? 1 : 
                    [6, 7, 9].includes(type) ? 6 : 
                    [0, 8, 12].includes(type) ? 0 : type,
                nav: nav,
                list_title: table_title
            }, () => {
                if (type === 6 || type === 7 || type === 9) { // 超参数
                    let str = type === 6 ? 'train_parameters' : 
                        type === 7 ? 'tpu_parameters' : 'cloud_parameters'
                    let arr
                    try {
                        arr = JSON.parse(localStorage.getItem(str)) || []
                    } catch (error) {
                        arr = []
                    }

                    this.setParamDialogList(arr)
                } else {
                    this.algorithmList(this.state.nav_act)
                    this.frameList()
                    this.link()
                }
            })
        }
    }
    // 关闭弹窗
    closeDialog() {
        window.globalMsg.blockly_dialog = {
            show: false,
            target: null,
            project: null,
            type: null
        }
    }
    navHandler(index) {
        if (index === this.state.nav_act) return false

        if (!this.is_load) {
            this.setState({
                nav_act: index,
                total: 0,
                pageNum: 1,

                list: [],
                list_check: -1
            }, async () => {
                if (this.state.menu_act === 1) {
                   await this.algorithmList(this.state.nav_act)
                }
                this.link()
            })
        }
    }
    // 刷新
    refresh() {
        if (this.state.refresh_timer !== null) { clearTimeout(this.state.refresh_timer) }

        let timer = setTimeout(() => {
            if (!this.state.is_load) {
                this.setState({
                    list: [],
                    total: 0,
                    pageNum: 1,
                    is_more: true,
                    is_load: false
                }, () => {
                    this.link()
                })
            }
        })
        this.setState({
            refresh_timer: timer
        })
    }
    // 上传数据集/跳转训练平台
    async toTrainPlatform() {
        // let url = ''
        // let response = await getDefaultConfig({name: 'label'})
        // if (response.success) {
        //     url = response.payload.settings[0].value + '/user/login?'
        // }
        // url += 'token=' + await this.props.token
        // window.open(url)

        localStorage.removeItem('create_msg')
        let response = await getDefaultConfig({ name: 'label' })
        if (response.success) {
            window.globalMsg.demiension = {
                show: true,
                url: response.payload.settings[0].value,
                date: new Date()
            }
        }
    }
    // 接口获取数据
    async link() {
        this.setState({
            is_load: true
        })

        let id = null
        let algorithmId, createdAtGte, createdAtLt, searchKey;

        if (this.state.menu_act === 0) {
            searchKey = this.state.search_value
        } else if (this.state.menu_act === 1) {
            searchKey = this.state.model_name
            createdAtGte = this.state.start_time
            createdAtLt = this.state.end_time
            let arr = this.state.algorithm_list.filter((item) => item.algorithmName === this.refs.algorithmSelect.value)
            if (arr.length > 0) algorithmId = arr[0].algorithmId
            let arr1 = this.state.frame_list.filter((item) => item.lableDesc === this.refs.frameSelect.value)
            if (arr1.length > 0) id = arr1[0].id
        }

        let api = this.state.api_arr[this.state.cur_type][this.state.nav_act]
        let response = await api({
            pageIndex: this.state.pageNum,
            pageSize: this.state.pageSize,
            searchKey: searchKey || '',
            frameWorkId: id || null,
            createdAtGte: createdAtGte || null,
            createdAtLt: createdAtLt || null,
            algorithmId: algorithmId || null
        })

        if (response.success) {
            let payload = response.payload

            let total = payload.totalSize
            let more = +total > (+this.state.pageNum * +this.state.pageSize)
            let list = []
            this.setState({
                total: total,
                is_load: false,
                is_more: more
            })

            // 初始化判断标题
            if (this.state.list.length === 0) {
                list.push(this.state.list_title)
            }

            // 初始化数据
            switch(this.state.cur_type) {
                case 0: // 积木数据集
                case 8: // 训练数据集
                case 12: // 编译数据集
                    this.dataSetSource(list, payload)
                    return
                case 1: // 训练模型
                case 4: // 编译模型
                case 5: // 云模型
                case 10: // 训练模型
                case 11: // 编译模型
                    this.modelSource(list, payload)
                    return
                default:
                    return
            }
        } else {
            // 提示错误信息
            this.setState({
                is_load: false
            })
        }
    }
    // 数据集数据处理
    dataSetSource(list, payload) {
        if (payload?.datasets && payload?.datasets.length > 0) {
            payload.datasets.forEach((item) => {
                item['createdAt'] = parseTime(item['createdAt'])
                item['state'] = ['无', '未上传', '解压中', '解压成功', '解压失败'][item.status]
                list.push(item)
            })
        }
        this.setState({
            list: this.state.list.concat(list)
        })
    }
    // 模型数据处理
    modelSource(list, payload) {
        if (payload?.modelVersions && payload?.modelVersions.length > 0) {
            payload.modelVersions.forEach((item) => {
                if (item['createdAt']) item['createdAt'] = parseTime(item['createdAt'])
                if (item?.versionDetail?.createdAt) item.versionDetail.createdAt = parseTime(item.versionDetail.createdAt)
                list.push(item)
            })
        }
        this.setState({
            list: this.state.list.concat(list)
        })
    }
    // 触底加载
    scrollHandle(e) {
        if (this.state.is_load) return
        if (!this.state.is_more) return
        if (this.state.scroll_timer !== null) { clearTimeout(this.state.scroll_timer) }

        let scrollTop = e.target.scrollTop
        let scrollHeight = e.target.scrollHeight
        let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height)
        let currentHeight = scrollTop + offsetHeight + this.state.distance
        let timer = setTimeout(() => {
            if (currentHeight >= scrollHeight) {
                console.log('刷新数据')
                this.setState({
                    pageNum: this.state.pageNum+=1
                }, () => {
                    this.link()
                })
            }
        }, 500)

        this.setState({
            scroll_timer: timer
        })
    }
    // 选中状态
    checkHandle(index) {
        this.setState({
            list_check: index === this.state.list_check ? -1 : index
        })
    }
    // 确认添加
    confirmHandle() {
        let data = this.state.list[this.state.list_check]

        // return
        let name, str;

        // 数据集
        if (this.state.cur_type === 0) {
            str = data.id + '\u272A' + data.latestVersion + 'BLOCKNAME=' + data.name
            name = data.name
        }

        // 训练模型/云模型
        if (this.state.cur_type === 1 || this.state.cur_type === 5) {
            if (data?.versionDetail) {
                name = data.versionDetail.versionName + ':' + data.versionDetail.version
                str = data.versionDetail.modelId + '\u272A' + data.versionDetail.version + '\u272A' + data.versionDetail.versionName + '\u272A' + data.versionDetail.descript + (data.versionDetail.algorithmId ? ('\u272A' + data.versionDetail.algorithmId) : '') + 'BLOCKNAME=' + data.versionDetail.versionName + ':' + data.versionDetail.version 
            } else {
                name = data.versionName + ':' + data.version
                str = data.modelId + '\u272A' + data.version + '\u272A' + data.versionName + '\u272A' + data.descript + (data.algorithmId ? ('\u272A' + data.algorithmId) : '') + 'BLOCKNAME=' + data.versionName + ':' + data.version 
            }
        }

        // 编译模型
        if (this.state.cur_type === 4) {
            if (data?.versionDetail) {
                name = data.versionDetail.versionName + ':' + data.versionDetail.version
                str = data.versionDetail.tpuModelId + '\u272A' + data.versionDetail.version + '\u272A' + data.versionDetail.versionName + '\u272A' + data.versionDetail.descript + (data.versionDetail.algorithmId ? ('\u272A' + data.versionDetail.algorithmId) : '') + 'BLOCKNAME=' + data.versionDetail.versionName + ':' + data.versionDetail.version 
            } else {
                name = data.versionName + ':' + data.version
                str = data.tpuModelId + '\u272A' + data.version + '\u272A' + data.versionName + '\u272A' + data.descript + (data.algorithmId ? ('\u272A' + data.algorithmId) : '') + 'BLOCKNAME=' + data.versionName + ':' + data.version 
            }
        }
        // this.props.blocklyDialog.type === 'DATASET_IMPORT'
        if (
            this.props.blocklyDialog.type === 'DATASET_TRAIN_IMPORT' ||
            this.props.blocklyDialog.type === 'DATASET_COMPILE_IMPORT'
        ) { // 新建模型导入数据集
            let type = this.props.blocklyDialog.type.split('_')[1].toLowerCase()
            let obj = {
                show: true,
                type: type + '-model',
                data_set: {
                    id: data.id,
                    version: data.latestVersion,
                    name: data.name,
                }
            }
            // let data_set = {
            //     id: data.id,
            //     version: data.latestVersion,
            //     name: data.name,
            // }
            // if (type === 'train') {
            //     obj['data_set'] = data_set
            // } else if (type === 'compile') {
            //     obj['compile_data_set'] = data_set
            // }

            this.props.onSetCreateModel(obj)
        } else if ( // 新建模型导入训练，编译的模型
            this.props.blocklyDialog.type === 'TRAIN_IMPORT'
        ) {
            // let modelVersionName = data?.versionDetail ? data.versionDetail.versionName : data.versionName
            let modelVersionName = '-1'
            let new_data = {
                is_select: true,
                modelVersionName,
                train_detail: {
                    modelId: data?.versionDetail ? data.versionDetail.modelId : data.modelId,
                    modelVersion: data?.versionDetail ? data.versionDetail.version : data.version,
                    modelVersionName,
                    modelDes: data?.versionDetail ? data.versionDetail.descript : data.descript,
                    algorithmId: data?.versionDetail ? data.versionDetail.algorithmId : data.algorithmId
                }
            }
            this.createModelJson(new_data, modelVersionName, 'train-model')
        } else if (this.props.blocklyDialog.type === 'COMPILE_IMPORT') {
            // let modelVersionName = data?.versionDetail ? data.versionDetail.versionName : data.versionName
            let modelVersionName = '-1'
            let new_data = {
                is_select: true,
                modelVersionName,
                compile_detail: {
                    tpuModelId:  data?.versionDetail ? data.versionDetail.tpuModelId : data.tpuModelId,
                    tpuModelVersion:  data?.versionDetail ? data.versionDetail.version : data.version
                }
            }
            this.createModelJson(new_data, modelVersionName, 'compile-model')
        } else {
            this.toJSON(data)
            window.blocklyMsg.drop_down = {
                target: this.props.blocklyDialog.target,
                str: str,
                name: name,
                date: new Date()
            }
        }

        this.closeDialog()
    }
    // 存储本地
    async toJSON(data) {
        let obj, index;
        switch(this.state.cur_type) {
            case 0:
                obj = await localStorage.getItem('DATASET') ? JSON.parse(localStorage.getItem('DATASET')) : []
                if (obj.length > 0) {
                    index = obj.findIndex((item) => (item.id === data.id) && (item.name === data.name)) 
                }
                break
            case 1:
            case 4:
            case 5:
                if (this.state.cur_type === 1) obj = await localStorage.getItem('CREATEMODEL') ? JSON.parse(localStorage.getItem('CREATEMODEL')) : []
                if (this.state.cur_type === 4) obj = await localStorage.getItem('CREATETPUMODEL') ? JSON.parse(localStorage.getItem('CREATETPUMODEL')) : []
                if (this.state.cur_type === 5) obj = await localStorage.getItem('CREATECLOUDMODEL') ? JSON.parse(localStorage.getItem('CREATECLOUDMODEL')) : []
                if (obj.length > 0) {
                    index = obj.findIndex((item) => {
                        if (item.versionDetail && data.versionDetail) {
                            return (item.versionDetail.id === data.versionDetail.id) && (item.versionDetail.version === data.versionDetail.version)
                        } else {
                            return (item.id === data.id) && (item.version === data.version)
                        }
                    })
                }
                break
            default:
                break
        }

        if (index != -1) {
            obj.splice(index, 1)
        }
        obj.push(data)
        localStorage.setItem(this.state.type[this.state.cur_type], JSON.stringify(obj))
    }
    async createModelJson(data, name, type) {
        let arr
        try {
            arr = await JSON.parse(localStorage.getItem('mode_extend_variable')) || []
        } catch(err) {
            arr = []
        }
        arr.push(data)
        localStorage.setItem('mode_extend_variable', JSON.stringify(arr))
        let {modelVersionName} = this.props.createModel

        this.props.onSetCreateModel({
            show: true,
            type: 'create-' + type + '-' + name,
            is_select: true,
            // modelVersionName: modelVersionName || name
        })
    }
    setParamDialogList(data) {
        let list = []
        list.push({
            name: this.props.intl.formatMessage(LANG_MESSAGE.DISPLAY_NAME),
            value: this.props.intl.formatMessage(LANG_MESSAGE.PARAMETER),
            operate: this.props.intl.formatMessage(LANG_MESSAGE.OPERATE)
        })

        data.forEach((item) => {
            list.push({
                name: item[0],
                value: item[1]
            })
        })

        this.setState({
            list: list
        })
    }
    // 添加训练参数
    showParamsDialog(status, type, value = '', name = '') {
        this.setState({
            params_dialog: status,
            params_type: type,
            params_value: value,
            params_name: name,
            params_edit_value: type === 'edit' ? value : null,
            params_edit_name: type === 'edit' ? name : null
        })
    }
    editValue(e) {
        this.setState({
            params_value: e.target.value
        })
    }
    editName(e) {
        this.setState({
            params_name: e.target.value
        })
    }
    paramsDialogConfirm() {
        if (!this.state.params_value || !this.state.params_name) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.ENTER_MESSAGE),
                type: 'fail'
            })
            return false
        }

        let arr = []
        let name = this.state.params_name
        let value = this.state.params_value
        if (this.state.params_type === 'add') {
            let check = this.props.blocklyDialog.target.menuGenerator_.findIndex((item) => item[0] === name)
            if (check != -1) {
                this.props.onChangeMessgae({
                    visible: true,
                    content: this.props.intl.formatMessage(LANG_MESSAGE.DUPLICATE_TRAINING_PARAMETER_NAME),
                    type: 'fail'
                })
                return
            }
        } else if (this.state.params_type === 'edit') {
            if (name !== this.state.params_edit_name) {
                let check = this.props.blocklyDialog.target.menuGenerator_.findIndex((item) => item[0] === name)
                if (check != -1) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.DUPLICATE_TRAINING_PARAMETER_NAME),
                        type: 'fail'
                    })
                    return
                }
            }
        }

        let str = this.state.cur_type === 6 ? 'train_parameters' : 
            this.state.cur_type === 7 ? 'tpu_parameters' : 'cloud_parameters'
        let index = this.state.cur_type === 6 ? 4 : 
            this.state.cur_type === 7 ? 4 : 4

        let n_val = name + '\u272A' + value
        if (this.state.params_type === 'add') {
            this.props.blocklyDialog.target.menuGenerator_.push([name, n_val])
            arr = this.props.blocklyDialog.target.menuGenerator_.slice(index)
            localStorage.setItem(str, JSON.stringify(arr))
        } else if (this.state.params_type === 'edit') {
            let edit_idx = this.props.blocklyDialog.target.menuGenerator_.findIndex((item) => item[0] === this.state.params_edit_name)
            this.props.blocklyDialog.target.menuGenerator_[edit_idx] = [name, n_val]
            arr = this.props.blocklyDialog.target.menuGenerator_.slice(index)
            localStorage.setItem(str, JSON.stringify(arr))
        }

        window.blocklyMsg.drop_down = {
            target: this.props.blocklyDialog.target,
            str: n_val,
            name,
            date: new Date()
        }
        
        this.setParamDialogList(arr)

        this.showParamsDialog(false, '', '', '')
    }
    deleteParams(name) {
        let index = this.props.blocklyDialog.target.menuGenerator_.findIndex((item) => item[0] === name)
        let cur_val = this.props.blocklyDialog.target.value_
        let select_index = this.props.blocklyDialog.target.menuGenerator_.findIndex((item) => item[1] === cur_val)
        this.props.blocklyDialog.target.menuGenerator_.splice(index, 1)
        this.props.blocklyDialog.target.menuGenerator_ = this.props.blocklyDialog.target.menuGenerator_
        let default_len = this.state.cur_type === 6 ? 4 : 
            this.state.cur_type === 7 ? 4 : 4
        let arr = this.props.blocklyDialog.target.menuGenerator_.filter((item, index) => index >= default_len)
        let str = this.state.cur_type === 6 ? 'train_parameters' : 
            this.state.cur_type === 7 ? 'tpu_parameters' : 'cloud_parameters'

        localStorage.setItem(str, JSON.stringify(arr.length > 0 ? arr : []))
        if ((cur_val != this.props.intl.formatMessage(LANG_MESSAGE.PLEASE_SELECT) || cur_val != this.props.intl.formatMessage(LANG_MESSAGE.MANAGE_TRAINING_PARAMETERS)) && select_index != -1) {
            if (index === select_index) {
                if (this.props.blocklyDialog.target.menuGenerator_.length > index) {
                    let value = this.props.blocklyDialog.target.menuGenerator_[index][1]
                    let name = this.props.blocklyDialog.target.menuGenerator_[index][0]
                    window.blocklyMsg.drop_down = {
                        target: this.props.blocklyDialog.target,
                        str: value,
                        name: name,
                        date: new Date()
                    }
                }
                if (this.props.blocklyDialog.target.menuGenerator_.length = index) {
                    let value = this.props.blocklyDialog.target.menuGenerator_[index-1][1]
                    let name = this.props.blocklyDialog.target.menuGenerator_[index-1][0]
                    window.blocklyMsg.drop_down = {
                        target: this.props.blocklyDialog.target,
                        str: value,
                        name: name,
                        date: new Date()
                    }
                }
            }
        }

        this.setParamDialogList(arr)
    }
    changeSearchValue(e) {
        this.setState({
            search_value: e.target.value
        })
    }
    searchHandle() {
        this.setState({
            list: [],
            total: 0,
            pageNum: 1,
            is_more: true,
        }, () => {
            this.link()
        })
    }
    // 筛选算法
    algorithmList(type) {
        if (this.state.menu_act === 1) this.refs.algorithmSelect.value = ''
        getAlgorithmmList({
            pageIndex: 1,
            pageSize: 100,
            type: [3, 2, 1][type]
        })
            .then((response) => {
                if (response.success) {
                    let list = response.payload.algorithms || []
                    list.unshift({
                        algorithmName: this.props.intl.formatMessage(LANG_MESSAGE.all),
                        algorithmId: ''
                    })
                    this.setState({
                        algorithm_list: list
                    }, () => {
                        if (this.state.menu_act === 1) this.refs.algorithmSelect.value = ''
                    })
                } else {
                    this.setState({
                        algorithm_list: []
                    }, () => {
                        if (this.state.menu_act === 1) this.refs.algorithmSelect.value = ''
                    })
                }
            })
            .catch(() => {})
    }
    // 筛选框架
    frameList() {
        getFrameList({
            pageIndex: 1,
            pageSize: 50
        })
            .then((response) => {
                if (response.success) {
                    let list = response.payload.lables || []
                    list.unshift({
                        lableDesc: this.props.intl.formatMessage(LANG_MESSAGE.all),
                        id: ''
                    })
                    this.setState({
                        frame_list: list
                    }, () => {
                        if (this.refs?.frameSelect?.value) this.refs.frameSelect.value = ''
                    })
                } else {
                    this.setState({
                        frame_list: []
                    }, () => {
                        if (this.refs?.frameSelect?.value) this.refs.frameSelect.value = ''
                    })
                }
            })
            .catch(() => {})
    }
    changeModelName(e) {
        this.setState({
            model_name: e.target.value
        })
    }
    onChange(value, dateString) {
        this.setState({
            start_time: dateString[0] ? new Date(dateString[0]).getTime() / 1000 : '',
            end_time: dateString[1] ? new Date(dateString[1]).getTime() / 1000 : ''
        })
    }
    onOk(value) {
        // console.log(value)
    }
    // 下载模型
    async downloadHandle(props) {
        if (props.hasOwnProperty('fileStatus') && props?.fileStatus !== 2) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.UPLOAD_MODEL_FILE),
                type: 'fail'
            })
            return
        } else if (props.hasOwnProperty('versionDetail') && props.versionDetail.fileStatus !== 2) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.UPLOAD_MODEL_FILE),
                type: 'fail'
            })
            return
        }
        let type = props.hasOwnProperty('versionDetail')

        let config = await getDefaultConfig({name: 'tpusp'})
        if (config.success) {
            let api = (this.state.cur_type === 4 || this.state.cur_type === 11) ? tpuDownloadModel : downloadModel
            let param = {
                version: (this.state.cur_type === 4 || this.state.cur_type === 11) ? (!type ? props.version : props.versionDetail.version) : (!type ? props.version : props.versionDetail.version),
                modelId: (this.state.cur_type === 4 || this.state.cur_type === 11) ? (!type ? props.tpuModelId : props.versionDetail.tpuModelId) : (!type ? props.modelId : props.versionDetail.modelId),
                domian: config.payload.settings[0].value
            }
            api(param)
                .then(response => {
                    if (response.success) {
                        const link = document.createElement('a')
                        link.style = 'display: none'; // 创建一个隐藏的a标签
                        // link.setAttribute('download', '下载文件')
                        link.setAttribute('href', response.payload.downloadUrl)
                        link.setAttribute('target', "_blank")
                        document.body.appendChild(link);

                        setTimeout(() => {
                            link.click(); // 触发a标签的click事件
                            document.body.removeChild(link);
                        }, 100)
                    } else {
                        this.props.onChangeMessgae({
                            visible: true,
                            content: response.error.subMessage,
                            type: 'fail'
                        })
                    }
                })
                .catch((error) => {})
        }
    }
    // 修改显示名称
    doubleInput(index, type, name, value) {
        if (index === 0) return
        this.setState({
            ['edit_' + type + '_index']: index,
            edit_name: name,
            edit_value: value
        })
    }
    blurInput(index, type) {
        let {name, value} = this.state.list[index]

        if (!name || !value) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.ENTER_MESSAGE),
                type: 'fail'
            })
            return false
        }
        
        if (type === 'name') {
            if (name !== this.state.edit_name) {
                let check = this.props.blocklyDialog.target.menuGenerator_.findIndex((item) => item[0] === name)
                if (check != -1) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.DUPLICATE_TRAINING_PARAMETER_NAME),
                        type: 'fail'
                    })
                    return
                }
            }
        }
        value = (value.indexOf('\u272A') !== -1 ? value.substring(value.indexOf('\u272A') + 1) : value)

        let str = this.state.cur_type === 6 ? 'train_parameters' : 
            this.state.cur_type === 7 ? 'tpu_parameters' : 'cloud_parameters'
        let idx = this.state.cur_type === 6 ? 4 : 
            this.state.cur_type === 7 ? 4 : 4

        let n_val = name + '\u272A' + value
        let arr = []
        let edit_idx = this.props.blocklyDialog.target.menuGenerator_.findIndex((item) => item[0] === this.state.edit_name)
        this.props.blocklyDialog.target.menuGenerator_[edit_idx] = [name, n_val]
        arr = this.props.blocklyDialog.target.menuGenerator_.slice(idx)
        localStorage.setItem(str, JSON.stringify(arr))

        window.blocklyMsg.drop_down = {
            target: this.props.blocklyDialog.target,
            str: n_val,
            name,
            date: new Date()
        }
        this.setParamDialogList(arr)

        this.setState({
            ['edit_' + type + '_index']: -1,
            edit_name: null,
            edit_value: null
        })
    }
    editNameChange(e, index) {
        let arr = this.state.list
        arr[index].name = e.target.value
        this.setState({
            list: arr
        })
    }
    editValueChange(e, index) {
        let arr = this.state.list
        arr[index].value = e.target.value
        this.setState({
            list: arr
        })
    }
    
    render() {
        return (
            <div className={styles.blocklyDialog}>
                {!this.state.params_dialog ? (
                    <div className={styles.containerBox}>
                        <div className={styles.header}>
                            <ul className={styles.menu}>
                                {this.state.menu.map((item, index) => {
                                    return (
                                        <li key={index} className={styles.menuItem}
                                            style={{
                                                borderBottom: this.state.menu_act === index ? '.1875rem solid #CE8137' : '',
                                                color: this.state.menu_act === index ? '#CE8137' : '#E1B387'
                                            }}
                                        >
                                            <img 
                                                className={styles.menuImg} 
                                                src={this.state.menu_act === index ? this.state.menu_img[index][1] : this.state.menu_img[index][0]}
                                            />
                                            <div className={styles.menuText}>{this.state.menu[index]}</div>
                                        </li>
                                    )
                                })}
                            </ul>
                            <img className={styles.closeIcon} src={IMG_CONTAINER.close} onClick={this.closeDialog} />
                        </div>

                        <div className={styles.content}>
                            {[6,7,9].includes(this.state.cur_type) ? 
                            (
                                <>
                                    <div className={styles.addParams} onClick={() => this.showParamsDialog(true, 'add')}>
                                        <img src={IMG_CONTAINER.upload} />
                                        <span>{
                                            this.state.cur_type === 6 ? this.props.intl.formatMessage(LANG_MESSAGE.ADD_TRAINING_PARAMETERS) : 
                                            this.state.cur_type === 7 ? this.props.intl.formatMessage(LANG_MESSAGE.ADD_TPU_PARAMETERS) :
                                            this.props.intl.formatMessage(LANG_MESSAGE.ADD_INFERENCE_PARAMETERS)
                                        }</span>
                                    </div>
                                    <ul className={styles.listBox}>
                                        <Params 
                                            showParamsDialog={this.showParamsDialog} 
                                            deleteParams={this.deleteParams} 
                                            doubleInput={this.doubleInput}
                                            blurInput={this.blurInput}
                                            editNameChange={this.editNameChange}
                                            editValueChange={this.editValueChange}
                                            edit_name_index={this.state.edit_name_index}
                                            edit_value_index={this.state.edit_value_index}
                                            list={this.state.list} 
                                            edit={this.props.intl.formatMessage(LANG_MESSAGE.edit)}
                                            delete={this.props.intl.formatMessage(LANG_MESSAGE.delete)}
                                        />
                                    </ul>
                                </>
                            ) : 
                            (
                                <>
                                    {/* 模型搜索功能 */}
                                    {this.state.menu_act === 1 ? (
                                        <div className={styles.modelSearchBox}>
                                            <div className={styles.modelName}>
                                                <span>{this.props.intl.formatMessage(LANG_MESSAGE.MODEL_NAME)}</span>
                                                <input type="text" placeholder={this.props.intl.formatMessage(LANG_MESSAGE.ENTER_MODEL_NAME)} value={this.state.model_name} onChange={this.changeModelName} />
                                            </div>
                                            <div className={styles.timeBox}>
                                                <span>{this.props.intl.formatMessage(LANG_MESSAGE.TIME)}</span>
                                                <RangePicker
                                                    showTime={{ format: 'HH:mm:ss' }}
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    placeholder={['Start Time', 'End Time']}
                                                    onChange={this.onChange}
                                                    onOk={this.onOk}
                                                />
                                            </div>
                                            <div className={styles.algorithmBox}>
                                                <span>{this.props.intl.formatMessage(LANG_MESSAGE.ALGORITHM)}</span>
                                                <select name="" id="algorithmSelect" ref="algorithmSelect">
                                                    {this.state.algorithm_list.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.algorithmName}>{item.algorithmName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className={styles.algorithmBox}>
                                                <span>{this.props.intl.formatMessage(LANG_MESSAGE.FRAMEWORK)}</span>
                                                <select name="" id="frameSelect" ref="frameSelect">
                                                    {this.state.frame_list.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.lableDesc}>{item.lableDesc}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <button onClick={this.refresh}>{this.props.intl.formatMessage(LANG_MESSAGE.filterPlaceholder)}</button>
                                        </div>
                                    ) : null}

                                    <div className={styles.navBox}>
                                        <ul className={styles.nav}>
                                            {this.state.nav.map((item, index) => {
                                                return (
                                                    <li key={index}
                                                        style={{
                                                            margin: index === 0 ? '0' : '0 0 0 1rem',
                                                            background: index == this.state.nav_act ? '#CE8137' : '#FDF6DC',
                                                            color: index == this.state.nav_act ? '#FFFFFF' : '#CE8137'
                                                        }}
                                                        onClick={() => this.navHandler(index)}
                                                    >{item}</li>
                                                )
                                            })}
                                            <img className={styles.refreshIcon} src={IMG_CONTAINER.refresh} onClick={this.refresh} />
                                        </ul>
                                        {/* 数据集搜索框 */}
                                        {[0, 8, 12].includes(this.state.cur_type) ? (
                                            <div className={styles.searchBox}>
                                                <input className={styles.searchInput} type="text" placeholder={this.props.intl.formatMessage(LANG_MESSAGE.KEYWORD_SEARCH)} value={this.state.search_value} onChange={this.changeSearchValue} />
                                                <img className={styles.searchImg} src={IMG_CONTAINER.search} />
                                                <button className={styles.searchBtn} onClick={this.searchHandle}>{this.props.intl.formatMessage(LANG_MESSAGE.filterPlaceholder)}</button>
                                            </div>
                                        ) : null}
                                    </div>
                                    {/* 上传数据集 */}
                                    {this.state.menu_act === 0 && this.state.nav_act === 2 ? (
                                        <div className={styles.uploadBox} onClick={this.toTrainPlatform}>
                                            <img src={IMG_CONTAINER.upload} />
                                            <span>{this.props.intl.formatMessage(LANG_MESSAGE.NEW_DATA_ENGINEERING)}</span>
                                        </div>
                                    ) : null}

                                    {this.props.token ? (
                                        <ul className={styles.listBox} onScroll={this.scrollHandle}>
                                            {/* 数据集 */}
                                            {this.state.menu_act === 0 ? 
                                                <DataSet 
                                                    list={this.state.list} 
                                                    list_check={this.state.list_check}
                                                    checkHandle={this.checkHandle}
                                                />
                                            : null}
                                            {/* 训练/编译模型 */}
                                            {this.state.menu_act === 1 ? 
                                                <Model 
                                                    type={this.state.cur_type}
                                                    list={this.state.list}
                                                    list_check={this.state.list_check}
                                                    down_title={this.props.intl.formatMessage(LANG_MESSAGE.download)}
                                                    checkHandle={this.checkHandle}
                                                    downloadHandle={this.downloadHandle}
                                                />
                                            : null}
                                        </ul>
                                    ) : (
                                        <div className={styles.tips}>{this.props.intl.formatMessage(LANG_MESSAGE.NO_LOGIN)}</div>
                                    )}
                                </>
                            )}
                        </div>
                        
                        {this.state.list_check !== -1 ? (
                            <div className={styles.footer}>
                                <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.CONFIRM_ADD)} onClick={this.confirmHandle} />
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {/* 新增，编辑训练参数 */}
                {this.state.params_dialog ? (
                    <ParamsDialog 
                        type={this.state.params_type} 
                        name={this.state.params_name}
                        value={this.state.params_value}
                        editName={this.editName}
                        editValue={this.editValue}

                        showParamsDialog={this.showParamsDialog}
                        paramsDialogConfirm={this.paramsDialogConfirm}
                        add={this.props.intl.formatMessage(LANG_MESSAGE.NEWLY_ADDED)}
                        edit={this.props.intl.formatMessage(LANG_MESSAGE.edit)}
                        cancel={this.props.intl.formatMessage(LANG_MESSAGE.cancel)}
                        ok={this.props.intl.formatMessage(LANG_MESSAGE.ok)}
                        display_name={this.props.intl.formatMessage(LANG_MESSAGE.DISPLAY_NAME)}
                        param_name={this.props.intl.formatMessage(LANG_MESSAGE.PARAMETER)}
                    />
                ) : null}
            </div>
        )
    }
}

BlocklyDialog.propTypes = {
}

const mapStateToProps = state => ({
    blocklyDialog: state.scratchGui.status.blockly_dialog,
    // locale: state.scratchGui.locales.locale
})

const mapDispatchToProps = dispatch => ({
    onSetBlocklyDialog: (params) => dispatch(setBlocklyDialog(params))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlocklyDialog)
