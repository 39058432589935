exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".select-model_com-dialog-container_1iTYN {\n    position: fixed;\n    z-index: 1001;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    text-align: center;\n    background: rgba(0, 0, 0, .4);\n}\n.select-model_box_zaV8R {\n    display: inline-block;\n    text-align: left;\n    vertical-align: middle;\n    width: auto;\n    max-width: 31.25rem;\n    height: auto;\n    margin: 20rem 0 0 0;\n    padding: 2.5rem 2.5rem 1.5625rem 2.5rem;\n    border-radius: .5rem;\n    background: #FFFFFF;\n    font-family: Source Han Sans CN-Regular;\n}\n.select-model_header_1ktxe {\n    font-size: 1.125rem;\n    color: #333333;\n}\n.select-model_content_1jnd1 {\n    margin: 1rem 0 1.9375rem 0;\n    color: #666666;\n    font-size: .875rem;\n}\n.select-model_footer_3yTvT {\n    text-align: center;\n}\n.select-model_cancle_I8_IQ {\n    margin: 0 3.375rem 0 0 ;\n    border: 1px solid #CE8137;\n    background: #FFFFFF;\n    color: #CE8137;\n}\n.select-model_comfirm_2bmmh {\n    border: none;\n    background: #CE8137;\n    color: #FFFFFF;\n}\n.select-model_com-btn_1dEiE {\n    width: auto;\n    height: auto;\n    border-radius: .375rem;\n    padding: .6875rem 2.8125rem .5625rem;\n    font-size: 1rem;\n}\n.select-model_cancle_I8_IQ:active,\n.select-model_comfirm_2bmmh:active {\n    scale: 0.8;\n}", ""]);

// exports
exports.locals = {
	"com-dialog-container": "select-model_com-dialog-container_1iTYN",
	"comDialogContainer": "select-model_com-dialog-container_1iTYN",
	"box": "select-model_box_zaV8R",
	"header": "select-model_header_1ktxe",
	"content": "select-model_content_1jnd1",
	"footer": "select-model_footer_3yTvT",
	"cancle": "select-model_cancle_I8_IQ",
	"comfirm": "select-model_comfirm_2bmmh",
	"com-btn": "select-model_com-btn_1dEiE",
	"comBtn": "select-model_com-btn_1dEiE"
};