import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React from 'react';

import VM from 'scratch-vm';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import CommunityButton from './community-button.jsx';
import ShareButton from './share-button.jsx';
import { ComingSoonTooltip } from '../coming-soon/coming-soon.jsx';
import ProjectWatcher from '../../containers/project-watcher.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import { MenuItem, MenuSection } from '../menu/menu.jsx';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import RightMenu from '../right-menu/right-menu.jsx';
import Rerun from '../rerun/rerun.jsx';
import HelperMenu from '../helper-menu/helper-menu.jsx'
import GroupMenu from '../group-menu/group-menu.jsx'
import xhr from 'xhr';
import {BitmapAdapter as V2BitmapAdapter1} from 'scratch-svg-renderer';

// 文件
import fileIcon from './file.svg';
// 下拉图标
import skDownCaret from './downCaret.svg';
// 帮助
import skHelpIcon from './help.svg';
// 设置
import skSettingIcon from './setting.svg';
import remixIcon from './icon--remix.svg';
import aboutIcon from './icon--about.svg';
import scratchLogo from './logo.svg';
import pictureIcon from './icon--picture.svg';
import loginIcon from './icon--login.png';
import bgIcon from './bg.png';
import outIcon from './icon/out.png'
import userIcon from './icon/user.png'
import workIcon from './icon/work.png'

import { openTipsLibrary } from '../../reducers/modals';
import { setPlayer } from '../../reducers/mode';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    // openPackMenu,
    // closePackMenu,
    filePackOpen,
    openHelpMenu,
    closeHelpMenu,
    helpMenuOpen,
    openSetupMenu,
    closeSetupMenu,
    setupMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen
} from '../../reducers/menus';
import { 
    setToken, 
    loginOpen, 
    loginOut, 
    loginMenuStatus,
    editMenu,
    setManageToken,
    setManageUserMessage,
    setZhiyaToken
} from '../../reducers/login.js'
import { 
    setWorkType, 
    setTaskIndex, 
    setTask, 
    getTaskDetail,
    setTaskDetail,
    setStudentHomewrok,
    setCorrectInfo,
    setTpuState
} from '../../reducers/status.js'
import {
    getWorkDetail,
    setWorkId,
    setWorkPath,
    setDescription,
    setWorkCover,
    setType,
    setIsWork,
    getImageBaseUrl,
    setImageBaseUrl,
    setDialogVisible,
    setWorkName,
    getDictFileDetail,
    setFileId,
    setIsFile,
    setFilePath,
    setFileData
} from '../../reducers/work.js'

import {
  setDialogVisibleZhiya,
  setProgramId,
  setWorkId as setWorkIdZhiya,
  setWorkCover as setWorkCoverZhiya,
  setWorkPath as setWorkPathZhiya,
  setUserId,
  setTitle,
  setSort,
  setRemark,
  getWorkDetailZhiya,
  setCanSaveZhiya
} from '../../reducers/work-zhiya.js'

import collectMetadata from '../../lib/collect-metadata';
import sharedMessages from '../../lib/shared-messages';
import styles from './menu-bar.css';

/* 单点登录对接 */
import SDK from 'casdoor-js-sdk';
import { config } from '../../utils/setting.js'
import { resizeExtensiond, getUrlParams } from '../../utils/utils.js'
import { getCastoken, getCodeToken } from '../../api/login.js'
import { 
    getManageToken, 
    createWrok, 
    getCourseWork, 
    editWrok, 
    getFilePath, 
    uploadWork,
    submitJob,
    studentAsignmentDetails,
    saveJob,
    resizeJob,
    deleteFiles,
    updateWork,
    saveWork,
    getConfigKey,
    getDictFile,
    getTpuUrl
} from '../../api/manage.js'
import {
  getZhiyaTokenByCasToken,
  getFilePathZhiya,
  publishWorkDetail
} from '../../api/zhiya.js'
// 暂时没用到，注释代码
// import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SaveStatus from './save-status.jsx';
// import ProjectTitleInput from './project-title-input.jsx';
// import AuthorInfo from './author-info.jsx';
// import AccountNav from '../../containers/account-nav.jsx';
// import LoginDropdown from './login-dropdown.jsx';
// 背包
// import packIcon from './pack.svg';
// 多人协作
// import othersIcon from './othersIcon.svg';
// import mystuffIcon from './icon--mystuff.png';

const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    },
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    },
    login: {
        id: 'gui.login.loginOut',
        defaultMessage: '退出登录',
        description: 'accessibility text for the tutorials button'
    },
    pluginUpdates: {
        id: 'gui.menuBar.pluginUpdates',
        description: 'Label for the name of the pluginUpdates',
        defaultMessage: 'pluginUpdates'
    },
    jobSavedSuccessfully: {
        id: 'gui.menuBar.jobSavedSuccessfully',
        description: 'Label for the name of the jobSavedSuccessfully',
        defaultMessage: 'jobSavedSuccessfully'
    },
    jobSaveFailed: {
        id: 'gui.menuBar.jobSaveFailed',
        description: 'Label for the name of the jobSaveFailed',
        defaultMessage: 'jobSaveFailed'
    },
    successfullyEdited: {
        id: 'gui.menuBar.successfullyEdited',
        description: 'Label for the name of the successfullyEdited',
        defaultMessage: 'successfullyEdited'
    },
    editingFailed: {
        id: 'gui.menuBar.editingFailed',
        description: 'Label for the name of the editingFailed',
        defaultMessage: 'editingFailed'
    },
    createdSuccessfully: {
        id: 'gui.dimesion.createdSuccessfully',
        description: 'Label for the name of the createdSuccessfully',
        defaultMessage: 'createdSuccessfully'
    },
    creationFailed: {
        id: 'gui.menuBar.creationFailed',
        description: 'Label for the name of the creationFailed',
        defaultMessage: 'creationFailed'
    },
    assignmentSubmittedSuccess: {
        id: 'gui.menuBar.assignmentSubmittedSuccess',
        description: 'Label for the name of the assignmentSubmittedSuccess',
        defaultMessage: 'assignmentSubmittedSuccess'
    },
    jobSubmissionFailed: {
        id: 'gui.menuBar.jobSubmissionFailed',
        description: 'Label for the name of the jobSubmissionFailed',
        defaultMessage: 'jobSubmissionFailed'
    },
    jobResetSuccessful: {
        id: 'gui.menuBar.jobResetSuccessful',
        description: 'Label for the name of the jobResetSuccessful',
        defaultMessage: 'jobResetSuccessful'
    },
    jobResetFailed: {
        id: 'gui.menuBar.jobResetFailed',
        description: 'Label for the name of the jobResetFailed',
        defaultMessage: 'jobResetFailed'
    },
    saveWork: {
        id: 'gui.menuBar.saveWork',
        description: 'Label for the name of the saveWork',
        defaultMessage: 'saveWork'
    },
    editJob: {
        id: 'gui.menuBar.editJob',
        description: 'Label for the name of the editJob',
        defaultMessage: 'editJob'
    },
    createJob: {
        id: 'gui.menuBar.createJob',
        description: 'Label for the name of the createJob',
        defaultMessage: 'createJob'
    },
    submitJob: {
        id: 'gui.menuBar.submitJob',
        description: 'Label for the name of the submitJob',
        defaultMessage: 'submitJob'
    },
    resetJob: {
        id: 'gui.menuBar.resetJob',
        description: 'Label for the name of the resetJob',
        defaultMessage: 'resetJob'
    },
    headSculpture: {
        id: 'gui.menuBar.headSculpture',
        description: 'Label for the name of the headSculpture',
        defaultMessage: 'headSculpture'
    },
    signIn: {
        id: 'general.signIn',
        description: 'Label for the name of the signIn',
        defaultMessage: 'signIn'
    },
    personal: {
        id: 'gui.menuBar.personal',
        description: '个人中心',
        defaultMessage: '个人中心'
    },
    works: {
        id: 'gui.menuBar.works',
        description: '作品管理',
        defaultMessage: '作品管理'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};

MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({ id, isRtl, children, className }) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

const AboutButton = props => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            versionDiv: '',
            servicesDiv:'',
            services: '',
            showLangule: false,
            task_index: 1,
            work_arr: [{index: 1}, {index: 2}, {index: 3}],
            file_path: '', // 上传作业路径
        }
        bindAll(this, [
            'handleClickNew',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleKeyPress',
            'handleLanguageMouseUp',
            'handleRestoreOption',
            'getSaveToComputerHandler',
            'restoreOptionMessage',
            'onMounthLanguage',
            'handleClickMorePeople',
            'handleSignIn',
            'handleCreateWork',
            'dowloadFilePath',
            'handleSubmitJob',
            'handleResizeJob',
            'onDeleteFiles',
            'handleEditWork',
            'handleImageBaseUrl',
            'handleGetUserAvatar',
            'loginType',
            'handleEditFile',
            'setCasToken',
            'handleZhiyaWork',
            'dowloadFilePathZhiya',
            'toCommunity'
        ]);
    }
    // 检测插件更新
    checkUpTpu() {
        xhr({
            method: 'get',
            url: BASE_URL + `/checkUpdate`
        }, (error, response) => {
            if (error || response.statusCode !== 200) {

                return (new Error(response.status));
            }
            let gotServices = (JSON.parse(response.body).data.download_url)
            this.setState({
                services: gotServices
            })

            this.setState({
                servicesDiv: (JSON.parse(response.body).data.has_update ?
                    (
                        <div style={{padding:'10px'}}>
                            <a href={this.state.services} style={{textDecoration:'none'}}>{this.props.intl.formatMessage(ariaMessages.pluginUpdates)}</a>
                        </div>
                    ) : (<div></div>))
            });
        });
    }
    componentWillMount() {
        // this.checkUpTpu();
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
        window.addEventListener('message', (e) => {
            if (e?.data?.message && e?.data?.message?.type === 'cas') {
                if (!this.props.cas_token || !this.props.token) {
                    let params = getUrlParams(e.data.message.value)
                    if (params.code && params.state) {
                        this.setCasToken(params.code, params.state)
                    }
                }
            }
        })

        // 扩展处理
        setTimeout(() => {
            resizeExtensiond()
        })
        /**
         * 人员管理跳转 
         * dictId：目录id 
         * dictName：目录名称 
         * courseId：课程id
         * taskId: 学生任务id 用去查看作业详情
         * c：cas_token换取平台token 
         * t：当前操作类型 create edit detail
         * i：编辑下标 index
         */
        let params = getUrlParams(window.location.href)
        // console.log("params:",params)
        if (params.c && params.t) {
            this.props.onLoginOut(false, this.props.cas_token) // 退出当前登录
            // if (!this.props.manage_token) {

                // 老师创建作业需要的字段目录名称，id，课程id
                this.props.onSetManageUserMessage({
                    manage_user_message: {
                        dictId: params.dictId || '',
                        dictName: decodeURI(params.dictName) || '',
                        courseId: params.courseId || ''
                    }
                })
                // 设置当前操作类型
                this.props.onSetWorkType(params.t)

                // 设置类型
                this.props.onSetType(params.t)
                // 编程平台token获取
                let response1 = new Promise((resolve, reject) => {
                    let res = getCodeToken({
                        casToken: params.c
                    })
                    resolve(res)
                })
                // 人员管理token获取
                let response2 = new Promise((resolve, reject) => {
                    let res = getManageToken({
                        casToken: params.c
                    })
                    resolve(res)
                })
                // 同步处理token，获取用户信息
                Promise.all([response1, response2])
                    .then(async (response) => {
                        let temp_v = {}
                        if (response[0].success) {
                            temp_v['token'] = response[0].payload.token
                        }
                        if (response[1].msg === '操作成功') {
                            temp_v['casToken'] = response[1].casToken
                            this.props.onSetToken(temp_v)
                            this.handleImageBaseUrl() // 获取图片请求头
                            // 获取用户信息
                            this.props.onSetManageToken(
                                response[1].token,
                                (a, b) => { // a 用户信息 b 用户类型
                                    // console.log("a 用户信息 b 用户类型", a, b)
                                    // 保存用户信息
                                    this.props.onSetManageUserMessage(a)
                                    // 学生
                                    if (b === 'student') {
                                        // 学生数据保存
                                        this.props.onSetStudentHomewrok({
                                            taskFile: params.taskFile,
                                            classId: params.classId,
                                            taskId: params.taskId,
                                            status: params.status
                                        })
                                        this.props.onGetTaskDetail(
                                            params.taskId,
                                            (c) => { // 老师作业详情（与自己作业对比是否做作业）

                                                if (params.distributeId) {
                                                    studentAsignmentDetails(params.distributeId)
                                                        .then(response => {
                                                            let file;
                                                            if (response.msg === '操作成功') {
                                                                file = response.data.taskFile
                                                            }
                                                            this.dowloadFilePath(file || c.filePath)
                                                        })
                                                        .catch()
                                                }
                                                // let file = params.taskFile || c.filePath
                                                // this.dowloadFilePath(file)
                                                this.props.onSetTaskDetail(c)
                                            }
                                        )
                                    }
                                    // 老师 评分
                                    if (b === 'teacher' && params.t === 'correct') {
                                        // 保存评分用户信息
                                        this.props.onSetCorrectInfo({
                                            studentId: params.studentId,
                                            classId: params.classId,
                                            taskId: params.taskId
                                        })
                                        // 获取学生作业详情
                                        this.props.onGetTaskDetail(
                                            params.taskId,
                                            (c) => { // 老师作业详情（与自己作业对比是否做作业）
                                                if (params.distributeId) {
                                                    studentAsignmentDetails(params.distributeId)
                                                        .then(response => {
                                                            this.props.onSetStudentHomewrok(response.data, true)

                                                            let file;
                                                            if (response.msg === '操作成功') {
                                                                file = response.data.correctFile || response.data.taskFile
                                                                this.props.onSetCorrectInfo({
                                                                    correctFile: response.data.correctFile,
                                                                    correctGrade: response.data.correctGrade,
                                                                    correctRemark: response.data.correctRemark
                                                                })
                                                            }
                                                            this.dowloadFilePath(file)
                                                        })
                                                        .catch()
                                                }
                                                this.props.onSetTaskDetail(c)
                                            }
                                        )
                                    }
                                }
                            )
                            if (params.t === 'create') { // 创建作业
                                // 老师创建作业请求目录下作业
                                let res = await getCourseWork({
                                    courseId: params.courseId,
                                    dictId: params.dictId,
                                })

                                if (res.msg === '查询成功' && res.rows.length > 0) {
                                    let arr = []
                                    res.rows.forEach((item) => {
                                        let v = {}
                                        v['file_path'] = item.filePath || ''
                                        v['remark'] = item.description || ''
                                        v['status'] = item.status
                                        v['taskId'] = item.taskId
                                        arr.push(v)
                                    })
                                    this.props.onSetTask(arr)

                                    let file = await getFilePath(res.rows[0].filePath)
                                    if (file) {
                                        let blob = new Blob([file])
                                        let reader = new FileReader()
                                        reader.onload = (e) => {
                                            setTimeout(() => {
                                                this.props.vm.loadProject(reader.result)
                                                    .then((res) => {
                                                        console.log(res, '加载文件')
                                                    })
                                            }, 500)
                                        }
                                        reader.readAsArrayBuffer(blob)
                                    }
                                }
                            }

                            if (params.t === 'detail') { // 查看作业详情
                                this.props.onGetTaskDetail(
                                    params.taskId,
                                    (a) => {
                                        let b = {}
                                        b['file_path'] = a.filePath || ''
                                        b['remark'] = a.description || ''
                                        b['status'] = a.status || ''
                                        b['taskId'] = a.taskId || ''
                                        this.props.onSetTask([b])

                                        this.dowloadFilePath(b['file_path'])
                                    }
                                )
                            }
                            // 作品详情修改
                            if (params.t === 'work_detail') {
                                // this.handleImageBaseUrl()
                                this.props.onSetIsWork(true)
                                this.props.onSetWorkId(params.workId)
                                
                                this.props.onGetWorkDetail(
                                    params.workId,
                                    (a) => {
                                        this.props.onSetWorkPath(a.workPath)
                                        this.props.onSetDescription(a.description)
                                        this.props.onSetWorkCover(a.workCover)
                                        this.props.onSetWorkName(a.workName)
                                        this.dowloadFilePath(a.workPath)
                                    }
                                )
                            }
                            // 作品添加
                            if (params.t === 'work_create') {
                                // this.handleImageBaseUrl()
                                this.props.onSetIsWork(true)
                                // this.props.onSetDescription('我的作品')
                            }
                            // 资源详情
                            if (params.t === 'dict_file') {
                                this.props.onGetDictFileDetail(params.fileId, (a) => {
                                    console.log("a", a)
                                    this.props.onSetFilePath(a.filePath)
                                    this.props.onSetFileId(a.fileId)
                                    this.props.onSetIsFile(true)
                                    this.props.onSetFileData(a)
                                    this.dowloadFilePath(a.filePath)
                                })
                            }
                        }
                        this.props.onSetToken(temp_v)
                        this.props.onChangeWork(false)
                        // 作品详情, 作品添加, 资源详情
                        if (params.t === 'dict_file' || params.t === 'work_create' || params.t === 'work_detail') {
                            // 不显示作业窗口
                            this.props.onChangeWork(true)
                        }
                    })
                    .catch(() => {})
            // }
        }
        /* 
        * 从智芽社区跳转过来，携带 castoken, 
        * 自动获取训练平台token 和智芽社区token
        */
        if (params.from === 'zhiya') {
            // 
            this.props.onSetProgramId(params.programId)
            //
            this.props.onSetUserId(params.userId)
            // 编程平台token获取
            if (params.cas_token) {
              let response3 = new Promise((resolve, reject) => {
                let res = getCodeToken({
                    casToken: params.cas_token
                })
                resolve(res)
              })
              // 智芽社区token 获取
              let response4 = new Promise((resolve, reject) => {
                  let res = getZhiyaTokenByCasToken({
                      casToken: params.cas_token
                  })
                  resolve(res)
              })
              Promise.all([response3, response4]).then(async (response) => {
                // console.log('token', response)
                let temp_v = {}
                if (response[0].success) {
                  temp_v['token'] = response[0].payload.token
                }
                if (response[1].msg === '操作成功') {
                  temp_v['casToken'] = response[1].casToken
                }
                this.props.onSetToken(temp_v)
                this.props.onSetZhiyaToken(response[1].token)
                if (params.workId) {
                  this.props.onGetWorkDetailZhiya(params.workId, (res) => {
                    // console.log("作品详情", res)
                    this.props.onSetWorkCoverZhiya(res.workCover)
                    this.props.onSetWorkIdZhiya(res.workId)
                    this.props.onSetWorkPathZhiya(res.workPath)
                    this.props.onSetTitle(res.title)
                    this.props.onSetSort(res.sort)
                    this.props.onSetRemark(res.remark)
                    this.props.onSetCanSaveZhiya(true)
                    this.dowloadFilePathZhiya(res.workPath)
                  })
                }
              })
            }
            if (params.releaseId) {
              let response5 = new Promise((resolve, reject) => {
                let res = publishWorkDetail(params.releaseId)
                resolve(res)
              })
              Promise.all([response5]).then(res => {
                // console.log("作品详情", res)
                if (res[0].msg === "操作成功") {
                  this.dowloadFilePathZhiya(res[0].data.workInfo.workPath)
                }
              })
            }
        }

        /* 单点登录处理，地址栏存在code或者state参数 */
        if (!this.props.cas_token || !this.props.token) {
            if (params.code && params.state) {
                this.setCasToken(params.code, params.state)
            }
        }
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    setCasToken(code, state) {
        new Promise((resolve, reject) => {
            let res = getCastoken({
                code,
                state
            })
            resolve(res)
        })
            .then((response) => {
                if (response.success) {
                    this.props.onSetToken(response.payload)
                }
            })
            .catch()
    }

    loginType() {
        if(+LOGIN_TYPE === 1) {
            this.props.onOpenLogin()
        } else {
            this.handleSignIn(+LOGIN_TYPE)
        }
    }
    handleClickNew() {
        // 智芽 点击新作品，清空智芽跳过来保存的数据
        // 暂时把保存数据的按钮关闭，注意 work-zhiya中的数据未清除
        this.props.onSetCanSaveZhiya(false)
        //如果项目是脏的，并且用户拥有该项目，我们将自动保存。

        //但是如果他们没有登录并且无法保存，用户应该考虑

        //首先下载或登录。

        //注意，如果用户登录并编辑其他人的项目，

        //他们会失去工作的。
        const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
            this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
        );
        this.props.onRequestCloseFile();

        if (readyToReplaceProject) {
            this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
        
            setTimeout(() => {
                let target = this.props.vm.runtime.getTargetForStage().getCurrentCostume()
                let bitmapAdapter1 = new V2BitmapAdapter1();
                bitmapAdapter1.changeBackdropBitmap(target.asset.data, `image/${target.asset.dataFormat}`)
                    .then((imageData) => {
                        this.props.vm.updateStageTargetBitmap(
                            this.props.vm.runtime.getTargetForStage().currentCostume, 
                            imageData,
                            imageData.width / 2,
                            imageData.height / 2,
                            2
                        )
                    })
                    .catch(() => {})
                    .finally(() => { bitmapAdapter1 = null })
            }, 300)
        }
        this.props.onRequestCloseFile();
    }
    onMounthLanguage () {
        this.props.onRequestCloseSetting();
    }
    handleClickRemix() {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave() {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy() {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity(waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); //转换到项目页之前保存
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // 将转换排队到项目页面
        }
    }
    handleClickShare(waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    // 恢复
    handleRestoreOption(restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress(event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    getSaveToComputerHandler(downloadProjectCallback) {
        if (this.props.manage_user_message.roles[0] === 'student' && this.props.student_homework.status != '4'  && this.props.student_homework.status != '2' && !this.props.isWork && !this.props.isFile) {
            saveJob({
                taskFile: this.props.student_homework.taskFile,
                classId: +this.props.student_homework.classId,
                taskId: +this.props.student_homework.taskId,
            })
                .then((response) => {
                    if (response.msg === '操作成功') {
                        this.props.onChangeMessgae({
                            visible: true,
                            content: this.props.intl.formatMessage(ariaMessages.jobSavedSuccessfully),
                            type: 'success'
                        })
                    } else {
                        this.props.onChangeMessgae({
                            visible: true,
                            content: this.props.intl.formatMessage(ariaMessages.jobSaveFailed),
                            type: 'fail'
                        })
                    }
                })
            return 
        } else {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
        }
        // if (this.props.manage_user_message.roles[0] === 'student' && this.props.student_homework.status != '4'  && this.props.student_homework.status != '2') {
        //     saveJob({
        //         taskFile: this.props.student_homework.taskFile,
        //         classId: +this.props.student_homework.classId,
        //         taskId: +this.props.student_homework.taskId,
        //     })
        //         .then((response) => {
        //             if (response.msg === '操作成功') {
        //                 this.props.onChangeMessgae({
        //                     visible: true,
        //                     content: '作业保存成功',
        //                     type: 'success'
        //                 })
        //             } else {
        //                 this.props.onChangeMessgae({
        //                     visible: true,
        //                     content: '作业保存失败',
        //                     type: 'fail'
        //                 })
        //             }
        //         })
        //     return 
        // }

        // return () => {
        //     this.props.onRequestCloseFile();
        //     downloadProjectCallback();
        //     if (this.props.onProjectTelemetryEvent) {
        //         const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
        //         this.props.onProjectTelemetryEvent('projectDidSave', metadata);
        //     }
        // };
    }
    handleLanguageMouseUp(e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    restoreOptionMessage(deletedItem) {
        switch (deletedItem) {
            case 'Sprite':
                return (<FormattedMessage
                    defaultMessage="Restore Sprite"
                    description="Menu bar item for restoring the last deleted sprite."
                    id="gui.menuBar.restoreSprite"
                />);
            case 'Sound':
                return (<FormattedMessage
                    defaultMessage="Restore Sound"
                    description="Menu bar item for restoring the last deleted sound."
                    id="gui.menuBar.restoreSound"
                />);
            case 'Costume':
                return (<FormattedMessage
                    defaultMessage="Restore Costume"
                    description="Menu bar item for restoring the last deleted costume."
                    id="gui.menuBar.restoreCostume"
                />);
            default: {
                return (<FormattedMessage
                    defaultMessage="Restore"
                    description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                    id="gui.menuBar.restore"
                />);
            }
        }
    }
    buildAboutMenu(onClickAbout) {
        if (!onClickAbout) {
            // hide the button
            return null;
        }
        if (typeof onClickAbout === 'function') {
            // make a button which calls a function
            return <AboutButton onClick={onClickAbout} />;
        }
        // assume it's an array of objects
        // each item must have a 'title' FormattedMessage and a 'handleClick' function
        // generate a menu with items for each object in the array
        return (
            <div
                className={classNames(styles.menuBarItem, styles.hoverable, {
                    [styles.active]: this.props.aboutMenuOpen
                })}
                onMouseUp={this.props.onRequestOpenAbout}
            >
                <img
                    className={styles.aboutIcon}
                    src={aboutIcon}
                />
                <MenuBarMenu
                    className={classNames(styles.menuBarMenu)}
                    open={this.props.aboutMenuOpen}
                    place={this.props.isRtl ? 'right' : 'left'}
                    onRequestClose={this.props.onRequestCloseAbout}
                >
                    {
                        onClickAbout.map(itemProps => (
                            <MenuItem
                                key={itemProps.title}
                                isRtl={this.props.isRtl}
                                onClick={this.wrapAboutMenuCallback(itemProps.onClick)}
                            >
                                {itemProps.title}
                            </MenuItem>
                        ))
                    }
                </MenuBarMenu>
            </div>
        );
    }
    wrapAboutMenuCallback(callback) {
        return () => {
            callback();
            this.props.onRequestCloseAbout();
        };
    }
    handleClickMorePeople () {
        // toast.warning('敬请期待');
    }
    // type: 2跳转页面 3弹框
    handleSignIn(type) {
        if (!this.props.token && !this.props.cas_token) {
            let sdk = new SDK(config)
            if (+type === 2) {
                sdk = new SDK(config)
                window.location.href = sdk.getSigninUrl()
            } else if (+type === 3) {
                window.open(sdk.getSigninUrl(),"newwindow","height=700,width=1000")
            }

            // 跳转
            // let sdk = new SDK(config)
            // window.location.href = sdk.getSigninUrl()
            // window.open(sdk.getSigninUrl(),"newwindow","height=700,width=1000")
        } else {
            if (!this.props.loginMenuStatus) {
                this.props.onEditMenu(true)
            }
        }
    }
    // 作业高亮
    // async handleDirAct(index) {
    //     if (this.state.task_index === index) return
    //     this.setState({
    //         task_index: index
    //     })
    //     this.props.onSetTaskIndex(index)
    //     let param = this.props.task[index - 1]
    //     if (param.file_path) {
    //         let file = await getFilePath(param.file_path)
    //         if (file) {
    //             let blob = new Blob([file])
    //             let reader = new FileReader()
    //             reader.onload = (e) => {
    //                 this.props.vm.loadProject(reader.result)
    //                     .then((res) => {
    //                         console.log(res)
    //                     })
    //             }
    //             reader.readAsArrayBuffer(blob)
    //         }
    //     } else {
    //         this.handleClickNew()
    //     }
    // }
    /**
     * 创建作业
     * 流程
     * 1，上传作品
     * 2，taskid判断编辑/创建
     */ 
    async handleCreateWork() {
        let old_file = this.props.task[this.state.task_index - 1].file_path
        this.props.saveProjectSb3()
            .then(async connect => {
                let formData = new FormData()
                const file = new File([connect], '1.sk', { type: 'text/plain', lastModified: Date.now() })
                formData.append('file', file)
                formData.append('path', '/task')
                let response1 = await uploadWork(formData)
                if (response1.msg === '操作成功') {
                    // 保存上传后的作品地址
                    this.props.onSetTask({file_path: response1.path}, this.state.task_index - 1)
                    let { courseId, dictId, dictName } = this.props.manage_user_message
                    let { file_path, status, taskId } = this.props.task[this.state.task_index - 1]
                    let remark = this.props.rich_text_content                    

                    let response;
                    if (taskId) { // 编辑
                        response = await editWrok({
                            sort: this.state.task_index,
                            taskName: dictName,
                            filePath: file_path,
                            description: remark,
                            courseId,
                            dictId,
                            taskId,
                            status
                        })
                        if (response.msg === '操作成功') {
                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(ariaMessages.successfullyEdited),
                                type: 'success'
                            })

                            this.onDeleteFiles(old_file)
                        } else {
                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(ariaMessages.editingFailed),
                                type: 'fail'
                            })
                        }
                    } else { // 创建
                        response = await createWrok({
                            sort: this.state.task_index,
                            filePath: file_path,
                            description: remark,
                            taskName: dictName,
                            status: 1,
                            courseId,
                            dictId
                        })

                        if (response.msg === '操作成功') {
                            this.props.onSetManageUserMessage({
                                manage_user_message: {}
                            })
                            this.props.onSetTask({
                                taskId: response.data.taskId
                            }, this.state.task_index - 1)
                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(ariaMessages.createdSuccessfully),
                                type: 'success'
                            })
                        } else {
                            this.props.onChangeMessgae({
                                visible: true,
                                content: this.props.intl.formatMessage(ariaMessages.creationFailed),
                                type: 'fail'
                            })
                        }
                    }
                }
            })
    }
    // 获取并赋值imagebaseurl 用于封面拼接url
    async handleImageBaseUrl() {
        let response =  await getConfigKey('fileurl')
        if (response.code === 200) {
            this.props.onSetImageBaseUrl(response.msg)
        } 
    }
    /**
     * 保存作品
     * 流程
     * 1、上传作品
     * 2、创建作品/修改作品
     */
    async handleEditWork() {
        // console.log("保存作品")
        this.props.onSetDialogVisible(true)
        // let old_workPath = this.props.workPath
        // this.props.saveProjectSb3()
        //     .then(async connect => {
        //         let formData = new FormData()
        //         const file = new File([connect], '1.sk', { type: 'text/plain', lastModified: Date.now() })
        //         formData.append('file', file)
        //         formData.append('path', '/work')
        //         let response1 = await uploadWork(formData)
        //         if (response1.msg === '操作成功') {
        //             // 保存上传后的作品地址
        //             this.props.onSetWorkPath(response1.path)

        //             // 保存作品
        //             let response;

        //             if (this.props.workId) {
        //                 response = await updateWork({
        //                     workId: this.props.workId,
        //                     workPath: response1.path,
        //                     description: this.props.description,
        //                     workCover: this.props.workCover
        //                 })
        //                 if (response.msg === '操作成功') {
        //                     this.props.onChangeMessgae({
        //                         visible: true,
        //                         content: '编辑成功',
        //                         type: 'success'
        //                     })
    
        //                     this.onDeleteFiles(old_workPath)
        //                 } else {

        //                     this.props.onChangeMessgae({
        //                         visible: true,
        //                         content: '编辑失败',
        //                         type: 'fail'
        //                     })
        //                     this.onDeleteFiles(response1.path)
        //                 }
        //             } else {
        //                 response = await saveWork({
        //                     workPath: response1.path,
        //                     description: this.props.description,
        //                 })
        //                 if (response.msg === '操作成功') {
        //                     this.props.onSetWorkId(response.data.workId)
        //                     this.props.onSetDescription(response.data.description)
        //                     this.props.onSetWorkPath(response.data.workPath)
        //                     this.props.onSetWorkCover(response.data.workCover)
        //                     this.props.onSetType('work_detail')
        //                     this.props.onChangeMessgae({
        //                         visible: true,
        //                         content: '创建成功',
        //                         type: 'success'
        //                     })
        //                 } else {
        //                     this.props.onChangeMessgae({
        //                         visible: true,
        //                         content: '创建失败',
        //                         type: 'fail'
        //                     })
        //                     this.onDeleteFiles(response1.path)
        //                 }
        //             }
   
        //         }
        //     })
    }
    async handleEditFile() {
        // console.log("fileData", this.props.fileData)
        this.props.onSetDialogVisible(true)
    }
    // 智芽 保存作品
    async handleZhiyaWork () {
      this.props.onSetDialogVisibleZhiya(true)
    }
    // 下载作品
    async dowloadFilePath(path) {
        let file = await getFilePath(path)
        if (file) {
            let blob = new Blob([file])
            let reader = new FileReader()
            reader.onload = (e) => {
                setTimeout(() => {
                    this.props.vm.loadProject(reader.result)
                        .then((res) => {
                            console.log(res, '加载文件')
                        })
                }, 500)
            }
            reader.readAsArrayBuffer(blob)
        }
    }
    // 下载作品
    async dowloadFilePathZhiya(path) {
      let file = await getFilePathZhiya(path)
      if (file) {
          let blob = new Blob([file])
          let reader = new FileReader()
          reader.onload = (e) => {
              setTimeout(() => {
                  this.props.vm.loadProject(reader.result)
                      .then((res) => {
                          console.log(res, '加载文件')
                      })
              }, 500)
          }
          reader.readAsArrayBuffer(blob)
      }
    }
    /**
     * 提交作业
     * 流程
     * 1，上传作品
     * 2，taskid判断编辑/创建
     */ 
    handleSubmitJob() {
        let old_file = this.props.student_homework.taskFile
        this.props.saveProjectSb3()
            .then(async connect => {
                let formData = new FormData()
                const file = new File([connect], '1.sk', { type: 'text/plain', lastModified: Date.now() })
                formData.append('file', file)
                formData.append('path', '/task')
                let response1 = await uploadWork(formData)
                if (response1.msg === '操作成功') {
                    // 保存上传作品地址
                    this.props.onSetStudentHomewrok({
                        taskFile: response1.path
                    })
                    submitJob({
                        taskFile: this.props.student_homework.taskFile,
                        classId: +this.props.student_homework.classId,
                        taskId: +this.props.student_homework.taskId,
                    })
                        .then(response => {
                            if (response.msg === '操作成功') {
                                this.props.onChangeMessgae({
                                    visible: true,
                                    content: this.props.intl.formatMessage(ariaMessages.assignmentSubmittedSuccess),
                                    type: 'success'
                                })
                                this.onDeleteFiles(old_file)
                            } else {
                                this.props.onChangeMessgae({
                                    visible: true,
                                    content: this.props.intl.formatMessage(ariaMessages.jobSubmissionFailed),
                                    type: 'fail'
                                })
                            }
                        })
                        .catch(() => {})
                }
            })
    }
    // 重置作业
    handleResizeJob() {
        resizeJob({
            classId: +this.props.student_homework.classId,
            taskId: +this.props.student_homework.taskId,
        })
            .then((response) => {
                if (response.msg === '操作成功') {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(ariaMessages.jobResetSuccessful),
                        type: 'success'
                    })
                    this.props.onSetStudentHomewrok({
                        taskFile: ''
                    })
                    this.dowloadFilePath(this.props.task_detail.filePath)
                } else {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(ariaMessages.jobResetFailed),
                        type: 'fail'
                    })
                }
            })
            .catch(() => {})
    }
    onDeleteFiles(path) {
        let formData = new FormData()
        formData.append('path', path)
        deleteFiles(formData)
            .then((response) => {
                console.log(response, '删除文件')
            })
            .catch(() => {})
    }
    // 返回用户头像
    handleGetUserAvatar() {
        // if 判断顺序很重要
        if (this.props.manage_token) {
            return this.props.imageBaseUrl + this.props.manage_user_message.user.avatar
        }
        // 弹窗登录
        if (this.props.token) {
            return pictureIcon;
        }
        // 未登录
        return loginIcon;
    }
    // 跳转智芽社区平台
    toCommunity(type) {
        getTpuUrl('zhiya_url')
            .then((res) => {
                if (res.code === 200) {
                    window.open(`${res.msg}?t=${type}&c=${this.props.cas_token}`, '_blank')
                }
                // console.log(res)
            })
            .catch(err => {})
    }

    render() {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );
        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        // Show the About button only if we have a handler for it (like in the desktop app)
        const aboutButton = this.buildAboutMenu(this.props.onClickAbout);
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        <div className={classNames(styles.menuBarItem, styles.skMenuLogo)}>
                            <img
                                id="logo_img"
                                alt="Scratch"
                                className={classNames(styles.scratchLogo, {
                                    [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                })}
                                draggable={false}
                                src={this.props.logo}
                                // onClick={this.props.onClickLogo}
                            />
                        </div>
                        
                        {/* 文件 */}
                        {(this.props.canManageFiles) && (
                            <div
                                className={classNames(styles.menuBarItem, styles.hoverable,styles.width64, styles.center, {
                                    [styles.active]: this.props.fileMenuOpen
                                })}
                                onMouseUp={this.props.onClickFile}
                            >
                                {/* nq 文件 */}
                                <img
                                    className={styles.fileIcon}
                                    src={fileIcon}
                                />
                                <img
                                    className={styles.fileCaret}
                                    src={skDownCaret}
                                />
                                {/* <FormattedMessage
                                    defaultMessage="File"
                                    description="Text for file dropdown menu"
                                    id="gui.menuBar.file"
                                /> */}
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.fileMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseFile}
                                >
                                    {/* 新作品 */}
                                    <MenuSection>
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={this.handleClickNew}
                                        >
                                            {newProjectMessage}
                                        </MenuItem>
                                    </MenuSection>
                                    {/* 另存为 */}
                                    {(this.props.isWork || this.props.isFile) && (
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={this.handleEditWork}
                                        >
                                            {this.props.intl.formatMessage(ariaMessages.saveWork)}
                                        </MenuItem>
                                    )}
                                    {/* 资源保存 废弃*/}
                                    {/* {this.props.isFile && (
                                        <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleEditFile}
                                    >
                                        资源文件保存
                                    </MenuItem>
                                    )} */}
                                    {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                        <MenuSection>
                                            {this.props.canSave && (
                                                <MenuItem onClick={this.handleClickSave}>
                                                    {saveNowMessage}
                                                </MenuItem>
                                            )}
                                            {this.props.canCreateCopy && (
                                                <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                    {createCopyMessage}
                                                </MenuItem>
                                            )}
                                            {this.props.canRemix && (
                                                <MenuItem onClick={this.handleClickRemix}>
                                                    {remixMessage}
                                                </MenuItem>
                                            )}
                                        </MenuSection>
                                    )}
                                    <MenuSection>
                                        {/* 从电脑中打开 */}
                                        <MenuItem
                                            onClick={this.props.onStartSelectingFileUpload}
                                        >
                                            {this.props.intl.formatMessage(sharedMessages.loadFromComputerTitle)}
                                        </MenuItem>
                                        {/* 保存到电脑 */}
                                        <SB3Downloader>
                                            {(className, downloadProjectCallback) => (
                                                <MenuItem
                                                    className={className}
                                                    onClick={() => this.getSaveToComputerHandler(downloadProjectCallback)}
                                                >
                                                    <FormattedMessage
                                                        defaultMessage="Save to your computer"
                                                        description="Menu bar item for downloading a project to your computer" // eslint-disable-line max-len
                                                        id="gui.menuBar.downloadToComputer"
                                                    />
                                                </MenuItem>
                                            )}
                                        </SB3Downloader>
                                    </MenuSection>
                                    {/* 编辑/关闭引导文件 */}
                                    <MenuSection>
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={() => {
                                                this.props.onChangeGuide(!this.props.guideVisible)
                                                this.props.onChangeGuideType(!this.props.guideVisible ? 'edit' : '')
                                            }}
                                        >
                                            {this.props.guideVisible ? '关闭引导文件' : '编辑引导文件'}
                                        </MenuItem>
                                    </MenuSection>
                                </MenuBarMenu>
                            </div>
                        )}
                        {/* 背包 */}
                        {/* {(this.props.isSKPack) && (
                            <div
                            className={classNames(styles.menuBarItem, styles.hoverable, styles.width64, styles.center, {
                                [styles.active]: this.props.filePackOpen
                            })}
                        >
                            <img
                                className={styles.packIcon}
                                src={packIcon}
                            />
                        </div>
                        )} */}
                        {/* 帮助 */}
                        {(this.props.isSKHelp) && (
                            <div
                            className={classNames(styles.menuBarItem, styles.hoverable, styles.width64, styles.center, {
                                [styles.active]: this.props.helpMenuOpen
                            })}
                            onMouseUp={this.props.onClickHelp}
                            >
                                <img
                                    className={styles.skHelpIcon}
                                    src={skHelpIcon}
                                />
                                <img
                                    className={styles.fileCaret}
                                    src={skDownCaret}
                                />
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.helpMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseHelp}
                                >
                                    <MenuSection>
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                            onClick={this.props.onOpenTipLibrary}
                                        >
                                            <FormattedMessage {...ariaMessages.tutorials} />
                                        </MenuItem>
                                    </MenuSection>
                                </MenuBarMenu>
                                
                            </div>
                        )}
                        {/* 设置 */}
                        {(this.props.isSKSetting) && (
                            <div
                            className={classNames(styles.menuBarItem, styles.hoverable, styles.width64, styles.center, {
                                [styles.active]: this.props.setupMenuOpen
                            })}
                            onMouseUp={this.props.onClickSetting}
                            >
                                <img
                                    className={styles.skSettingIcon}
                                    src={skSettingIcon}
                                />
                                <img
                                    className={styles.fileCaret}
                                    src={skDownCaret}
                                />
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.setupMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseSetting}
                                >
                                    <MenuSection>
                                        <MenuItem
                                            isRtl={this.props.isRtl}
                                        >
                                            <RightMenu
                                                label={this.props.intl.formatMessage(ariaMessages.language)}
                                                onMounthLanguage={this.onMounthLanguage}
                                            > 
                                            </RightMenu>
                                        </MenuItem>
                                    </MenuSection>
                                    <MenuSection>
                                        <TurboMode>{(toggleTurboMode, { turboMode }) => (
                                            <MenuItem onClick={toggleTurboMode}>
                                                {turboMode ? (
                                                    <FormattedMessage
                                                        defaultMessage="Turn off Turbo Mode"
                                                        description="Menu bar item for turning off turbo mode"
                                                        id="gui.menuBar.turboModeOff"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage="Turn on Turbo Mode"
                                                        description="Menu bar item for turning on turbo mode"
                                                        id="gui.menuBar.turboModeOn"
                                                    />
                                                )}
                                            </MenuItem>
                                        )}</TurboMode>
                                    </MenuSection>
                                </MenuBarMenu>
                                
                            </div>
                        )}
                        {/* 智芽作品保存 */}
                        {this.props.canSaveZhiya ? (
                          <div onClick={this.handleZhiyaWork}
                              className={classNames(styles.menuBarItem, styles.hoverable, styles.width64, styles.center)}>
                            <div style={{
                                width: '102px',
                                color: 'hsla(0, 0%, 40%, 1)',
                                fontWeight: '500',
                                fontSize: '16px',
                                textAlign: 'center'
                            }}>
                              <FormattedMessage
                                  defaultMessage="Save"
                                  description="Save Zhiya's works"
                                  id="gui.menuBar.save"
                                  />
                            </div>
                          </div>
                        ):null}
                    </div>
                    
                    {/* 分享按钮 */}
                    <div className={classNames(styles.menuBarItem)}>
                        {this.props.canShare ? (
                            (this.props.isShowingProject || this.props.isUpdating) && (
                                <ProjectWatcher onDoneUpdating={this.props.onSeeCommunity}>
                                    {
                                        waitForUpdate => (
                                            <ShareButton
                                                className={styles.menuBarButton}
                                                isShared={this.props.isShared}

                                                onClick={() => {
                                                    this.handleClickShare(waitForUpdate);
                                                }}

                                            />
                                        )
                                    }
                                </ProjectWatcher>
                            )
                        ) : (
                            this.props.showComingSoon ? (
                                <MenuBarItemTooltip id="share-button">
                                    <ShareButton className={styles.menuBarButton} />
                                </MenuBarItemTooltip>
                            ) : []
                        )}
                        {this.props.canRemix ? remixButton : []}
                    </div>
                    <div className={classNames(styles.menuBarItem, styles.communityButtonWrapper)}>
                        {this.props.enableCommunity ? (
                            (this.props.isShowingProject || this.props.isUpdating) && (
                                <ProjectWatcher onDoneUpdating={this.props.onSeeCommunity}>
                                    {
                                        waitForUpdate => (
                                            <CommunityButton
                                                className={styles.menuBarButton}

                                                onClick={() => {
                                                    this.handleClickSeeCommunity(waitForUpdate);
                                                }}

                                            />
                                        )
                                    }
                                </ProjectWatcher>
                            )
                        ) : (this.props.showComingSoon ? (
                            <MenuBarItemTooltip id="community-button">
                                <CommunityButton className={styles.menuBarButton} />
                            </MenuBarItemTooltip>
                        ) : [])}
                    </div>
                </div>
                {['teacher', 'student'].includes(this.props.manage_user_message.roles[0]) ?
                    (<div className={styles.workMenu}>
                        {/* <div className={styles.workContent}>
                            <span className={styles.dirName}>0{this.state.task_index}{this.props.manage_user_message.dictName}</span>
                            {this.state.work_arr.map((item, index) => (
                                <div 
                                    key={index} 
                                    className={classNames(styles.dirItem, this.state.task_index === index+1 ? styles.dirItemAct : '')}
                                    onClick={() => this.handleDirAct(index+1)}
                                >
                                    { item.index }
                                </div>
                            ))}
                        </div> */}
                        {/* 权限：所有用户 保存作品 创建作品 */}
                        {/* {(this.props.workType === 'work_detail' || this.props.workType === 'work_create') ? (
                            <input 
                                className={styles.workBtn}
                                type="button" 
                                value= {this.props.workId ? '保存作品' : '创建作品'}
                                onClick={this.handleEditWork}
                            >
                            </input>
                        ): null} */}
                        {/* 权限：老师，创建，查看详情 */}
                        {(this.props.manage_user_message.roles[0] === 'teacher' && this.props.workType != 'correct' && this.props.isWork === false && this.props.isFile === false) ?
                            (<input 
                                className={styles.workBtn} 
                                type="button" 
                                value={this.props.task[0].taskId ? this.props.intl.formatMessage(ariaMessages.editJob) : this.props.intl.formatMessage(ariaMessages.createJob)}
                                onClick={this.handleCreateWork}
                            />) : null
                        }
                        {/* 权限：学生，除了已完成及作品操作 */}
                        {(this.props.manage_user_message.roles[0] === 'student' &&
                            this.props.student_homework.status != '4' && this.props.isWork === false && this.props.isFile === false
                        ) ?
                            (<div>
                                <input 
                                    className={styles.workBtn} 
                                    type="button" 
                                    value={this.props.intl.formatMessage(ariaMessages.submitJob)}
                                    onClick={this.handleSubmitJob}
                                />
                                <input 
                                    className={styles.workBtn} 
                                    type="button" 
                                    value={this.props.intl.formatMessage(ariaMessages.resetJob)}
                                    disabled={this.props.student_homework.status === '0' ? true : false}
                                    onClick={this.handleResizeJob}
                                />
                            </div>
                            ) : null
                        }
                    </div>) : null
                }
                {/* 权限：所有用户 保存作品 创建作品 */}
                {/* <div className={styles.workMenu}>
                    {(this.props.isWork === true) ? (
                        <input 
                            className={styles.workBtn}
                            type="button" 
                            value= {this.props.workId ? '保存作品' : '创建作品'}
                            onClick={this.handleEditWork}
                        >
                        </input>
                    ): null}
                </div> */}
                <div className={styles.otherMenu}>
                    <Rerun  onSetTpuState={this.props.onSetTpuState}></Rerun>
                    {/* 帮助 */}
                    {this.props.tpu_state === 7 ? (
                        <HelperMenu intl={this.props.intl} /> 
                    ) : null}
                    {/* <div className={styles.collMore}>
                        <div className={styles.pointer} onClick={this.handleClickMorePeople}>
                            <img src={othersIcon} alt="" title='多人协作'/>
                        </div>
                    </div> */}
                    {/* {this.props.canEditTitle ? (
                        <div className={styles.projectEditTitle}>
                            <div className={classNames(styles.menuEditTitle, styles.growable, styles.background)} >
                                <MenuBarItemTooltip
                                    enable
                                    id="title-field"
                                >
                                    <ProjectTitleInput
                                        className={classNames(styles.titleFieldGrowable)}
                                    />
                                </MenuBarItemTooltip>
                            </div>
                        </div>
                    ) : ((this.props.authorUsername && this.props.authorUsername !== this.props.username) ? (
                        <AuthorInfo
                            className={styles.authorInfo}
                            imageUrl={this.props.authorThumbnailUrl}
                            projectTitle={this.props.projectTitle}
                            userId={this.props.authorId}
                            username={this.props.authorUsername}
                        />
                    ) : null)} */}
                    {/* <div className={styles.menuBarPane}>
                        <div className={classNames(styles.menuBarPaneItem, styles.colorSave)}>
                            <SB3Downloader>
                                {(className, downloadProjectCallback) => (
                                    <div onClick={() => this.getSaveToComputerHandler(downloadProjectCallback)}>
                                        <FormattedMessage
                                        defaultMessage="Save"
                                        description="Menu bar item for downloading a project to your computer" // eslint-disable-line max-len
                                        id="gui.menuBar.save"
                                        />
                                    </div>
                                )}
                            </SB3Downloader>
                        </div>
                    </div> */}
                    {/* <div className={styles.menuBarPane}>
                        <div className={classNames(styles.menuBarPaneItem, styles.colorRelease)}>
                            <div>
                                <FormattedMessage
                                    defaultMessage="Release"
                                    description="Menu bar item for publishing project" // eslint-disable-line max-len
                                    id="gui.menuBar.release"
                                />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className={styles.menuBarPane}>
                        <div className={classNames(styles.menuBarPaneItem, styles.colorShare)}>
                            <div>
                                <FormattedMessage
                                    defaultMessage="Share"
                                    description="Label for project share button"
                                    id="gui.menuBar.share"
                                />
                            </div>
                        </div>
                    </div> */}
                    
                    {/* 登录 */}
                    <div
                        className={classNames(styles.menuBarItem, styles.hoverable, styles.center, {
                            [styles.active]: this.props.loginMenuStatus
                        })}
                        style={{
                            width: '102px',
                            margin: this.props.token ? '0' : '0 12px 0 0'
                        }}
                        onMouseUp={this.loginType}
                    >
                        {this.props.token ? 
                            <img 
                                className={styles.loginIcon}
                                src={this.handleGetUserAvatar()}
                                title={this.props.intl.formatMessage(ariaMessages.headSculpture)}
                            /> :
                            <div className={styles.loginBtn}>{this.props.intl.formatMessage(ariaMessages.signIn)}</div>
                        }
                        {/* 版本 */}
                        {this.props.token ? 
                            <span className={styles.codeVersion}>v{VERSION}</span>: null
                        }
                        <MenuBarMenu
                            className={classNames(styles.menuBarMenu)}
                            open={this.props.loginMenuStatus}
                            place={this.props.isRtl ? 'right' : 'left'}
                            onRequestClose={() => this.props.onEditMenu(false)}
                        >
                            <MenuSection>
                                {/* 用户头像下拉菜单 */}
                                <div className={styles.userInfo}>
                                    <div>
                                         <img
                                            className={styles.userImage}
                                            src={this.handleGetUserAvatar()}
                                            title="头像"/>
                                    </div>
                                   
                                    <div>
                                        <img src={bgIcon} alt="" />
                                    </div>
                                    <div className={styles.userName}>
                                        {/* {this.props.manage_user_message.user.nickName} */}
                                        {this.props.user_code_message?.fullName || ''}
                                    </div>
                                    <div className={styles.userEmail}>
                                        {/* {this.props.manage_user_message.user.email} */}
                                        {this.props.user_code_message?.email || ''}
                                    </div>
                                </div>
                                <MenuItem
                                    isRtl={this.props.isRtl}
                                    onClick={() => this.toCommunity('personal')}
                                >
                                    <div className={styles.outBox}>
                                        <img src={userIcon} alt="" className={styles.outIcon}/>
                                        <div>{this.props.intl.formatMessage(ariaMessages.personal)}</div>
                                    </div>
                                </MenuItem>
                                <MenuItem
                                    isRtl={this.props.isRtl}
                                    onClick={() => this.toCommunity('works')}
                                >
                                    <div className={styles.outBox}>
                                        <img src={workIcon} alt="" className={styles.outIcon}/>
                                        <div>{this.props.intl.formatMessage(ariaMessages.works)}</div>
                                    </div>
                                </MenuItem>
                                <MenuItem
                                    isRtl={this.props.isRtl}
                                    onClick={() => { 
                                        this.props.onSetManageUserMessage(false),
                                        this.props.onLoginOut(true, this.props.cas_token), 
                                        this.props.onChangeWork(true), 
                                        this.props.onSetWorkType('')
                                    }}
                                >
                                    <div className={styles.outBox}>
                                        <img src={outIcon} alt="" className={styles.outIcon}/>
                                        <FormattedMessage {...ariaMessages.login} />
                                    </div>
                                </MenuItem>
                            </MenuSection>
                        </MenuBarMenu>
                    </div>
                    {/* 群组 */}
                    {this.props.token ? (
                        <GroupMenu
                            containerStyle={[styles.menuBarItem, styles.hoverable, styles.center]}
                            groupBtnStyle={[styles.groupBtn]}
                            fileCaretStyle={[styles.fileCaret]}
                            menuBarMenuStyle={[styles.menuBarMenu]}
                            outBoxStyle={[styles.outBox]}
                            isRtl={this.props.isRtl}
                            imgSrc={skDownCaret}
                            intl={this.props.intl}
                        />
                    ) : null}

                    {/* ｛/*在帐户菜单中显示正确的UI

                        已登录，以及会话是否可用*/}

                    {/* <div className={styles.accountInfoGroup}>
                        <div className={styles.menuBarItem}>
                            {this.props.canSave && (
                                <SaveStatus />
                            )}
                        </div>
                        {this.props.sessionExists ? (
                            this.props.username ? (
                                //************用户已登录************
                                <React.Fragment>
                                    <a href="/mystuff/">
                                        <div
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.hoverable,
                                                styles.mystuffButton
                                            )}
                                        >
                                            <img
                                                className={styles.mystuffIcon}
                                                src={mystuffIcon}
                                            />
                                        </div>
                                    </a>
                                    <AccountNav
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.hoverable,
                                            { [styles.active]: this.props.accountMenuOpen }
                                        )}
                                        isOpen={this.props.accountMenuOpen}
                                        isRtl={this.props.isRtl}
                                        menuBarMenuClassName={classNames(styles.menuBarMenu)}
                                        onClick={this.props.onClickAccount}
                                        onClose={this.props.onRequestCloseAccount}
                                        onLogOut={this.props.onLogOut}
                                    />
                                </React.Fragment>
                            ) : (
                                //*********用户未登录，但存在会话
                                //*********以便他们可以选择登录
                                <React.Fragment>
                                    <div
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.hoverable
                                        )}
                                        key="join"
                                        onMouseUp={this.props.onOpenRegistration}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Join Scratch"
                                            description="Link for creating a Scratch account"
                                            id="gui.menuBar.joinScratch"
                                        />
                                    </div>
                                    <div
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.hoverable
                                        )}
                                        key="login"
                                        onMouseUp={this.props.onClickLogin}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Sign in"
                                            description="Link for signing in to your Scratch account"
                                            id="gui.menuBar.signIn"
                                        />
                                        <LoginDropdown
                                            className={classNames(styles.menuBarMenu)}
                                            isOpen={this.props.loginMenuOpen}
                                            isRtl={this.props.isRtl}
                                            renderLogin={this.props.renderLogin}
                                            onClose={this.props.onRequestCloseLogin}
                                        />
                                    </div>
                                </React.Fragment>
                            )
                        ) : (
                            //********没有可用的登录会话，因此不要显示登录内容
                            <React.Fragment>

                            </React.Fragment>
                        )}
                    </div> */}
                    {/* 插件更新 */}
                    {/* <div className={styles.version}>
                        {this.state.servicesDiv} 
                    </div> */}

                    {aboutButton}
                </div>
            </Box>
        );
    }
}

MenuBar.propTypes = {
    aboutMenuOpen: PropTypes.bool,
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    editMenuOpen: PropTypes.bool,
    filePackOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    helpMenuOpen: PropTypes.bool,
    setupMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isSKPack: PropTypes.bool,
    isSKHelp: PropTypes.bool,
    isSKSetting: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    onClickAbout: PropTypes.oneOfType([
        PropTypes.func, // button mode: call this callback when the About button is clicked
        PropTypes.arrayOf( // menu mode: list of items in the About menu
            PropTypes.shape({
                title: PropTypes.string, // text for the menu item
                onClick: PropTypes.func // call this callback when the menu item is clicked
            })
        )
    ]),
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickHelp: PropTypes.func,
    onClickSetting: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestOpenAbout: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseHelp: PropTypes.func,
    onRequestCloseSetting: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showComingSoon: PropTypes.bool,
    userOwnsProject: PropTypes.bool,
    username: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired,
    onOpenLogin: PropTypes.func,
    onLoginOut: PropTypes.func,
    onSetToken: PropTypes.func,
    onSetManageToken: PropTypes.func,
    onSetManageUserMessage: PropTypes.func,
    loginMenuStatus: PropTypes.bool,
    onChangeMessgae: PropTypes.func,
    onSetZhiyaToken: PropTypes.func,
    canSaveZhiya: PropTypes.bool
};

MenuBar.defaultProps = {
    logo: scratchLogo,
    onShare: () => { }
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        aboutMenuOpen: aboutMenuOpen(state),
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        filePackOpen: filePackOpen(state),
        helpMenuOpen: helpMenuOpen(state),
        setupMenuOpen: setupMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        vm: state.scratchGui.vm,
        loginMenuStatus: loginMenuStatus(state),
        workType: state.scratchGui.status.work_type,
        manage_user_message: state.scratchGui.login.manage_user_message,
        user_code_message: state.scratchGui.login.user_code_message,
        taskRemark: state.scratchGui.status.task_remark,
        task: state.scratchGui.status.task,
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        student_homework: state.scratchGui.status.student_homework,
        workId: state.scratchGui.work.workId,
        workPath: state.scratchGui.work.workPath,
        description: state.scratchGui.work.description,
        workCover: state.scratchGui.work.workCover,
        isWork: state.scratchGui.work.isWork,
        isFile: state.scratchGui.work.isFile,
        imageBaseUrl: state.scratchGui.work.imageBaseUrl,
        manage_token: state.scratchGui.login.manage_token,
        fileData: state.scratchGui.work.file,
        rich_text_content: state.scratchGui.status.rich_text_content,
        tpu_state: state.scratchGui.status.tpu_state,
        canSaveZhiya: state.scratchGui.workZhiya.canSave,
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () =>  dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onRequestCloseHelp: () => dispatch(closeHelpMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onClickHelp: () => dispatch(openHelpMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickSetting: () => dispatch(openSetupMenu()),
    onRequestCloseSetting: () => dispatch(closeSetupMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onRequestOpenAbout: () => dispatch(openAboutMenu()),
    onRequestCloseAbout: () => dispatch(closeAboutMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onOpenLogin: () => dispatch(loginOpen()),
    onLoginOut: (bol, cas_token) => {
        if (bol) history.pushState('', '', window.location.origin)
        dispatch(loginOut( (cas_token === 'undefined' || !cas_token) ? '' : cas_token ))
    },
    onEditMenu: (params) => dispatch(editMenu(params)),
    onSetToken: (params) => dispatch(setToken(params)),
    onSetManageToken: (params, callback) => dispatch(setManageToken(params, callback)),
    onSetManageUserMessage: (params) => dispatch(setManageUserMessage(params)),
    onSetWorkType: (params) => dispatch(setWorkType(params)),
    onSetTaskIndex: (params) => dispatch(setTaskIndex(params)),
    onSetTask: (params, index) => dispatch(setTask(params, index)),
    onGetTaskDetail: (params, callback) => dispatch(getTaskDetail(params, callback)),
    onSetTaskDetail: (params) => dispatch(setTaskDetail(params)),
    onSetStudentHomewrok: (params, type) => dispatch(setStudentHomewrok(params, type)),
    onSetCorrectInfo: (params) => dispatch(setCorrectInfo(params)),
    onGetWorkDetail: (params, callback) => dispatch(getWorkDetail(params, callback)),
    onSetWorkId: (params) => dispatch(setWorkId(params)),
    onSetWorkPath: (params) => dispatch(setWorkPath(params)),
    onSetDescription: (params) => dispatch(setDescription(params)),
    onSetWorkCover: (params) => dispatch(setWorkCover(params)),
    onSetType: (params) => dispatch(setType(params)),
    onSetIsWork: (params) => dispatch(setIsWork(params)),
    onGetImageBaseUrl: (params, callback) => dispatch(getImageBaseUrl(params, callback)),
    onSetImageBaseUrl: (params) => dispatch(setImageBaseUrl(params)),
    onSetDialogVisible: (params) => dispatch(setDialogVisible(params)),
    onSetDialogVisibleZhiya: (params) => dispatch(setDialogVisibleZhiya(params)),
    onSetWorkName: (params) => dispatch(setWorkName(params)),
    onGetDictFileDetail: (params, callback) => dispatch(getDictFileDetail(params, callback)),
    onSetFileId: (params) => dispatch(setFileId(params)),
    onSetIsFile: (params) => dispatch(setIsFile(params)),
    onSetFilePath: (params) => dispatch(setFilePath(params)),
    onSetFileData: (params) => dispatch(setFileData(params)),
    onSetTpuState: (params) => dispatch(setTpuState(params)),
    onSetZhiyaToken: (params) => dispatch(setZhiyaToken(params)),
    onSetProgramId: (params) => dispatch(setProgramId(params)),
    onSetUserId: (params) => dispatch(setUserId(params)),
    onGetWorkDetailZhiya: (params, callback) => dispatch(getWorkDetailZhiya(params, callback)),
    onSetWorkCoverZhiya: (params) => dispatch(setWorkCoverZhiya(params)),
    onSetWorkIdZhiya: (params) => dispatch(setWorkIdZhiya(params)),
    onSetWorkPathZhiya: (params) => dispatch(setWorkPathZhiya(params)),
    onSetTitle: (params) => dispatch(setTitle(params)),
    onSetSort: (params) => dispatch(setSort(params)),
    onSetRemark: (params) => dispatch(setRemark(params)),
    onSetCanSaveZhiya: (params) => dispatch(setCanSaveZhiya(params))
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);
