import React from 'react';
import bindAll from 'lodash.bindall';
import styles from './dimension.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage, intlShape} from 'react-intl';

import IMG_CONTAINER from './img/index.js'
import LANG_MESSAGE from './lang.js'
// reducer
import { setDimension } from '../../reducers/status'
import { 
    imageTrainMenuOpen, 
    openImageTrainMenu,
    closeImageTrainmenu,
    dimUploadMenuOpen,
    openDimUploadMenu,
    closeDimUploadMenu
} from '../../reducers/menus'
// api
import { 
    getDataSetType,
    getTagType,
    createDataSet,
    getUploadUrl,
    getDefaultConfig,
    minIO,
    confirmUploadFile
} from '../../api/login.js'
import { 
    createDimProject, 
    uploadFile,
    importFile,
    getTaskList,
    getProject,
    setValidate,
    setSampleTask,
    saveProject,
    getTask,
    submitDimension,
    updateDimension,
    donwloadFile,
    downloadLocalFile,
    getViews,
    createViews,
    removeTask,
    renameCategorize,
    createViewsFilter,
    getFilterCategorizeTaskList,
    deleteImageTask,
    deleteUploadImage,
    batchAnnotation
} from '../../api/dimension'
// ui
import CategorizeBox from './categorize/categorize-box.jsx';
import { Button, message, Select } from 'antd'
const { Option } = Select;
// js
import { 
    colorToHex, 
    hexToRgba,
    generateRandomColor,
    dataUrlToBlob
} from '../../utils/utils.js';
// import Drawer from '../../utils/dimension-canvas.js'
import Drawer from '../../utils/dimension-canvas1.js'

class Dimension extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0, // 步骤
            step_menu: [
                {
                    img: IMG_CONTAINER.algorithmIcon,
                    actImg: IMG_CONTAINER.algorithmActIcon,
                    content: props.intl.formatMessage(LANG_MESSAGE.taskType), 
                },
                // {
                //     img: IMG_CONTAINER.uploadIcon,
                //     actImg: IMG_CONTAINER.uploadActIcon,
                //     content: props.intl.formatMessage(LANG_MESSAGE.pictures), 
                // },
                {
                    img: IMG_CONTAINER.dimensionIcon,
                    actImg: IMG_CONTAINER.dimensionActIcon,
                    content: props.intl.formatMessage(LANG_MESSAGE.dataAnnotations),
                }
            ],
            categorize: [
                // {
                //     img: IMG_CONTAINER.audioIcon,
                //     content: props.intl.formatMessage(LANG_MESSAGE.audioFrequency),
                //     children: []
                // },
                {
                    img: IMG_CONTAINER.imageIcon,
                    content: props.intl.formatMessage(LANG_MESSAGE.image),
                },
                // {
                //     img: IMG_CONTAINER.attitudeIcon,
                //     content: props.intl.formatMessage(LANG_MESSAGE.attitude)
                // },
            ],
            categorize_list: [
                {
                    name: 'categorize',
                    content: props.intl.formatMessage(LANG_MESSAGE.imageClassification),
                    present: '',
                    tag: '</Choices>',
                    label_config: `<View>
                    <Image name="image" value="$image"/>
                    <Choices name="choice" toName="image">
                        <Choice value="class1"/>
                    </Choices>
                  </View>`
                    // <Choice value="Adult content"/>
                    // <Choice value="Weapons" />
                    // <Choice value="Violence" />
                },
                {
                    name: 'detection',
                    content: props.intl.formatMessage(LANG_MESSAGE.imageDetection),
                    present: '',
                    tag: '</RectangleLabels>',
                    label_config: `<View>
                    <Image name="image" value="$image"/>
                    <RectangleLabels name="label" toName="image">
                    </RectangleLabels>
                  </View>`
                    //   <Label value="Airplane" background="green"/>
                    //   <Label value="Car" background="blue"/>
                },
                // {
                //     name: 'segment',
                //     content: props.intl.formatMessage(LANG_MESSAGE.imageSegmentation),
                //     present: '',
                //     tag: '</PolygonLabels>',
                //     label_config: `<View>
                //     <Header value="选择标签并单击图像开始"/>
                //     <Image name="image" value="$image" zoom="true"/>
                //     <PolygonLabels name="label" toName="image" strokeWidth="3" pointSize="small" opacity="0.9">
                //       <Label value="Airplane" background="red"/>
                //       <Label value="Car" background="blue"/>
                //     <Label value="test" background="#FFA39E"/></PolygonLabels>
                //   </View>`
                // }
            ],
            categorize_type: null,
            categorize_act: 0, // 0 1 2
            categorize_list_act: 0, // 0 1 2
            categorize_present_act: '',
            all_upload_list: [],
            upload_list: [
                {
                    img_list: []
                }
            ],
            upload_list_act: 0,
            // 弹框
            mask_visible: false,
            mask_type: '', // 弹框类型
            // 创建项目
            create_title: '', // 新建弹框标题
            create_name: props.intl.formatMessage(LANG_MESSAGE.imageDetection), // 新增项目名称
            create_desc: props.intl.formatMessage(LANG_MESSAGE.training), // 新增项目描述
            // 上传图片
            upload_img_list: [], // 上传图片
            upload_img_act: null,
            // 拍照
            sanp_roles: false, // 摄像头权限
            snap_upload_list: [],
            snap_num: '', // 一秒多少张
            setinterval_id: '', // 快拍
            // 标签
            tag_list: [],
            tag_list_act: -1,
            // 添加标签
            add_tag_list: [],
            add_tag_name: '', // 添加标签名称

            url_img: null,

            dimension_msg: null, // 当前标注信息
            dimension_canvas: null, // 标注
            dimension_icon: [
                // IMG_CONTAINER.deleteIcon1,
                // IMG_CONTAINER.moveIcon,
                // IMG_CONTAINER.dragIcon,
                // IMG_CONTAINER.amplifyIcon,
                // IMG_CONTAINER.proportionIcon,
                // IMG_CONTAINER.reduceIcon
            ],
            dimension_icon_act: null,
            dimension_sub_type: '',

            remove_tag_act: null,

            // 导出
            export_data_set_type: [],
            export_tag_type: [],
            data_set_name: '',
            data_set_desc: '',
            select_list: [],
            select_id: [],
            export_type: '',

            // 本地导出下载列表
            export_down_list: [],
            export_down_act: null,

            // 标注辅助线（删除判断是否能进行删除）
            auxiliary_line: false,

            remove_type: null,
            remove_index: null,
            remove_value: null,
            remove_categorize_name: null, // 删除分类，需要用的大类名称
            delete_content: '',
            delete_tag: false,

            // 过滤
            filter_act: 0, // 0 全部 1 已标注 2 未标注

            is_tips: true,

            // 分类
            cur_categorize: null, // 当前分类

            // 添加分类/重命名
            rename_type: '',
            rename_value: '',
            rename_index: -1,
            // 分类menu
            menu_index: null,
            menu_bol: false,
            // 按钮禁用
            comfirm_disable: false,

            // 当前绘制标签
            cur_tag: [],

            resize_data: {},
            diemsion_size: null,
            snap_size: 0,
            snap_size_height: 0,
            drag_index: -1,

            // 检测分页（检测只有一个）
            detection_page: 1,
            detection_page_size: 20,
            detection_total: 0,
            
            camera_devices: [], // 摄像头设备
            camera_val: ''
        }
        bindAll(this, [
            'handleBack',
            'onSaveDataSet',
            'selectCategorizeList',
            'setMaskType',
            'closeMask',
            'setCreateName',
            'createProject',
            'openLocalFile',
            'handleChange',
            'openUpload',
            'getSanpRoles',
            'takePhoto',
            'handleClear',
            'snapComfirm',
            'setSnapNum',
            'capture',
            'stopTakePhoto',
            'backStep',
            'nextStep',
            'uploadImgConfirm',
            'refreshTaskList',
            'addTag',
            'getProject',
            'setColor',
            'insertStr',
            'setAddTagName',
            'addTagConfirm',
            'setUploadImgIndex',
            'getTaskProject',
            'setCanvasColor',
            'submitProject',
            'randomStr',
            'setUrlImg',
            'setDimnesionFunc',
            'setCreateDesc',
            'setDataSetName',
            'setDataSetDesc',
            'exportDataSet',
            'initMultipleSelect',
            'keydownHandle',
            'removeTagHandle',
            'onExportProject',
            'setDownloadItem',
            'watchEvent',
            'removeSelectRect',
            'removeSelectImg',
            'removeTagImg',
            'filterImgList',
            'newCloseProject',

            'submitCategorizeProject',
            'refreshCategorizeTaskList',
            'getCategorizeProjectList',
            'showCategorizeMenu',
            'showRename',
            'renameComfirm',
            'setRenameValue',
            'removeCategorize',
            'removeData',
            'handleClick',
            'removeUploadImage',
            'changeScroll',
            'getCanvasSize',
            'changeTagContainer',
            'dragUpload',
            'setDragIndex',
            'doubleClickHandle',
            'renameInputHandle',
            'renameBlurHandle',
            'pageChange',
            'refreshFilterCategorizeTaskList',
            'createViewContainer',
            'createFilter',
            'getView',
            'openCamera',
            'changeCamera',
            'clearTakePhoto'
        ])
    }
    componentDidMount() {
        this.getCanvasSize()
        let data = localStorage.getItem('create_msg') ? JSON.parse(localStorage.getItem('create_msg')) : ''
        if (data) {
            let index = this.state.categorize_list.findIndex((item) => data.label_config.indexOf(item.tag) != -1)
            if (index === 0) { // 图像分类
                this.refreshCategorizeTaskList()
            } else if (index === 1) { // 图像检测
                this.filterImgList(0)
                if ((this.state.step_menu.length - 1) === 1) {
                    this.getProject('set_tag')
                }
            }
            
            this.setState({
                step: 1,
                categorize_list_act: index,
                categorize_type: this.state.categorize_list[index].name,
                create_title: this.state.categorize_list[index].content,
                categorize_present_act: this.state.categorize_list[index].present
            })
        }

        // 监听键盘事件
        document.addEventListener('keydown', this.keydownHandle)
        // 自定义事件
        window.myCustomEvent = new CustomEvent('customEvent');

        document.addEventListener('customEvent', this.watchEvent);
        document.addEventListener('mouseup', this.handleClick);
        window.addEventListener('resize', this.getCanvasSize)
    }
    componentWillUnmount() {
        if (document.getElementById('dim-upload')) {
            document.body.removeChild(document.getElementById('dim-upload'))
        }

        document.removeEventListener('keydown', this.keydownHandle)
        document.removeEventListener('customEvent', this.watchEvent)
        document.removeEventListener('mouseup', this.handleClick)
        window.removeEventListener('resize', this.getCanvasSize)
    }
    // 选择创建的模式
    selectCategorizeList(index) {
        let title = this.state.categorize_list[index].content
        let present = this.state.categorize_list[this.state.categorize_list_act].present
        this.setState({
            categorize_list_act: index,
            create_title: title,
            categorize_present_act: present
        })
    }
    /* 创建项目功能流程 */
    // 项目名称
    setCreateName(e) {
        this.setState({
            create_name: e.target.value
        })
    }
    // 项目描述
    setCreateDesc(e) {
        this.setState({
            create_desc: e.target.value
        })
    }
    // 创建项目
    createProject() {
        if (this.state.create_name.length <= 2) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.valiadteNames),
                type: 'fail'
            })
            return false
        }
        if (!this.state.create_name || !this.state.create_desc) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.createProjectTip),
                type: 'fail'
            })
            return false
        }
        this.setState({
            comfirm_disable: true
        })
        let config = this.state.categorize_list[this.state.categorize_list_act].label_config
        
        createDimProject({
            title: this.state.create_name,
        })
            .then((response) => {
                if (response.id) {
                    saveProject(response.id, {
                        title: this.state.create_name,
                        description: this.state.create_desc,
                        label_config: config
                    })
                        .then((response1) => {
                            if (response1) {
                                localStorage.setItem('create_msg', JSON.stringify(response1))
                                let value = this.state.step + 1
                                if (this.state.categorize_list_act === 0) { // 分类
                                    let arr = []

                                    const regex = /value="([^"]*)"/g
                                    if (response1?.label_config) {
                                        const matches = response1.label_config.matchAll(regex)
                                        for (const match of matches) {
                                            let temp = {}
                                            temp['name'] = match[1]
                                            temp['img_list'] = []
                                            temp['is_menu'] = false
                                            temp['is_rename'] = false
                                            temp['old_name'] = match[1]
                                            temp['page'] = 1
                                            temp['page_size'] = 20
                                            temp['total'] = 0
                                            arr.push(temp)
                                        }
                                        if (arr.length > 0) arr.shift(0)
                                        this.setState({
                                            upload_list: arr
                                        })
                                    }

                                    // 分类特殊处理，创建views
                                    this.createViewContainer(response1.id, [], 'categorize')
                                } else if (this.state.categorize_list_act === 1) { // 检测
                                    if (value === this.state.step_menu.length - 1) this.getProject('set_tag')
                                }

                                this.setState({
                                    mask_visible: false,
                                    mask_type: '',
                                    step: value,
                                    categorize_type: this.state.categorize_list[this.state.categorize_list_act].name
                                })
                                this.props.onChangeMessgae({
                                    visible: true,
                                    content: this.props.intl.formatMessage(LANG_MESSAGE.createdSuccessfully),
                                    type: 'success'
                                })
                            }
                        })
                        .catch(() => {})
                }
            })
            .catch(() => {})
            .finally(() => {
                this.setState({
                    comfirm_disable: false 
                })
            })
    }

    /**
     * 检测
     * 刷新任务列表
     */
    refreshTaskList(id, view, callback) {
        getTaskList({
            page: this.state.detection_page,
            page_size: this.state.detection_page_size,
            project: id,
            view,
            interaction: 'filter'
        })
            .then((response) => {
                if (response.total > 0) {
                    let arr = []
                    response.tasks.forEach((item, index) => {
                        let temp_v = {}
                        temp_v['url'] = (localStorage.getItem('demiension_url') || '' ) + item.data.image
                        temp_v['id'] = item.id
                        temp_v['is_labeled'] = item.is_labeled
                        arr.push(temp_v)
                    })
                    let new_data = [{ img_list: arr }]
                    // 根据当前filter_act，过滤筛选全部，已标注，未标注
                    let filter = arr.filter((item) => {
                        if (this.state.filter_act === 0) {
                            return item
                        } else if (this.state.filter_act === 1) {
                            return item.is_labeled === true
                        } else if (this.state.filter_act === 2) {
                            return item.is_labeled === false
                        }
                    })

                    this.setState({
                        all_upload_list: new_data,
                        upload_list: [{
                            img_list: filter
                        }],
                        detection_total: response.total
                    }, () => {
                        if (callback) callback()
                    })
                } else {
                    this.setState({
                        upload_img_act: null,
                        all_upload_list: [],
                        upload_list: [],
                        detection_total: 0
                    })
                    if (this.state.dimension_canvas) {
                        this.state.dimension_canvas.resetConfig()
                        this.state.dimension_canvas.clearAll()
                    }
                }
            })
            .catch(() => {})
    }
    /* 
     * 过滤标注
     * type all complete incomplete
     */
    async filterImgList(type) {
        let data = JSON.parse(localStorage.getItem('create_msg'))
        getViews(data.id)
            .then(async (response) => {
                let view = null
                // 获取viewsid
                if (response.length > 0) view = response[0].id
                else view = await this.createViewContainer(data.id, [], 'detection')
                let options = type === 0 ? [] : [{
                    "filter": "filter:tasks:completed_at",
                    "operator": "empty",
                    "value": type === 1 ? false : true,
                    "type": "Datetime"
                }]
                // 创建过滤器
                await this.createFilter(view, data.id, 'detection', options)
                this.setState({
                    detection_page: 1
                }, () => this.refreshTaskList(data.id, view, () => this.setUploadImgIndex(0)))
            })
            .catch()

        this.setState({
            filter_act: type
        })
    }

    /*
     * 显示弹框 
     * type 弹框类型
     * params 其它参数
     */
    setMaskType(type, params) {
        if (type === 'create') { // 创建
            let name_arr = [
                this.props.intl.formatMessage(LANG_MESSAGE.imageClassification),
                this.props.intl.formatMessage(LANG_MESSAGE.imageDetection),
                this.props.intl.formatMessage(LANG_MESSAGE.imageSegmentation)
            ]
            this.setState({
                create_name: name_arr[this.state.categorize_list_act]
            })
        }

        if (type === 'snap') { // 拍照，开启摄像头权限
            this.getSanpRoles()
        }

        if (type === 'tag') { // 标签，获取标签列表
            let data = JSON.parse(localStorage.getItem('create_msg'))
            getProject(data.id)
                .then((response) => {
                    let tag_list = response.parsed_label_config.label.labels_attrs
                    let arr = []

                    for(let i in tag_list) {
                        let temp_v = {}
                        temp_v['value'] = tag_list[i].value
                        temp_v['background'] = tag_list[i].background
                        temp_v['background_code'] = colorToHex(tag_list[i].background)
                        arr.push(temp_v)
                    }
                    this.setState({
                        add_tag_list: arr
                    })
                })
                .catch(() => {})
        }

        this.setState({
            mask_visible: true,
            mask_type: type,
            cur_categorize: params?.type === 'categorize' ? params : null, // 分类
        })
    }
    // 关闭弹框
    closeMask() {
        // 关闭摄像头
        if (this.refs.snapVideo) {
            try {
                let tracks = this.refs.snapVideo.srcObject.getTracks()
                tracks.forEach(track => track.stop())
                this.refs.snapVideo.srcObject = null
            } catch(err) {
                this.refs.snapVideo.srcObject = null
            }
        }
        this.props.vm.runtime.commonCamera.closeCamera()
        if (document.getElementById('snapImg')) document.getElementById('snapImg').src = ''
        
        this.setState({
            mask_visible: false,
            mask_type: '',
            export_type: '',
            detection: null,
            export_down_act: null,
            auxiliary_line: false,
            delete_content: '',
            upload_img_list: [],
            snap_upload_list: [],
            add_tag_name: '',
            add_tag_list: [],
            select_id: [],
            select_list: [],
            export_down_list: [],
            remove_type: null,
            remove_index: null,
            remove_value: null,
            delete_tag: false,
            // 暂时不清除
            // create_name: '',
            // create_desc: '',
            // 摄像头
            snap_num: '',
            rename_value: '',
            comfirm_disable: false,
            camera_devices: [],
            camera_val: ''
        })
    }

    /**
     * 上传图片流程
     * 本地上传 --> 创建file DOM元素 --> 事件监听
     */
    openLocalFile() {
        let input;
        input = document.createElement('input')
        input.id = 'dim-upload'
        input.type = 'file'
        input.accept = '.jpg, .png, .gif, .bmp, .svg, .webp'
        input.multiple = 'multiple'
        input.style = 'display: none;'
        input.onchange = this.handleChange
        document.body.appendChild(input);

        input.click();
    }
    // 本地上传
    handleChange(e) {
        let arr = []
        let that = this
        let formData = new FormData()
        let data = Array.from(e.target.files).filter((item) => item.type.startsWith('image/'))
        if (data.length > 0) {
            message.loading(this.props.intl.formatMessage(LANG_MESSAGE.resourceLoading), 100000) 
            for(let i = 0; i < data.length; i++) {
                let file = data[i]
                if (file.type.startsWith('image/')) {
                    let name = this.randomStr() +  '.' + file.name.split('.')[1]
                    let new_file = new File([file], name, {
                        type: file.type,
                        lastModified: file.lastModified
                    })
                    formData.append(new_file.name, new_file)
    
                    let reader = new FileReader()
                    reader.onload = function(e) {
                        arr.push({
                            name: new_file.name,
                            url: e.target.result
                        })
    
                        if (i === data.length - 1) {
                            let msg = JSON.parse(localStorage.getItem('create_msg'))
                            uploadFile(msg.id, formData)
                                .then((response) => {
                                    response.file_upload_ids.forEach((item, index) => {
                                        arr[index].id = item
                                    })
                                    let new_arr = that.state.upload_img_list.concat(arr)
                                    // 分类，检测
                                    if (that.state.categorize_list_act === 0 || that.state.categorize_list_act === 1) {
                                        that.setState({
                                            upload_img_list: new_arr
                                        })
                                    }
                                })
                                .catch(() => {})
                                .finally(() => {
                                    message.destroy()
                                })
                        }
                    }
                    reader.readAsDataURL(new_file)
                }
            }
        }
    }
    // 本地上传删除
    removeUploadImage(index, type) {
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let arr = this.state[type]
        if (type === 'snap_upload_list') {
            arr.splice(index, 1)
            this.setState({
                type: arr
            })
        } else {
            let item = arr[index]
            deleteUploadImage(data.id, {
                file_upload_ids: [item.id]
            })
                .then((response) => {
                    if (response.deleted === 1) {
                        arr.splice(index, 1)
                        this.setState({
                            type: arr
                        })
                    }
                })
                .catch()
        }
    }

    /*
     * 本地上传图片确认按钮
     *
     * 分类 --> 分类操作
     * 
     * 检测 --> 导入项目
     */
    // 打开确认弹框
    openUpload(index, type, evnet, name) {
        evnet.stopPropagation()
        this.setState({
            mask_visible: true,
            mask_type: 'delete',
            delete_content: this.props.intl.formatMessage(LANG_MESSAGE.isRemoveImg),
            remove_type: 'img',
            remove_index: index,
            remove_value: type,
            remove_categorize_name: name
        })
    }
    async uploadImgConfirm() {
        this.setState({
            comfirm_disable: true
        })
        message.loading(this.props.intl.formatMessage(LANG_MESSAGE.uploadingResources), 100000)
        let arr = this.state.upload_img_list.map((item) => item.id)
        let data = await JSON.parse(localStorage.getItem('create_msg'))
        importFile(data.id, {
            file_upload_ids: arr
        })
            .then(async (response) => {
                if (this.state.categorize_list_act === 0) { // 分类
                    let arr = []
                    response.task_ids.forEach((item) => arr.push(item))
                    // 自动分类
                    await this.submitCategorizeProject(arr, this.closeMask)
                } else if (this.state.categorize_list_act === 1) { // 检测
                    let view = await this.getView()
                    // 刷新列表
                    this.refreshTaskList(data.id, view, () => {
                        this.closeMask()
                        message.destroy()
                    })
                }

                if (document.getElementById('dim-upload')) {
                    document.body.removeChild(document.getElementById('dim-upload'))
                }
            })
            .catch(() => message.destroy())
            .finally(() => {
                this.setState({
                    comfirm_disable: false
                })
            })
    }

    /**
     * 摄像头功能
     * 刷新，获取摄像头权限
     */
    async getSanpRoles(device = '') {
        let result = await this.props.vm.runtime.commonCamera.getCamera()
        if (result.camera.length > 0) {
            this.setState({
                camera_devices: result.camera,
                camera_val: result.camera[0].label
            }, () => {
                this.openCamera(device || result.camera[0].deviceId)
            })
        } else {
            if (result.error) {
                window.globalMsg.tips = {
                    visible: true,
                    content: result.error + '',
                    type: 'fail',
                    data: new Date()
                }
            } else {
                window.globalMsg.tips = {
                    visible: true,
                    content: '无摄像头可以使用',
                    type: 'fail',
                    data: new Date()
                }
            }
        }
    }
    async openCamera(device) {
        if (device === 'Hareware Camera') {
            this.setState({
                sanp_roles: true
            }, () => {
                this.props.vm.runtime.commonCamera.openCamera({
                    type: device,
                    elm: 'snapImg'
                })
            })
        } else {
            let constraints = {
                video: {
                    width: 640,
                    height: 640,
                    transform: 'scaleX(-1)',
                    deviceId: {
                        exact: device
                    }
                }
            }
            let result = await this.props.vm.runtime.commonCamera.openCamera({
                type: device, 
                constraints
            })
            this.setState({
                sanp_roles: result.error ? false : true
            })
            if (result.error) {
                window.globalMsg.tips = {
                    visible: true,
                    content: result.error,
                    type: 'fail',
                    data: new Date()
                }
            } else {
                this.refs.snapVideo.srcObject = result.stream
                this.refs.snapVideo.play()
                // 用于截640*640图片
                this.refs.snapDisVideo.srcObject = result.stream
                this.refs.snapDisVideo.play()
            }  
        }
    }
    changeCamera(e) {
        let index = this.state.camera_devices.findIndex((item) => item.deviceId === e)
        if (this.state.camera_val === this.state.camera_devices[index].label) return

        if (e !== 'Hareware Camera' && document.getElementById('snapImg')) {
            if (document.getElementById('snapImg')) {
                document.getElementById('snapImg').src = ''
            }
        }
        this.setState({
            camera_val: this.state.camera_devices[index].label
        })
        this.openCamera(e)
    }
    // 拍照
    async takePhoto() {
        if (!this.state.sanp_roles || this.state.setinterval_id) return
        let arr = []

        if (+this.state.snap_num) {
            let interval = 1000 / +this.state.snap_num // 每秒几张图片
            let id = setInterval(async () => {
                let url = await this.capture()
                arr = this.state.snap_upload_list.concat({
                    url
                })
                this.setState({
                    snap_upload_list: arr
                })
            }, interval)

            this.setState({
                setinterval_id: id
            })
        } else {
            let url = await this.capture()
            arr = this.state.snap_upload_list.concat({
                url
            })
            this.setState({
                snap_upload_list: arr
            })
        }
    }
    async capture() {
        let dataUrl = null
        let options = {
            width: 640,
            height: 640,
            elm: null,
            type: 'image/webp'
        }

        if (this.state.camera_val === 'Hareware Camera') {
            let result = await this.props.vm.runtime.commonCamera.getFarewareElem('snapImg')
            if (result) {
                options.elm = result
                dataUrl = this.props.vm.runtime.commonCamera.takePhoto(options)
                return dataUrl
            }
            return ''
        } else {
            options.elm = this.refs.snapDisVideo
            dataUrl = this.props.vm.runtime.commonCamera.takePhoto(options)
            return dataUrl
        }
    }
    // 停止拍照
    stopTakePhoto() {
        let id = this.state.setinterval_id
        clearInterval(id)
        this.setState({
            setinterval_id: ''
        })
    }
    // 清空
    handleClear() {
        this.setState({
            snap_upload_list: [],
            upload_img_list: [],
            upload_list: [
                {
                    img_list: []
                }
            ],
            upload_img_act: null,
            categorize_type: '',
            cur_categorize: null,
            cur_tag: [],
            remove_tag_act: -1,
            tag_list_act: -1,
            detection_page: 1,
            detection_total: 0
        })
    }
    // 拍照清空
    clearTakePhoto() {
        this.setState({
            snap_upload_list: [],
        })
    }
    // 拍照确认按钮
    snapComfirm() {
        if (this.state.snap_upload_list.length === 0) return false
        this.stopTakePhoto()
        this.setState({
            comfirm_disable: true
        })

        let formData  = new FormData()
        this.state.snap_upload_list.forEach((item, index) => {
            let blob = dataUrlToBlob(item.url)
            let name = this.randomStr() + '.png'
            let file = new File([blob], name, { type: 'image/png' })
            formData.append(file.name, file)
        })
        let data = JSON.parse(localStorage.getItem('create_msg'))

        message.loading(this.props.intl.formatMessage(LANG_MESSAGE.uploadingResources), 100000)
        uploadFile(data.id, formData)
            .then((response) => {
                let new_arr = this.state.snap_upload_list
                response.file_upload_ids.forEach((item, index) => {
                    new_arr[index].id = item
                })
                let arr = new_arr.map((item) => item.id)

                importFile(data.id, {
                    file_upload_ids: arr
                })
                    .then(async (response) => {
                        if (this.state.categorize_list_act === 0) { // 分类
                            let arr = []
                            response.task_ids.forEach((item) => arr.push(item))
                            // 自动分类
                            await this.submitCategorizeProject(arr, this.closeMask)
                        } else if (this.state.categorize_list_act === 1) { // 检测
                            let view = await this.getView()
                            this.refreshTaskList(data.id, view,  () => {
                                this.closeMask()
                                message.destroy()
                            })
                        }
                    })
                    .catch(() => message.destroy())
            })
            .catch(() => message.destroy())
            .finally(() => {
                this.setState({
                    comfirm_disable: false
                })
            })
    }
    setSnapNum(e) {
        let value;
        if (e.target.value < 0) {
            value = 0
        } else if (e.target.value > 30) {
            value = 30
        } else {
            value = e.target.value
        }
        this.setState({
            snap_num: value
        })
    }

    /** 
     * 上一步
     * 下一步
     */
    backStep() {
        let value = +this.state.step - 1
        if (value === 0) { // 清空缓存
            localStorage.removeItem('create_msg')
            this.setState({
                dimension_canvas: null,
                filter_act: 0
            })
            this.handleClear()
        }

        this.setState({
            step: value
        })
    }
    async nextStep() {
        if (this.state.categorize_list_act === 1) { // 图像检测
            let length = this.state.upload_list[this.state.upload_list_act].img_list.length
            if (length === 0) return false
            this.setState({
                step: 2,
            })
            this.getProject('set_tag')
            
            let task_id = this.state.upload_list[this.state.upload_list_act].img_list[0].id
            let res = await this.getTaskProject(task_id)

            this.setState({
                dimension_msg: res
            })

            let url = (localStorage.getItem('demiension_url') || '' ) + res.data.image
            if (this.state.dimension_canvas === null) {
                this.setState({
                    dimension_canvas: new Drawer('dimCanvas', {
                        text: {
                            'delete': this.props.intl.formatMessage(LANG_MESSAGE.delete)
                        }
                    }),
                    resize_data: {
                        data: res.annotations,
                        color: this.state.tag_list
                    }
                }, () => {
                    setTimeout(() => {
                        this.state.dimension_canvas.setBackgroundImage(url)
                        this.state.dimension_canvas.setData(res.annotations, this.state.tag_list)
                        this.setUrlImg(url)
                    }, 100)
                })
            } 
        }
    }

    /**
     * 标签操作 
     */
    // 设置新增标签名称
    setAddTagName(e) {
        this.setState({
            add_tag_name: e.target.value
        })
    }
    async addTag() {
        if (!this.state.add_tag_name) return false

        let str_list = [...new Set(this.state.add_tag_name.split('\n'))].filter((item) => item != '')
        let res = await this.getProject()

        let new_str = []
        let config = res.label_config
        if (str_list.length > 0) {
            str_list.forEach((item, index) => {
                let bol = this.state.add_tag_list.findIndex(itm => itm.value === item)
                if (bol === -1) {
                    let color = generateRandomColor()
                    let element = `<Label value="${item}" background="${color}"/>`
                    config = this.insertStr(config, config.indexOf('</RectangleLabels>'), element)
                    new_str.push({
                        value: item,
                        background: color,
                        background_code: color
                    })
                }
            })
            if (new_str.length === 0) return false
            let arr = this.state.add_tag_list
            arr = arr.concat(new_str)
            this.setState({
                add_tag_list: arr,
                add_tag_name: ''
            })

            let res1 = setValidate(res.id, {
                label_config: config
            })
            let res2 = setSampleTask(res.id, {
                label_config: config
            })
            Promise.all([res1, res2])
                .then((response) => {})
                .catch(() => {})
        }
    }
    // 字符串插入
    insertStr(str, index, insertStr) {
        const ary = str.split(''); // 转化为数组
        ary.splice(index, 0, insertStr); // 使用数组方法插入字符串
        return ary.join(''); // 拼接成字符串后输出
    }
    // 获取当前详情
    async getProject(type, callback) {
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let response = await getProject(data.id)
        if (type && type === 'set_tag') {
            let tag_list = response.parsed_label_config.label.labels_attrs
            let arr = []
            for(let i in tag_list) {
                let temp_v = {}
                temp_v['value'] = tag_list[i].value
                temp_v['background'] = tag_list[i].background
                temp_v['background_code'] = colorToHex(tag_list[i].background)
                temp_v['background_opacity'] = hexToRgba(temp_v['background_code'], '.2')
                arr.push(temp_v)
            }

            this.setState({
                tag_list: arr
            }, () => {
                if (callback) callback()
            })
        }
        return response
    }
    // 颜色选择
    setColor(e, index) {
        let arr = this.state.add_tag_list
        arr[index].background_code = e.target.value
        this.setState({
            add_tag_list: arr
        })
    }
    // 删除标签
    removeTag(index) {
        let arr = this.state.add_tag_list
        arr.splice(index, 1)
        this.setState({
            add_tag_list: arr
        })
    }
    // 确认添加标签
    async addTagConfirm() {
        this.setState({
            comfirm_disable: true
        })
        let res = await this.getProject()

        let config = `
        <View>
            <Image name="image" value="$image"/>
            <RectangleLabels name="label" toName="image">
            ${this.state.add_tag_list.map((item) => {
                return `<Label value="${item.value}" background="${item.background_code}" />`
            })}
            </RectangleLabels>
        </View>
        `
        saveProject(res.id, {
            label_config: config.replaceAll(',', '')
        })
            .then((response) => {
                this.closeMask()
                this.getProject('set_tag', () => {
                    if (this.state.dimension_canvas != null && this.state.dimension_canvas.label_name != '') {
                        let index = this.state.tag_list.findIndex((item) => item.value === this.state.dimension_canvas.label_name)
                        this.setState({
                            tag_list_act: index,
                            remove_tag_act: index
                        })
                    }
                })
            })
            .catch((error) => {})
            .finally(() => {
                this.setState({
                    comfirm_disable: false
                })
            })
    }
    removeTagHandle() {
        this.setState({
            mask_visible: true,
            mask_type: 'delete',
            delete_content: this.state.remove_tag_act === null ? this.props.intl.formatMessage(LANG_MESSAGE.selectFirst) : this.props.intl.formatMessage(LANG_MESSAGE.deleteCurTag),
            remove_type: 'tag',
            delete_tag: this.state.remove_tag_act === null ? false : true
        })
    }

    async getTaskProject(taskid) {
        let response = await getTask(taskid)
        return response
    }

    /* 分类功能 */
    // 自动分类
    async submitCategorizeProject(params, callback = null) {
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let tag = this.state.cur_categorize.name

        if (Array.isArray(params) && params.length > 0) {
            await batchAnnotation({
                draft_id: 0,
                lead_time: 8.003, // 暂时写死，准备时间，绘制到提交时间
                result: [{
                    from_name: 'choice',
                    id: this.randomStr(),
                    origin: 'manual',
                    to_name: 'image',
                    type: 'choices',
                    value: {
                        choices: [tag]
                    }
                }],
                parent_annotation: null,
                parent_prediction: null,
                project: data.id,
                task_ids: params
            })
                .then((response) => {})
                .catch()
            if (callback) callback()

            this.refreshCategorizeTaskList()
        }
        message.destroy()
    }
    /**
     * 刷新分类数据
     * 获取分类详情，再获取views（查看是否存在id，没有创建views）
     * 创建过滤器，再获取对应数据列表
     */
    async refreshCategorizeTaskList() {
        let arr = []
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let res = await this.getCategorizeProjectList(data.id)

        const regex = /value="([^"]*)"/g
        if (res?.label_config) {
            const matches = res.label_config.matchAll(regex)
            for (const match of matches) {
                let temp = {}
                temp['name'] = match[1]
                temp['img_list'] = []
                temp['is_menu'] = false
                temp['is_rename'] = false
                temp['old_name'] = match[1]
                temp['page'] = 1
                temp['page_size'] = 20
                temp['total'] = 0
                arr.push(temp)
            }
            if (arr.length > 0) arr.shift(0)
        }

        getViews(data.id)
            .then(async (response) => {
                let view = null
                // 获取views
                if (response.length > 0) view = response[0].id 
                else view = await this.createViewContainer(data.id, [], 'categorize')
                // 为每个分类创建过滤器，进行过滤查询
                for (let i = 0; i < arr.length; i++) {
                    await this.createFilter(view, data.id, 'categorize', [{
                        filter: "filter:tasks:annotations_results",
                        operator: "contains",
                        type: "String",
                        value: `"${arr[i].name}"`
                    }])

                    await getFilterCategorizeTaskList(
                        arr[i].page,
                        arr[i].page_size,
                        view,
                        data.id
                    )
                        .then((response) => {
                            arr[i].total = response.total
                            if (response.tasks.length > 0) {
                                response.tasks.forEach((item) => {
                                    arr[i].img_list.push({
                                        url: (localStorage.getItem('demiension_url') || '' ) + item.data.image,
                                        id:  item.id,
                                        is_labeled:  item.is_labeled
                                    })
                                })
                            }
                        })
                        .catch()
                }

                this.setState({
                    upload_list: arr
                })
            })
            .catch(() => {})
    }
    // 创建views
    async createViewContainer(id, items, type)  {
        return await createViews(0, id, {
            data: {
                columnsDisplayType: {},
                columnsWidth: {},
                filters: {
                    conjunction: 'and',
                    items
                },
                gridWidth: 4,
                hiddenColumns: {
                    explore: type === 'detection' ? [
                        "tasks:inner_id",
                        "tasks:annotations_results",
                        "tasks:annotations_ids",
                        "tasks:predictions_score",
                        "tasks:predictions_model_versions",
                        "tasks:predictions_results",
                        "tasks:file_upload",
                        "tasks:storage_filename",
                        "tasks:created_at",
                        "tasks:updated_at",
                        "tasks:updated_by",
                        "tasks:avg_lead_time"
                    ] : [],
                    labeling: [
                        "tasks:id" ,
                        "tasks:inner_id" ,
                        "tasks:completed_at" ,
                        "tasks:cancelled_annotations" ,
                        "tasks:total_predictions" ,
                        "tasks:annotators" ,
                        "tasks:annotations_results" ,
                        "tasks:annotations_ids" ,
                        "tasks:predictions_score" ,
                        "tasks:predictions_model_versions" ,
                        "tasks:predictions_results" ,
                        "tasks:file_upload" ,
                        "tasks:storage_filename" ,
                        "tasks:created_at" ,
                        "tasks:updated_at" ,
                        "tasks:updated_by" ,
                        "tasks:avg_lead_time"
                    ]
                },
                ordering: [],
                search_text: null,
                target: 'tasks',
                title: 'Default',
                type: 'list'
            },
            project: id
        })
            .then((response) => {
                return response.id
            })
            .catch((error) => {
                return error
            })
    }
    // 创建views过滤器
    async createFilter(view, id, type, items) {
        await createViewsFilter(view, id, {
            data: {
                columnsDisplayType: {},
                columnsWidth: {},
                filters: {
                    conjunction: "and",
                    items
                },
                gridWidth: 4,
                hiddenColumns: {
                    explore: type === 'detection' ? [
                        "tasks:inner_id",
                        "tasks:annotations_results",
                        "tasks:annotations_ids",
                        "tasks:predictions_score",
                        "tasks:predictions_model_versions",
                        "tasks:predictions_results",
                        "tasks:file_upload",
                        "tasks:storage_filename",
                        "tasks:created_at",
                        "tasks:updated_at",
                        "tasks:updated_by",
                        "tasks:avg_lead_time"
                    ] : [],
                    labeling: [
                        "tasks:id",
                        "tasks:inner_id",
                        "tasks:completed_at",
                        "tasks:cancelled_annotations",
                        "tasks:total_predictions",
                        "tasks:annotators",
                        "tasks:annotations_results",
                        "tasks:annotations_ids",
                        "tasks:predictions_score",
                        "tasks:predictions_model_versions",
                        "tasks:predictions_results",
                        "tasks:file_upload",
                        "tasks:storage_filename",
                        "tasks:created_at",
                        "tasks:updated_at", 
                        "tasks:updated_by",
                        "tasks:avg_lead_time"
                    ]
                },
                ordering: [],
                search_text: null,
                target: "tasks",
                title: "Default",
                type: "list"
            },
            id: view,
            project: id
        })
            .then()
            .catch()
    }
    async getView(type) {
        let view = null
        let data = JSON.parse(localStorage.getItem('create_msg'))
        await getViews(data.id)
            .then(async (response) => {
                if (response.length > 0) view = response[0].id 
                else view = await this.createViewContainer(data.id, [], type)
            })
            .catch(error => {view = false})
        return view
    }

    // 获取分类任务列表
    async getCategorizeProjectList(id) {
        let res = await getProject(id)
        localStorage.setItem('create_msg', JSON.stringify(res))
        return res
    }
    // 图像分类menu
    showCategorizeMenu(index) {
        let arr = this.state.upload_list
        arr.forEach((item, idx) => {
            if (idx === index) {
                arr[idx].is_menu = !arr[idx].is_menu
            } else {
                arr[idx].is_menu = false
            }
        })
        this.setState({
            upload_list: arr,
            menu_index: index,
            menu_bol: true
        })
    }
    // 添加图像分类/重命名图像分类
    showRename(type, index) {
        this.setState({
            mask_visible: true,
            mask_type: 'rename',
            rename_type: type,
            rename_index: index
        })
    }
    // 设置分类名称
    setRenameValue(e) {
        this.setState({
            rename_value: e.target.value
        })
    }
    // 添加图像分类/重命名确认按钮
    async renameComfirm() {
        if (!this.state.rename_value) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.inputClassificationName),
                type: 'fail'
            })
            return false
        }

        this.setState({
            comfirm_disable: true
        })
        if (this.state.rename_type === 'add') {
            let data = JSON.parse(localStorage.getItem('create_msg'))
            let res = await this.getCategorizeProjectList(data.id)

            let labels = []
            const regex = /value="([^"]*)"/g
            if (res?.label_config) {
                const matches = res.label_config.matchAll(regex)
                for (const match of matches) {
                    labels.push(match[1])
                }
                if (labels.length > 0) labels.shift(0)
            }

            if (labels.indexOf(this.state.rename_value) != -1) {
                this.props.onChangeMessgae({
                    visible: true,
                    content: this.props.intl.formatMessage(LANG_MESSAGE.classificationNameExists),
                    type: 'fail'
                })
                this.setState({
                    comfirm_disable: false
                })
                return false
            } else {
                labels.push(this.state.rename_value)
                let element = `<Choice value="${this.state.rename_value}" />`
                let config = this.insertStr(res.label_config, res.label_config.indexOf('</Choices>'), element)
                let res1 = setValidate(res.id, {
                    label_config: config
                })
                let res2 = setSampleTask(res.id, {
                    label_config: config
                })
                Promise.all([res1, res2])
                    .then((response) => {})
                    .catch(() => {})
                    .finally(() => {
                        this.setState({
                            comfirm_disable: false
                        })
                        saveProject(res.id, {
                            label_config: config
                        })
                            .then((response1) => {
                                this.closeMask()
                                this.refreshCategorizeTaskList()
                            })
                            .catch(() => {})
                    })
            }
        } else if (this.state.rename_type === 'edit') {
            message.loading(this.props.intl.formatMessage(LANG_MESSAGE.modifyName), 100000)
            let data = JSON.parse(localStorage.getItem('create_msg'))
            let res = await this.getCategorizeProjectList(data.id)
            let labels = this.state.upload_list

            renameCategorize(res.id, {
                old_label: labels[this.state.rename_index].old_name,
                new_label: this.state.rename_value
            })
                .then((response) => {
                    if (response?.label_config) {
                        this.setState({
                            comfirm_disable: false
                        })

                        this.props.onChangeMessgae({
                            visible: true,
                            content: this.props.intl.formatMessage(LANG_MESSAGE.nameModificationSuccessful),
                            type: 'success'
                        })
                        this.closeMask()
                        this.refreshCategorizeTaskList()
                    } else if (response?.detail) {
                        this.props.onChangeMessgae({
                            visible: true,
                            content: response.detail,
                            type: 'fail'
                        })
                    }
                })
                .catch((error) => {
                    this.setState({
                        comfirm_disable: false
                    })
                    this.props.onChangeMessgae({
                        visible: true,
                        content: error.response.data.detail,
                        type: 'fail'
                    })
                })
                .finally(() => {
                    message.destroy()
                })
        }
    }
    // 删除分类
    async removeCategorize(index) {
        if (this.state.upload_list.length === 1) {
            this.props.onChangeMessgae({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.mustIncludeAClassification),
                type: 'fail'
            })
            return false
        }
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let res = await this.getCategorizeProjectList(data.id)
        let labels = []

        const regex = /value="([^"]*)"/g
        if (res?.label_config) {
            const matches = res.label_config.matchAll(regex)
            for (const match of matches) {
                labels.push(match[1])
            }
            if (labels.length > 0) labels.shift(0)
        }

        if (labels.indexOf(this.state.upload_list[index].name) != -1) {
            await this.removeData(index)

            labels.splice(labels.indexOf(this.state.upload_list[index].name), 1)
            let config = `<View>
                <Image name="image" value="$image"/>
                <Choices name="choice" toName="image">
                    ${labels.map((item) => {
                        return `<Choice value="${item}" />`
                    })}
                </Choices>
            </View>
            `
            saveProject(res.id, {
                label_config: config.replaceAll(',', '')
            })
                .then((response) => {})
                .catch((error) => {})
                .finally(() => {
                    this.closeMask()
                })
        }
    }
    // 删除所有样本数据
    async removeData(index) {
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let item = this.state.upload_list[index]

        removeTask('delete_tasks', data.id, {
            "ordering": [],
            "selectedItems":{
                "all": false,
                "included": []
            },
            "filters": {
                "conjunction": "and",
                "items": [{
                    "filter": "filter:tasks:annotations_results",
                    "operator": "contains",
                    "value": `"${item.name}`,
                    "type": "String"
                }]
            },
            "project": data.id
        })
            .then((response) => {
                this.refreshCategorizeTaskList()
            })
            .catch()
    }
    // 分类点击每一项处理滚轮
    changeScroll(type, index) {
        if (type === 'categorize') {
            let width = 438
            let length = this.state.upload_list.length

            let scroll_container = document.getElementById('uploadContainer')
            let client_width = scroll_container.clientWidth

            let ratio = client_width / length + 50

            if (length * width > client_width) { // 有滚到条
                scroll_container.scrollTo(ratio * index, 0)
            }
            return
        }
    }
    changeTagContainer() {
        let stage = window.getComputedStyle(this.refs.tagContainer).visibility
        // let visible_stage = window.getComputedStyle(this.refs.visibleTagContainer).visibility
        if (stage === 'visible') {
            this.refs.tagContainer.style.visibility = 'hidden'
            this.refs.visibleTagContainer.style.visibility = 'visible'
        } else {
            this.refs.tagContainer.style.visibility = 'visible'
            this.refs.visibleTagContainer.style.visibility = 'hidden'
        }
    }
    // 关闭数据工程
    newCloseProject() {
        localStorage.removeItem('create_msg')
        this.props.onSetDimension(false)
        document.body.removeChild(document.getElementsByClassName('my-set-dialog')[0])
    }
    // 重命名双击
    doubleClickHandle(e, index) {
        let new_arr = this.state.upload_list
        new_arr[index].is_rename = !new_arr[index].is_rename
        this.setState({
            upload_list: new_arr
        })
    }
    // 重命名
    renameInputHandle(e, index) {
        let new_arr = this.state.upload_list
        new_arr[index].name = e
        this.setState({
            upload_list: new_arr
        })
    }
    async renameBlurHandle(index) {
        let new_arr = this.state.upload_list
        new_arr[index].is_rename = false
        if (new_arr[index].name === new_arr[index].old_name) {
            this.setState({
                upload_list: new_arr
            })
            return
        }

        let data = JSON.parse(localStorage.getItem('create_msg'))
        let res = await this.getCategorizeProjectList(data.id)
        message.loading(this.props.intl.formatMessage(LANG_MESSAGE.modifyName), 100000)
        renameCategorize(res.id, {
            old_label: new_arr[index].old_name,
            new_label: new_arr[index].name
        })
            .then((response) => {
                if (response?.label_config) {
                    this.setState({
                        comfirm_disable: false
                    })

                    this.props.onChangeMessgae({
                        visible: true,
                        content: this.props.intl.formatMessage(LANG_MESSAGE.nameModificationSuccessful),
                        type: 'success'
                    })
                    this.closeMask()
                    this.refreshCategorizeTaskList()
                } else if (response?.detail) {
                    this.props.onChangeMessgae({
                        visible: true,
                        content: response.detail,
                        type: 'fail'
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    comfirm_disable: false
                })
                this.props.onChangeMessgae({
                    visible: true,
                    content: error.response.data.detail,
                    type: 'fail'
                })
            })
            .finally(() => {
                message.destroy()
            })
    }

    pageChange(page, type, index) {
        let data = localStorage.getItem('create_msg') ? JSON.parse(localStorage.getItem('create_msg')) : ''
        if (type === 'detection') {
            this.setState({
                detection_page: page <= 0 ? 1 : page
            }, async () => {
                let view = await this.getView()
                this.refreshTaskList(data.id, view, () => this.setUploadImgIndex(0))
            })
        } else if (type === 'categorize') {
            let arr = this.state.upload_list
            arr[index].page = (page <= 0 ? 1 : page)
            this.refreshFilterCategorizeTaskList(index)
        }
    }
    async refreshFilterCategorizeTaskList(index) {
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let arr = this.state.upload_list
        getViews(data.id)
            .then(async (response) => {
                let view = null
                // 获取view
                if (response.length > 0) view = response[0].id 
                else view = await this.createViewContainer(data.id, [], 'categorize')

                let func = async(index) => {
                    await this.createFilter(view, data.id, 'categorize', [{
                        filter: "filter:tasks:annotations_results",
                        operator: "contains",
                        type: "String",
                        value: `"${arr[index].name}"`
                    }])
    
                    await getFilterCategorizeTaskList(
                        arr[index].page,
                        arr[index].page_size,
                        view,
                        data.id
                    )
                        .then((response) => {
                            arr[index].total = response.total
                            if (response.tasks.length > 0) {
                                let img_list = []
                                response.tasks.forEach((item, index) => {
                                    img_list.push({
                                        url: (localStorage.getItem('demiension_url') || '' ) + item.data.image,
                                        id:  item.id,
                                        is_labeled:  item.is_labeled
                                    })
                                })
                                arr[index].img_list = img_list
                            }
                        })
                        .catch()
                }

                if (index === -1) {
                    for (let i = 0; i < arr.length; i++) {
                        await func(i)
                    }
                } else {
                    await func(index)
                }
                this.setState({
                    upload_list: arr
                })
            })
            .catch(() => {})
    }

    /**
     * 打标功能
     */
    // 监听自定义事件（标注完自动保存功能）
    watchEvent() {
        let obj = this.state.dimension_canvas.event
        this.submitProject(obj)
    }
    // 动态设置canvas大小
    getCanvasSize() {
        let size = window.getComputedStyle(document.documentElement).fontSize
        let ratio = (+size.replace('px', ''))/16 * 646
        let snap_height = (+size.replace('px', ''))/16 * 550
        let ratio_width = (+size.replace('px', ''))/16 * 741
        let ratio_height = (+size.replace('px', ''))/16 * 588
        this.setState({
            snap_size: ratio,
            snap_size_height: snap_height,
            diemsion_size: {
                ratio_width,
                ratio_height
            }
        }, () => {
            if (this.state.dimension_canvas !== null) {
                this.state.dimension_canvas.setData(this.state.resize_data.data, this.state.resize_data.color)
            }
        })
    }
    // 图像选中
    setUploadImgIndex(index) {
        if (this.state.step === 1 && this.state.categorize_type != 'detection') return false
        this.setState({
            upload_img_act: index
        }, async () => {
            let task_id = this.state.upload_list[this.state.upload_list_act].img_list[index].id
            let res = await this.getTaskProject(task_id)

            if (res) {
                // 当前图形所绘制多少种颜色
                let color_arr = []
                let new_color = []
                res.annotations.forEach((item, index) => {
                    if (item.result != null && item.result.length > 0) {
                        item.result.forEach((itm, idx) => {
                            if (itm.value.rectanglelabels.length > 0 && color_arr.indexOf(itm.value.rectanglelabels[0]) === -1) {
                                color_arr.push(itm.value.rectanglelabels[0])
                                new_color = new_color.concat(this.state.tag_list.filter((m, i) => m.value === itm.value.rectanglelabels[0]))
                            }
                        })
                    }
                })

                this.setState({
                    dimension_msg: res,
                    cur_tag: new_color
                })
                
                let url = (localStorage.getItem('demiension_url') || '' ) + res.data.image
                if (this.state.dimension_canvas === null) {
                    this.setState({
                        dimension_canvas: new Drawer('dimCanvas', {
                            text: {
                                'delete': this.props.intl.formatMessage(LANG_MESSAGE.delete)
                            }
                        }),
                        resize_data: {
                            data: res.annotations,
                            color: this.state.tag_list
                        }
                    }, () => {
                        setTimeout(() => {
                            this.state.dimension_canvas.setBackgroundImage(url)
                            this.state.dimension_canvas.setData(res.annotations, this.state.tag_list)
                            this.setUrlImg(url)
                        }, 100)
                    })
                } else {
                    // this.setState({
                    //     tag_list_act: -1
                    // })
                    let item = this.state.tag_list[this.state.tag_list_act]
                    this.state.dimension_canvas.resetConfig()
                    if (item) {
                        this.state.dimension_canvas.setColor(item.background_opacity.replace('.2', '.3'))
                        this.state.dimension_canvas.setLabelName(item.value)
                    }
                    this.state.dimension_canvas.setBackgroundImage(url)
                    this.state.dimension_canvas.setData(res.annotations, this.state.tag_list)
                    this.setUrlImg(url)
                    this.setState({
                        resize_data: {
                            data: res.annotations,
                            color: this.state.tag_list
                        }
                    })
                }
            }
        })
    }
    // 设置dimCanvas绘制矩形颜色
    setCanvasColor(item, index) {
        if (!this.state.dimension_canvas) return
        if (this.state.tag_list_act != -1 && this.state.tag_list_act === index) {
            this.state.dimension_canvas.setColor(null)
            this.state.dimension_canvas.setLabelName('')
            this.setState({
                tag_list_act: -1
            })
        } else {
            this.state.dimension_canvas.setColor(item.background_opacity.replace('.2', '.3'))
            this.state.dimension_canvas.setLabelName(item.value)
            this.setState({
                tag_list_act: index
            })
        }
    }
    // 提交标注，参数需要生成10位随机字符串
    randomStr(len = 10) {
        let t = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm1234567890";
        let val = "";
        for (let i = 0; i < len; i++) {
        val += t.charAt(Math.floor(Math.random() * t.length));
        }
        return val;
    }
    // 提交标注，参数需要用到原始图片宽高
    setUrlImg(url) {
        let image = new Image()
        image.src = url
        image.onload = (e) => {
            this.setState({
                url_img: {
                    width: image.width,
                    height: image.height
                }
            })
        }
    }
    // 设置canvas功能
    setDimnesionFunc(index) {
        if (index === 0) {
            if (!this.state.dimension_canvas) return
            this.setState({
                mask_visible: true,
                mask_type: 'delete',
                auxiliary_line: true, 
                delete_content: this.props.intl.formatMessage(LANG_MESSAGE.isClearTag)
            })
        }
        this.setState({
            dimension_icon_act: index
        })
    }
    // 提交项目/更新项目
    submitProject(obj) {
        if (!this.state.dimension_canvas) return

        let data = JSON.parse(localStorage.getItem('create_msg'))
        let result = []
        
        let canvas = document.getElementById('dimCanvas')
        let proportion_width = 100 / canvas.width
        let proportion_height = 100 / canvas.height

        let taskid = this.state.upload_list[this.state.upload_list_act].img_list[this.state.upload_img_act].id

        if (this.state.dimension_msg.is_labeled) { // 更新
            let id
            let dim_arr
            if (obj) {
                if (obj.id === '') {
                    id = [this.state.dimension_msg.annotations_ids.split(',')[0]]
                } else {
                    id = [obj.id]
                }
                dim_arr = this.state.dimension_canvas.getLayers().filter((item) => (+item.create_id === +id[0]) || (item.create_id === ''))
            } else {
                id = this.state.dimension_msg.annotations_ids.split(',')
                dim_arr = this.state.dimension_canvas.getLayers()
            }

            let arr = []
            if (this.state.dimension_canvas) {
                this.state.dimension_msg.annotations.forEach((item, index) => {
                    let temp = {}
                    temp.draft_id = 0
                    temp.lead_time = item.lead_time
                    temp.parent_annotation = item.parent_annotation
                    temp.parent_prediction = item.parent_prediction
                    temp.project = item.project
                    temp.result = []
                    arr.push(temp)
                })
            }

            dim_arr.forEach((item, index) => {
                let temp = {}
                temp.from_name = 'label'
                temp.id = this.randomStr()
                temp.image_rotattion = 0
                temp.item_index = 0
                temp.origin = 'manual'
                temp.original_height = this.state.url_img.height
                temp.original_width = this.state.url_img.width
                temp.to_name = 'image'
                temp.type = 'rectanglelabels'
                temp.value = {
                    x: proportion_width * +item.x,
                    y: proportion_height * +item.y,
                    width: proportion_width * +item.width,
                    height: proportion_height * +item.height,
                    rotation: item.angle || 0 ,
                    rectanglelabels: [item.label_name],
                }

                arr[0].result.push(temp)
            })
            // 处理多个标注容器
            let promises = id.map((item, index) => {
                return updateDimension(item, taskid, data.id, arr[0])
            })
            Promise.all(promises)
                .then(() => {})
                .catch(() => {})
                .finally(async () => {
                    this.getProject('set_tag')
                    if (arr[0].result.length === 0) this.removeSelectRect()
                    else {
                        let view = await this.getView()
                        this.refreshTaskList(data.id, view)
                        this.setUploadImgIndex(this.state.upload_img_act)
                    } 
                })
        } else {
            if (this.state.dimension_canvas) {
                let arr = this.state.dimension_canvas.getLayers()
                arr.forEach((item, index) => {
                    let temp = {}
                    temp.from_name = 'label'
                    temp.image_rotation = 0
                    temp.item_index = 0 
                    temp.origin = 'manual'
                    temp.original_height = this.state.url_img.height
                    temp.original_width = this.state.url_img.width
                    temp.to_name = 'image'
                    temp.type = 'rectanglelabels'
                    temp.id = this.randomStr()
                    temp.value = {
                        x: proportion_width * +item.x,
                        y: proportion_height * +item.y,
                        width: proportion_width * +item.width,
                        height: proportion_height * +item.height,
                        rotation: item.angle || 0,
                    }
                    if (item?.label_name != null)  {
                        temp.value.rectanglelabels = [item.label_name]
                    }
    
                    result.push(temp)
                })
            }

            submitDimension(taskid, data.id, {
                draft_id: 0,
                lead_time: 8.003, // 暂时写死，准备时间，绘制到提交时间
                parent_annotation: null,
                parent_prediction: null,
                project: data.id,
                result: result
            })
                .then(async (response) => {
                    this.getProject('set_tag')
                    let view = await this.getView()

                    if ((this.state.filter_act === 2) && (this.state.upload_list[0].img_list.length === 1)) {
                        let detection_page = (this.state.detection_total - 1) / this.state.detection_page_size
                        this.setState({
                            detection_page: detection_page < 1 ? 1: detection_page
                        })
                    }
                    this.refreshTaskList(data.id, view, () => {
                        if (this.state.filter_act === 2) this.setUploadImgIndex(0)
                        else this.setUploadImgIndex(this.state.upload_img_act)
                    })
                })
                .catch((error) => {})
        }
    }
    // 设置dimCanvas绘制矩形颜色  删除标签记录下标
    setRemoveTag(item, index) {
        if (!this.state.dimension_canvas) return
        if (this.state.tag_list_act != -1 && this.state.tag_list_act === index) {
            this.state.dimension_canvas.setColor(null)
            this.state.dimension_canvas.setLabelName('')
            this.setState({
                tag_list_act: -1,
                remove_tag_act: null
            })
        } else {
            this.state.dimension_canvas.setColor(item.background_opacity.replace('.2', '.3'))
            this.state.dimension_canvas.setLabelName(item.value)
            this.setState({
                tag_list_act: index,
                remove_tag_act: index
            })
        }
    }
    // 确认删除canvas绘制，确认清空canvas
    async removeSelectRect() {
        // this.state.dimension_canvas.removeSelectRect()
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let options = [
            [], 
            [{
                "filter": "filter:tasks:completed_at",
                "operator": "empty",
                "value": false,
                "type": "Datetime"
            }], 
            [{
                "filter": "filter:tasks:completed_at",
                "operator": "empty",
                "value": true,
                "type": "Datetime"
            }]
        ]
        await deleteImageTask('delete_tasks_annotations', data.id, {
            "ordering": [],
            "selectedItems": {
                "all": false,
                "included": [
                    this.state.dimension_msg.id
                ]
            },
            "filters": {
                "conjunction": "and",
                "items": options[this.state.filter_act]
            },
            "project": data.id
        })
        this.state.dimension_canvas.resetConfig()
        this.closeMask()
        // this.submitProject()
        if ((this.state.filter_act === 1) && this.state.upload_list[0].img_list.length === 1) {
            let detection_page = (this.state.detection_total - 1) / this.state.detection_page_size
            this.setState({
                detection_page: detection_page < 1 ? 1: detection_page
            })
        }
        let view = await this.getView()
        this.refreshTaskList(data.id, view, () => this.setUploadImgIndex(0))
    }
    // 弹框提示确认删除图片
    removeSelectImg() {
        let data = JSON.parse(localStorage.getItem('create_msg'))
        let new_arr, item;
        this.setState({
            comfirm_disable: true
        })
        if (this.state.categorize_list_act === 0) { // 分类
            // 区分上传，拍照
            new_arr = this.state.remove_value === 'upload' ? this.state.upload_list : this.state.snap_upload_list
            let value = this.state.remove_categorize_name
            let idx = new_arr.findIndex((item) => item.name === value)
            // item = new_arr[idx].img_list.splice(this.state.remove_index, 1)
            item = new_arr[idx].img_list[this.state.remove_index]

            if (this.state.remove_value === 'upload') {
                deleteImageTask('delete_tasks', data.id, {
                    "ordering": [],
                    "selectedItems": {
                        "all": false,
                        "included": [
                            item.id
                        ]
                    },
                    "filters": {
                        "conjunction": "and",
                        "items": [
                            {
                                "filter": "filter:tasks:annotations_results",
                                "operator": "contains",
                                "value": `"${value}"`,
                                "type": "String"
                            }
                        ]
                    },
                    "project": data.id
                })
                    .then((response) => {
                        if (new_arr[idx].img_list.length === 1) {
                            new_arr[idx].page = 1
                        }
                        this.closeMask()
                        this.refreshFilterCategorizeTaskList(idx)
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.setState({
                            comfirm_disable: false
                        })
                    })
            } else {
                this.setState({
                    snap_upload_list: new_arr,
                    comfirm_disable: false
                })
            }
        }

        if (this.state.categorize_list_act === 1) { // 检测
            new_arr = this.state.remove_value === 'upload' ? this.state.upload_list[this.state.upload_list_act].img_list : this.state.snap_upload_list
            item = new_arr[this.state.remove_index]
            if (this.state.remove_value === 'upload') {
                deleteImageTask('delete_tasks', data.id, {
                    "ordering": [],
                    "selectedItems": {
                        "all": false,
                        "included": [
                            item.id
                        ]
                    },
                    "filters": {
                        "conjunction": "and",
                        "items": []
                    },
                    "project": data.id
                })
                    .then(async (response) => {
                        this.closeMask()
                        if (new_arr.length === 1) {
                            let detection_page = (this.state.detection_total - 1) / this.state.detection_page_size
                            this.setState({
                                detection_page: detection_page < 1 ? 1: detection_page
                            })
                        }
                        let view = await this.getView()
                        this.refreshTaskList(data.id, view, () => this.setUploadImgIndex(0))
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.setState({
                            comfirm_disable: false
                        })
                    })
            } else {
                this.setState({
                    snap_upload_list: new_arr,
                    comfirm_disable: false
                })
            }
        }
    }
    async removeTagImg() {
        let response = await this.getProject()
        let tag_list = response.parsed_label_config.label.labels_attrs
        let arr = []

        for(let i in tag_list) {
            let temp_v = {}
            temp_v['value'] = tag_list[i].value
            temp_v['background'] = tag_list[i].background
            temp_v['background_code'] = colorToHex(tag_list[i].background)
            arr.push(temp_v)
        }

        arr.splice(this.state.remove_tag_act, 1)

        let config = `
        <View>
            <Image name="image" value="$image"/>
            <RectangleLabels name="label" toName="image">
            ${arr.map((item) => {
                return `<Label value="${item.value}" background="${item.background_code}" />`
            })}
            </RectangleLabels>
        </View>
        `

        saveProject(response.id, {
            label_config: config.replaceAll(',', '')
        })
            .then(async (response) => {
                this.getProject('set_tag')
                let view = await this.getView()
                this.refreshTaskList(response.id, view)
            })
            .catch((error) => {})
            .finally(() => {
                this.closeMask()
            })
    }

    // 返回
    handleBack() {
        localStorage.removeItem('create_msg')
        this.props.onSetDimension(false)
    }

    /**
     * 拖拽上传功能
     * 设置拖拽下标
     * 拖拽上传图片
     */
    setDragIndex(index) {
        this.setState({
            drag_index: index
        })
    }
    async dragUpload(e, params, index) {
        if (this.state.drag_index === index) return
        e.preventDefault()
        let files = []
        let arr = []

        try {
            files = e.dataTransfer.files
            if (files.length === 0) throw new Error('error')
        } catch(error) {
            let response = await fetch(e.dataTransfer.getData('text'))
            let blob = await response.blob()
            let file
            if (blob.type === 'image/svg+xml') {
                file = await new File([blob], '1.svg', { type: blob.type });
            } else {
                file = await new File([blob], '1.png', { type: 'image/png' });
            }
            files = [file]
        }

        let data = Array.from(files).filter((item) => item.type.startsWith('image/'))
        if (data.length > 0) {
            let that = this
            let formData = new FormData()
            message.loading(this.props.intl.formatMessage(LANG_MESSAGE.uploadingResources), 100000)
            for(let i = 0; i < data.length; i++) {
                let file = data[i]
                let name = this.randomStr() + '.' + file.name.split('.')[1]
                let new_file = new File([file], name, {
                    type: file.type,
                    lastModified: file.lastModified
                })
                formData.append(new_file.name, new_file)
                let reader = new FileReader()
                reader.onload = function(e) {
                    arr.push({
                        name: new_file.name,
                        url: e.target.result
                    })
                    if (i === data.length - 1) {
                        let msg = JSON.parse(localStorage.getItem('create_msg'))
                        uploadFile(msg.id, formData)
                            .then(async (response) => {
                                response.file_upload_ids.forEach((item, index) => {
                                    arr[index].id = item
                                })
                                let new_arr = that.state.upload_img_list.concat(arr)
                                let arr_id = new_arr.map((item) => item.id)

                                importFile(msg.id, {
                                    file_upload_ids: arr_id
                                })
                                    .then(async (response) => {
                                        if (that.state.categorize_list_act === 0) { // 分类
                                            let list = []
                                            response.task_ids.forEach((item, index) => {
                                                list.push(item)
                                            })
                                            that.setState({
                                                cur_categorize: params || null
                                            }, () => {
                                                // 自动分类
                                                that.submitCategorizeProject(list)
                                            })
                                        } else if (that.state.categorize_list_act === 1) { // 检测
                                            let view = await that.getView()
                                            that.refreshTaskList(msg.id, view)
                                        }
                                    })
                                    .catch()
                                    .finally(() => message.destroy())
                            })
                            .catch(() => {
                                message.destroy()
                            })
                    }
                }
                reader.readAsDataURL(file)
            }
        }
    }

    /* 导出，下载功能 */
    // 导出
    onSaveDataSet() {
        if (this.state.step === 0) return
        getDataSetType(1, 99)
            .then((response) => {
                this.setState({
                    export_data_set_type: response.payload.lables,
                }, () => {
                    document.getElementById('dataSetType').selectedIndex = -1
                    document.getElementById('tagType').selectedIndex = -1
                })
            })
            .catch(() => {})
        getTagType(1, 99)
            .then((response) => {
                this.setState({
                    export_tag_type: response.payload.lables
                }, () => this.initMultipleSelect() )
            })
            .catch(() => {})

        this.setState({
            mask_visible: true,
            mask_type: 'export',
            export_type: 'save',
            data_set_name: '',
            data_set_desc: ''
        })
    }
    // 下载
    async onExportProject() {
        let data = localStorage.getItem('create_msg') ? JSON.parse(localStorage.getItem('create_msg')) : ''

        let response = await downloadLocalFile(data.id)
        if (response && response.length > 0) {
            let arr = response.filter((item) => item?.disabled == null)

            this.setState({
                mask_visible: true,
                mask_type: 'export',
                export_type: 'download',
                export_down_list: arr
            })
        } else {
            this.setState({
                mask_visible: true,
                mask_type: 'export',
                export_type: 'download',
                export_down_list: []
            })
        }
    }
    // 设置数据集名称
    setDataSetName(e) {
        this.setState({
            data_set_name: e.target.value
        })
    }
    // 设置数据集描述
    setDataSetDesc(e) {
        this.setState({
            data_set_desc: e.target.value
        })
    }
    // 下拉多选初始化
    initMultipleSelect() {
        let select = document.getElementById('tagType')
        // let values = this.state.select_list; //存储选择的内容
        let opts = []; //存储option标签
        for(let i = 0; i < select.length; i++) {
            opts.push(select.item(i))
        }
        // 创建一个隐藏起来的option
        let optionHide = document.createElement('option')
        optionHide.setAttribute('hidden', true)
        optionHide.hidden = true
        select.appendChild(optionHide)

        let that = this

        document.getElementById('dataSetType').selectedIndex = -1
        document.getElementById('tagType').selectedIndex = -1
        
        select.addEventListener("input", function() {
            let values = that.state.select_list
            let ids = that.state.select_id
            // 获取当前选择的值
            let value = this.options[this.selectedIndex].value
            let text = this.options[this.selectedIndex].text

            this.options[this.selectedIndex].style = 'background: #FDF6DC'; // 选中的option背景为蓝色
            let index = values.indexOf(text)

            if (index > -1) {
                values.splice(index, 1);

                ids.splice(index, 1)
                that.setState({
                    select_list: values,
                    select_id: ids
                })

                opts.filter(function (opt) {
                    if (opt.text === text) {
                        opt.style = "";
                    }
                });
            } else {
                //没选择就将该值push到values中
                values.push(text);
                ids.push(value)

                that.setState({
                    select_list: values,
                    select_id: ids
                })
            }

            this.options[this.length - 1].text = values.toString();
            if (values.length > 0) {
                //将隐藏的option的selected属性设置为true，这样select.value获取的值就是多选选中的值
                this.options[this.length - 1].selected = true;
            } else {
                this.options[0].selected = true;
                document.getElementById('tagType').selectedIndex = -1
            }
        })
    } 
    // 设置本地下载项
    setDownloadItem(item, index) {
        this.setState({
            export_down_act: index
        })
    }
    // 导出，下载确认
    async exportDataSet() {
        if (this.state.export_type === 'save') {
            if (!this.state.data_set_name) {
                alert(this.props.intl.formatMessage(LANG_MESSAGE.inputDataSetName))
                return
            }
    
            let dataSetType = document.getElementById('dataSetType')
            let set_type = dataSetType.options[dataSetType.selectedIndex].value
            if (!set_type) {
                alert(this.props.intl.formatMessage(LANG_MESSAGE.selectDataSetType))
                return
            }
            this.setState({
                comfirm_disable: true
            })
            let data = localStorage.getItem('create_msg') ? JSON.parse(localStorage.getItem('create_msg')) : ''

            // 下载文件
            let type = this.state.categorize_type === 'categorize' ? 'SK_CLASSIFY' : 'SK_DETECTION'
            donwloadFile(data.id, true, type)
                .then(async (response1) => {
                    // if (response1) {
                    //     let blob = new Blob([response1.data])
                    //     if (response1.data.size != blob.size) {
                    //         console.log(response1.data.size, blob.size)
                    //         this.props.onChangeMessgae({
                    //             visible: true,
                    //             content: '下载失败，网络不稳定',
                    //             type: 'fail'
                    //         })

                    //         this.setState({
                    //             comfirm_disable: false
                    //         })
                    //         return 
                    //     }
                    // }

                    // 创建数据集
                    let response = await createDataSet({
                        name: this.state.data_set_name,
                        desc: this.state.data_set_desc,
                        typeId: set_type,
                        applyIds: this.state.select_id,
                        projectId: data?.id || '',
                        projectCreatedAt: data?.created_at ? new Date(data.created_at).getTime() : ''
                    })
                    if (response.success) {
                        // 域名接口
                        let response2 = await getDefaultConfig({ name: 'tpusp' })

                        if (response1) {
                            let blob = new Blob([response1.data])
                            if (response1.data.size != blob.size) {
                                console.log(response1.data.size, blob.size)
                                this.props.onChangeMessgae({
                                    visible: true,
                                    content: this.props.intl.formatMessage(LANG_MESSAGE.downloadFailed),
                                    type: 'fail'
                                })
                            
                                this.setState({
                                    comfirm_disable: false
                                })
                                return 
                            }

                            let reader = new FileReader()
                            reader.onload = (e) => {
                                // 获取下载地址
                                getUploadUrl(
                                    response.payload.id, 
                                    response.payload.version,
                                    {
                                        domain: response2.payload.settings[0].value,
                                        fileName: response1.fileName
                                    }
                                )
                                    .then((response3) => {
                                        if (response3.success) {
                                            let file = new File([blob], response1.fileName, {type: blob.type, lastModified: Date.now()})
                                            // 导出操作
                                            minIO(response3.payload.uploadUrl, file)
                                                .then((response4) => {
                                                    // 确认是否导出成功
                                                    confirmUploadFile(
                                                        response.payload.id, 
                                                        response.payload.version,
                                                        {
                                                            fileName: response1.fileName
                                                        }
                                                    )
                                                        .then((response5) => {
                                                            if (response5.success) {            
                                                                this.setState({
                                                                    // mask_visible: false,
                                                                    mask_type: 'export-success',
                                                                    comfirm_disable: false
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    comfirm_disable: false
                                                                })
                                                            }
                                                        })
                                                        .catch(() => {
                                                            this.setState({
                                                                comfirm_disable: false
                                                            })

                                                            this.props.onChangeMessgae({
                                                                visible: true,
                                                                content: this.props.intl.formatMessage(LANG_MESSAGE.exportFailed),
                                                                type: 'fail'
                                                            })
                                                        })
                                                })
                                        }
                                        else {
                                            this.setState({
                                                comfirm_disable: false
                                            })
                                        }
                                    })
                                    .catch(() => {
                                        this.setState({
                                            comfirm_disable: false
                                        })
                                    })
                            }
                            reader.readAsArrayBuffer(blob)
                        } else {
                            this.setState({
                                comfirm_disable: false
                            })
                        }
                    } else {
                        this.setState({
                            comfirm_disable: false
                        })
                        alert(this.props.intl.formatMessage(LANG_MESSAGE[`errorCode${response.error.subcode}`]))
                    }
                })
                .catch(() => {
                    this.setState({
                        comfirm_disable: false
                    })
                })

        } else if (this.state.export_type === 'download') {
            if (this.state.export_down_act === null) return
            let data = this.state.export_down_list[this.state.export_down_act]
            let data1 = localStorage.getItem('create_msg') ? JSON.parse(localStorage.getItem('create_msg')) : ''
            this.setState({
                comfirm_disable: true
            })
            
            donwloadFile(data1.id, true, data.name)
                .then((response) => {
                    let blob = new Blob([response.data])
                    console.log(response.data.size, blob.size)
                    if (response.data.size != blob.size) {
                        this.props.onChangeMessgae({
                            visible: true,
                            content: this.props.intl.formatMessage(LANG_MESSAGE.downloadFailed),
                            type: 'fail'
                        })

                        this.setState({
                            comfirm_disable: false
                        })
                        return 
                    }

                    const url = window.URL.createObjectURL(blob);

                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);

                    downloadLink.href = url;
                    downloadLink.download = response.fileName;
                    downloadLink.type = blob.type;
                    downloadLink.click();

                    this.setState({
                        // mask_visible: false,
                        mask_type: 'export-success',
                    })

                    // remove the link after a timeout to prevent a crash on iOS 13 Safari
                    window.setTimeout(() => {
                        document.body.removeChild(downloadLink);
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                })
                .catch((error) => {
                    this.setState({
                        comfirm_disable: false
                    })
                })
                .finally(() => {
                    this.setState({
                        comfirm_disable: false
                    })
                })
        }
    }

    /* 全局事件 */
    // 点击处理下拉开关
    handleClick() {
        if (this.state.menu_index != null && !this.state.menu_bol) {
            let arr = this.state.upload_list
            arr.forEach((item) => {
                item.is_menu = false
            })
            this.setState({
                upload_list: arr,
                menu_index: null,
                menu_bol: false
            })
        } else {
            this.setState({
                menu_bol: false
            })
        }
    }
    // 键盘监听左右键
    keydownHandle(e) {
        if (e.keyCode === 37) {
            this.switchAnnotations('left')
        } else if (e.keyCode === 39) {
            this.switchAnnotations('right')
        }
    }
    // 切换标注图
    switchAnnotations(type) {
        if (
            !this.state.dimension_canvas 
        ) return 

        let length = this.state.upload_list[this.state.upload_list_act].img_list.length
        let value = this.state.upload_img_act

        if (type === 'left') {
            if (value - 1 < 0) value = length - 1
            else value--
        } else if (type === 'right') {
            if (value + 1 > length - 1)  value = 0
            else value++
        }

        this.setUploadImgIndex(value)
    }

    render() {
        return (
            <div className={styles.container}>
                {/* 顶部 */}
                <div className={styles.header}>
                    {/* <img 
                        src={IMG_CONTAINER.backIcon} 
                        className={styles.backImg} 
                        onClick={this.handleBack}
                    /> */}
                    <div className={styles.backBox} onClick={this.handleBack}>
                        <img src={IMG_CONTAINER.newBack} />
                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.newBack)}</span>
                    </div>
                    <div className={styles.menu}>
                        {this.state.step_menu.map((item, index) => {
                            return  (
                                <div className={styles.menuItem} key={index}>
                                    <div className={classNames(styles.item, {
                                        [styles.itemAct]: this.state.step == index
                                    })}>
                                        <img 
                                            src={this.state.step === index ? item.actImg : item.img} 
                                        />
                                        <div >{item.content}</div>
                                    </div>
                                    {this.state.step_menu.length - 1 === index ?
                                        null : (<div className={styles.dashed}></div>)
                                    }
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.newCloseProject} onClick={this.newCloseProject}>
                        <img src={IMG_CONTAINER.newCloseProject} />
                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.newCloseProject)}</span>
                    </div>
                    {/* {this.state.step !== 0 ? (
                        <div className={styles.nav} onMouseUp={this.props.onOpenImageTrainMenu}>
                            <img src={IMG_CONTAINER.navIcon} />
                            <span className={styles.title}>{this.state.categorize_list[this.state.categorize_list_act].content}{this.props.intl.formatMessage(LANG_MESSAGE.train)}</span>
                            <MenuBarMenu
                                className={classNames(styles.navBar)}
                                open={this.props.imageTrainMenuOpen}
                                place={'left'}
                                onRequestClose={this.props.onCloseImageTrainmenu}
                            >
                                <MenuSection>
                                    <MenuItem onClick={this.onSaveDataSet}>
                                        <div style={{'margin': '0 .25rem'}}>
                                            {this.props.intl.formatMessage(LANG_MESSAGE.saveToMyDataset)}
                                        </div>
                                    </MenuItem>
                                    <MenuItem onClick={this.onExportProject}>
                                        <div style={{'margin': '0 .25rem'}}>
                                            {this.props.intl.formatMessage(LANG_MESSAGE.downloadDatasetToLocal)}
                                        </div>
                                    </MenuItem>
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                    ) : (<div></div>)} */}
                </div>
                <div 
                    className={styles.line}
                    style={{
                        width: this.state.step === this.state.step_menu.length - 1 ? '100%' : '90%',
                        margin: this.state.step === this.state.step_menu.length - 1 ? '-0.25rem 0 1.5625rem 2.5rem' : '-.25rem auto 1.5625rem auto',
                    }}
                ></div>
                {/* 内容 */}
                <div 
                    className={styles.content} 
                    style={{'justifyContent': this.state.step === 0 ? '' : 'space-between'}}
                >
                    {/* 步骤一 */}
                    {this.state.step === 0 ? (
                        <div className={styles.main}>
                            <div className={styles.categorizeList}>
                                {this.state.categorize.map((item, index) => {
                                    return (
                                        <div key={index} className={styles.listItem}>
                                            <img src={item.img} />
                                            <span className={styles.listTitle}>{item.content}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={styles.categorizeLine}></div>
                            <div className={styles.categorizeChildList}>
                                {this.state.categorize_list.map((item, index) => {
                                    return (
                                        <div key={index} className={classNames(styles.childListItem, {
                                            [styles.childListAct]: this.state.categorize_list_act == index
                                        })} onClick={() => this.selectCategorizeList(index)}>
                                            {item.content}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={styles.categorizeLine}></div>
                            <div className={styles.categorizePresent}>
                                {this.state.categorize_present_act}
                            </div>
                        </div>
                    ) : null}
                    {/* 步骤二 */}
                    {(this.state.step === 1 && this.state.categorize_type != 'detection') ? (
                        <div id="uploadContainer" className={styles.uploadContainer}>
                            {this.state.upload_list.map((item, index) => {
                                return (
                                    <CategorizeBox 
                                        key={index}
                                        index={index}
                                        name={item.name}
                                        img_list={item.img_list}
                                        is_menu={item.is_menu}
                                        is_rename={item.is_rename}
                                        step={this.state.step}
                                        categorize_list_act={this.state.categorize_list_act}
                                        upload_img_act={this.state.upload_img_act}
                                        categorize_type={this.state.categorize_type}
                                        filter_act={this.state.filter_act}
                                        onOpenDimUploadMenu={this.props.onOpenDimUploadMenu}
                                        dimUploadMenuOpen={this.props.dimUploadMenuOpen}
                                        onCloseDimUploadMenu={this.props.onCloseDimUploadMenu}
                                        IMG_CONTAINER={IMG_CONTAINER}
                                        LANG_MESSAGE={LANG_MESSAGE}
                                        page={item.page}
                                        total={item.total}

                                        openUpload={this.openUpload}
                                        setMaskType={this.setMaskType}
                                        setUploadImgIndex={this.setUploadImgIndex}
                                        filterImgList={this.filterImgList}
                                        showCategorizeMenu={this.showCategorizeMenu}
                                        showRename={this.showRename}
                                        removeCategorize={this.removeCategorize}
                                        removeData={this.removeData}
                                        changeScroll={this.changeScroll}
                                        intl={this.props.intl}
                                        dragUpload={this.dragUpload}
                                        setDragIndex={this.setDragIndex}
                                        doubleClickHandle={this.doubleClickHandle}
                                        renameInputHandle={this.renameInputHandle}
                                        renameBlurHandle={this.renameBlurHandle}
                                        pageChange={this.pageChange}
                                    />
                                )
                            })}
                        </div>
                    ) : null}
                    {/* 除了检测{临时修改} */}
                    {(this.state.step === 1 && this.state.categorize_list_act != 1) ? ( 
                        <div className={styles.categorizeAdd} onClick={() => this.showRename('add')}>
                            <img src={IMG_CONTAINER.addIcon} className={styles.addImg} />
                            <span className={styles.addTitle}>{this.props.intl.formatMessage(LANG_MESSAGE.addClassification)}</span>
                        </div>
                    ) : null}
                    {/* 步骤三 */}
                    {(this.state.step === 1 && this.state.categorize_type === 'detection') || this.state.step === 2 ? (
                        <div className={styles.dimensionContainer}>
                            <CategorizeBox 
                                index={0}
                                step={this.state.step}
                                upload_img_act={this.state.upload_img_act}
                                categorize_type={this.state.categorize_type}
                                filter_act={this.state.filter_act}
                                categorize_list_act={this.state.categorize_list_act}
                                img_list={this.state.upload_list[this.state.upload_list_act]?.img_list.length > 0 ? this.state.upload_list[this.state.upload_list_act].img_list : []}
                                IMG_CONTAINER={IMG_CONTAINER}
                                LANG_MESSAGE={LANG_MESSAGE}
                                page={this.state.detection_page}
                                total={this.state.detection_total}

                                onOpenDimUploadMenu={this.props.onOpenDimUploadMenu}
                                dimUploadMenuOpen={this.props.dimUploadMenuOpen}
                                onCloseDimUploadMenu={this.props.onCloseDimUploadMenu}
                                openUpload={this.openUpload}
                                setMaskType={this.setMaskType}
                                setUploadImgIndex={this.setUploadImgIndex}
                                filterImgList={this.filterImgList}
                                changeScroll={this.changeScroll}
                                intl={this.props.intl}
                                dragUpload={this.dragUpload}
                                setDragIndex={this.setDragIndex}
                                pageChange={this.pageChange}
                            />

                            <div className={styles.dimensionPanel}>
                                <img className={styles.leftIcon} src={IMG_CONTAINER.leftIcon} alt="" onClick={() => this.switchAnnotations('left')} />
                                <div className={styles.dimensionContent}>
                                    <div>
                                        <canvas ref="dimCanvas" id="dimCanvas" width={this.state.diemsion_size.ratio_width} height={this.state.diemsion_size.ratio_height}></canvas>
                                        <div className={styles.dimensionBtnGroup}>
                                            {this.state.cur_tag.map((item, index) => {
                                                return (
                                                    <div 
                                                        key={index} 
                                                        style={{
                                                            'borderLeft': `.25rem solid ${item.background_code}`,
                                                            'background': item.background_opacity,
                                                            'color': item.background_code
                                                            // 'background': this.state.tag_list_act === index ? item.background_code : item.background_opacity,
                                                            // 'color': this.state.tag_list_act === index ? 'white' : item.background_code
                                                        }}
                                                        // onClick={() => this.setCanvasColor(item, index)}
                                                    >
                                                        {item.value}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className={styles.dimensionIcon}>
                                        {this.state.dimension_icon.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                >
                                                    <img 
                                                        style={{
                                                            'background': this.state.dimension_icon_act === index ? '#E5E5E5' : '',
                                                            'borderRadius': this.state.dimension_icon_act === index ? '.5rem' : '',
                                                        }}
                                                        src={item}
                                                        alt="" 
                                                        onClick={() => this.setDimnesionFunc(index)}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <img className={styles.rightIcon} src={IMG_CONTAINER.leftIcon} alt="" onClick={() => this.switchAnnotations('right')} />
                                
                                {this.state.is_tips ? (
                                    <div className={styles.dimesionFunTips}>
                                        {/* <div> */}
                                            <div>{this.props.intl.formatMessage(LANG_MESSAGE.switchPictures)}</div>
                                            <img src={IMG_CONTAINER.closeIcon1} alt="" onClick={() => this.setState({is_tips: false})} />
                                        {/* </div> */}
                                    </div>
                                ) : null}

                                {this.state.dimension_canvas != null ? (
                                    <input className={styles.deleteButton} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.clearAnnotation)} onClick={() => this.setDimnesionFunc(0)} />
                                ) : null}
                            </div>

                            <div ref="tagContainer" className={styles.dimesionTagContainer}>
                                <div className={styles.dimensionTagNav}>
                                    <div className={styles.contractBox} onClick={this.changeTagContainer}>
                                        <img src={IMG_CONTAINER.contractIcon} alt="" />
                                    </div>
                                    {/* <div className={styles.editBox}>
                                        <div className={styles.editBoxLeft}>
                                            <img src={IMG_CONTAINER.circleIcon} alt="" />
                                            <img src={IMG_CONTAINER.refreshIcon} alt="" />
                                        </div>
                                        <div className={styles.editBoxRight}>
                                            <img src={IMG_CONTAINER.tagDeleteIcon} alt="" />
                                            <span>删除</span>
                                        </div>
                                    </div> */}
                                </div>
                                <div className={styles.dimensionAddTag} onClick={() => this.setMaskType('tag')}>
                                    <img src={IMG_CONTAINER.addTagIcon} alt="" />
                                    <span>{this.props.intl.formatMessage(LANG_MESSAGE.newLabel)}</span>
                                </div>
                                <div className={styles.dimensionTitle}>
                                    <span>{this.props.intl.formatMessage(LANG_MESSAGE.allLabels)}({this.state.tag_list.length})</span>
                                    <div>
                                        <img src={IMG_CONTAINER.tagDeleteIcon} alt="" />
                                        <span onClick={this.removeTagHandle}>{this.props.intl.formatMessage(LANG_MESSAGE.delete)}</span>
                                    </div>
                                    {/* <img src={IMG_CONTAINER.searchIcon} alt="" /> */}
                                </div>
                                <div className={styles.dimensionTagBox}>
                                    {this.state.tag_list.map((item, index) => {
                                        return (<div 
                                            style={{
                                                // 'background': item.background_opacity,
                                                // 'border': this.state.remove_tag_act ===index ? '1px solid #CE8137' : ''

                                                'background': this.state.remove_tag_act === index ? item.background_code : item.background_opacity,
                                                'color': this.state.remove_tag_act === index ? 'white' : item.background_code
                                            }} 
                                            className={styles.tagItem} 
                                            key={index}
                                            onClick={() => this.setRemoveTag(item, index)}
                                            // onClick={() => this.setCanvasColor(item, index)}
                                        >
                                            {item.value}
                                        </div>)
                                    })}
                                </div>
                                {/* {this.state.dimension_canvas ? (
                                    <div className={styles.dimensionFooter}>
                                        <input type="button" value={this.state.dimension_msg?.is_labeled ? '更新' : '提交'} onClick={this.submitProject} />
                                    </div>
                                ) : null} */}
                            </div>

                            <div ref="visibleTagContainer" className={styles.visibleTagContainer}>
                                <div className={styles.contractBox} onClick={this.changeTagContainer}>
                                    <img src={IMG_CONTAINER.contractIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {/* 底部按钮 */}
                <div className={styles.footer}>
                    {this.state.step === 0 ? (
                        <input className={classNames(styles.footerBtn, styles.footerBtnAct)} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.create)} onClick={() => this.setMaskType('create')} />
                    ) : null}
                    {this.state.step != 0 ? (
                        <>
                            <input className={classNames(styles.footerBtn, styles.footerBtnBack)} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.back)} onClick={this.backStep} />
                            {(this.state.step === 2 || !(this.state.step === 1 && (this.state.categorize_type === 'detection' || this.state.categorize_type === 'categorize'))) ? (
                                <input className={classNames(styles.footerBtn, styles.footerBtnAct)} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.nextStep)} onClick={this.nextStep} />
                            ) : null}
                            <input 
                                className={classNames(styles.footerBtn, styles.footerBtnExport)} 
                                type="button" 
                                value={this.props.intl.formatMessage(LANG_MESSAGE.export)}
                                onClick={this.onSaveDataSet}
                            />
                            {/* <input className={classNames(styles.footerBtn, styles.closeBtn)} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.close)} onClick={this.handleBack} /> */}
                            <input 
                                className={styles.footerBtnDownload} 
                                type="button" 
                                value={this.props.intl.formatMessage(LANG_MESSAGE.download)}
                                onClick={this.onExportProject}
                            />
                        </>
                    ) : null}
                </div>
                
                {/* 弹框内容 */}
                {this.state.mask_visible ? (
                    <>
                        <div className={styles.mask}></div>
                        {/* 创建项目 */}
                        {this.state.mask_type === 'create' ? (
                            <div className={classNames(styles.maskBox, styles.createBox)}>
                                <div className={styles.renameHeader}>
                                    <img className={styles.closeIcon} src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                </div>
                                <div className={styles.createTitle}>
                                    {this.props.intl.formatMessage(LANG_MESSAGE.newBuilt)}
                                    {this.state.create_title}
                                    {this.props.intl.formatMessage(LANG_MESSAGE.trainingProgram)}
                                </div>
                                <div className={styles.createContent}>
                                    <div>
                                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.name)}</span>
                                        <input 
                                            type="text" 
                                            placeholder={this.props.intl.formatMessage(LANG_MESSAGE.enterProjectName)}
                                            value={this.state.create_name} 
                                            onChange={this.setCreateName}
                                        />
                                    </div>

                                    <div>
                                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.describe)}</span>
                                        <input 
                                            type="text" 
                                            placeholder={this.props.intl.formatMessage(LANG_MESSAGE.enterDescriptionContent)}
                                            value={this.state.create_desc} 
                                            onChange={this.setCreateDesc}
                                        />
                                    </div>
                                </div>
                                <div className={styles.createFooter}>
                                    <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)} onClick={this.closeMask} />
                                    <input disabled={this.state.comfirm_disable} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} onClick={this.createProject} />
                                </div>
                            </div>
                        ) : null}
                        {/* 新建分类/重命名 */}
                        {this.state.mask_type === 'rename' ? (
                            <div className={classNames(styles.maskBox, styles.renameBox)}>
                                <div className={styles.renameHeader}>
                                    <img className={styles.closeIcon} src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                </div>
                                <div className={styles.renameTitle}>{ this.state.rename_type === 'add' ? this.props.intl.formatMessage(LANG_MESSAGE.addClassification) : this.props.intl.formatMessage(LANG_MESSAGE.rename) }</div>
                                <div className={styles.renameInput}>
                                    <span>{this.props.intl.formatMessage(LANG_MESSAGE.nameOfClassification)}</span>
                                    <input 
                                        type="text" 
                                        placeholder={this.props.intl.formatMessage(LANG_MESSAGE.enterTheNameOfTheClassification)}
                                        value={this.state.rename_value} 
                                        onChange={this.setRenameValue}
                                    />
                                </div>
                                <div className={styles.renameFooter}>
                                    <input className={styles.renameCancle} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)} onClick={this.closeMask} />
                                    <input disabled={this.state.comfirm_disable} className={styles.renameComfirm} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} onClick={this.renameComfirm} />
                                </div>
                            </div>
                        ) : null}
                        {/* 上传 */}
                        {this.state.mask_type === 'upload' ? (
                            <div className={classNames(styles.maskBox, styles.uploadImgBox)}>
                                <div className={styles.uploadImgHeader}>
                                    <span>{this.props.intl.formatMessage(LANG_MESSAGE.pictures)}</span>
                                    <img className={styles.uploadClose} src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                </div>
                                <div className={styles.uploadImgContent}>
                                    <div className={styles.uploadImgSource} onClick={this.openLocalFile}>
                                        <img src={IMG_CONTAINER.uploadImgIcon} alt="" />
                                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.pictures)}</span>
                                    </div>
                                    {this.state.upload_img_list.length > 0 ? (
                                        <>
                                            {this.state.upload_img_list.map((item, index) => {
                                                return (
                                                    <div className={styles.uploadImgItem} key={index}>
                                                        <img className={styles.uploadImage} key={index} src={item.url} />
                                                        <img 
                                                            className={styles.uploadImgDelete} 
                                                            src={IMG_CONTAINER.addTagDeleteIcon} 
                                                            alt="" 
                                                            onClick={() => this.removeUploadImage(index, 'upload_img_list')} 
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </>
                                    ) :
                                        // ，也可以直接拖拽网络图片到此处
                                        <span className={styles.uploadImgTip}>{this.props.intl.formatMessage(LANG_MESSAGE.uploadTips)}</span>
                                    }
                                </div>
                                {this.state.upload_img_list.length > 0 ? (
                                    <div className={styles.uploadImgFooter}>
                                        <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)} onClick={this.closeMask} />
                                        <input 
                                            disabled={this.state.comfirm_disable} 
                                            type="button" 
                                            value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} 
                                            onClick={this.uploadImgConfirm} 
                                        />
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {/* 拍照 */}
                        {this.state.mask_type === 'snap' ? (
                            <div className={classNames(styles.maskBox, styles.snapBox)}>
                                <div className={styles.snapHeader}>
                                    <span>{this.props.intl.formatMessage(LANG_MESSAGE.capture)}</span>
                                    <div className={styles.snapHeaderRight}>
                                        <Select 
                                            value={this.state.camera_val}
                                            style={{ width: 120 }} 
                                            onChange={this.changeCamera}
                                        >
                                            {this.state.camera_devices.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.deviceId}>{item.label}</Option>
                                                )
                                            })}
                                        </Select>
                                        <img className={styles.uploadClose} src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                    </div>
                                </div>
                                <div className={styles.snapContent}>
                                    <div className={styles.snapLeft}>
                                        <div className={styles.snapCamera}>
                                            {this.state.sanp_roles ? (
                                                <>
                                                    {this.state.camera_val !== 'Hareware Camera' ? (
                                                        <>
                                                            <video ref="snapVideo" width="100%" height="100%"></video>
                                                            <video className={styles.snapDisVideo} ref="snapDisVideo" width="640" height="640"></video>
                                                        </>
                                                    ) : (
                                                        <img id="snapImg" width="100%" height="100%" alt="" />
                                                    )}
                                                    {/* width={this.state.snap_size} height={this.state.snap_size_height} */}
                                                </>
                                            ) : (
                                                <>
                                                    <img src={IMG_CONTAINER.snapIcon} alt="" />
                                                    <span className={styles.snapCameraTip}>{this.props.intl.formatMessage(LANG_MESSAGE.cameraAbnormality)}</span>
                                                    <span className={styles.snapCameraRefresh} onClick={() => this.getSanpRoles(this.state.camera_val)}>{this.props.intl.formatMessage(LANG_MESSAGE.search)}</span>
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.snapFooter}>
                                            <input className={styles.snapPhoto} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.getPic)} onClick={this.takePhoto} />
                                            <input className={styles.sanpUpload} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.stop)} onClick={this.stopTakePhoto} />
                                            <div className={styles.snapCount}>
                                                {this.props.intl.formatMessage(LANG_MESSAGE.perSecond)}
                                                <input type="number" value={this.state.snap_num} onChange={this.setSnapNum} min="0" max="30" />
                                                {this.props.intl.formatMessage(LANG_MESSAGE.nervous)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.snapRight}>
                                        <div className={styles.snapUploadList}>
                                            {this.state.snap_upload_list.map((item, index) => {
                                                return <div key={index} className={styles.snapListItem}>
                                                    <img src={item.url} alt="" />
                                                    <img 
                                                        className={styles.uploadDelete} 
                                                        src={IMG_CONTAINER.deleteIcon2} 
                                                        alt="" 
                                                        // onClick={(e) => this.openUpload(index, 'snap', e)}
                                                        onClick={() => this.removeUploadImage(index, 'snap_upload_list')} 
                                                    />
                                                </div>
                                            })}
                                        </div>
                                        <div className={styles.snapRightBtn}>
                                            <input className={styles.snapClear} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.empty)} onClick={this.clearTakePhoto} />
                                            <input disabled={this.state.comfirm_disable} className={styles.snapComfirm} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} onClick={this.snapComfirm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {/* 标注 */}
                        {this.state.mask_type === 'tag' ? (
                            <div className={classNames(styles.maskBox, styles.tagBox)}>
                                <div className={styles.tagHeader}>
                                    <span>{this.props.intl.formatMessage(LANG_MESSAGE.newLabel)}</span>
                                    <img src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                </div>

                                <div className={styles.tagContent}>
                                    <div>
                                        <p>{this.props.intl.formatMessage(LANG_MESSAGE.addLabelName)}</p>
                                        <textarea name="" id="" cols="40" rows="10" value={this.state.add_tag_name} onChange={this.setAddTagName}></textarea>
                                        <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.add)} onClick={this.addTag} />
                                    </div>
                                    <div>
                                        <p>{this.props.intl.formatMessage(LANG_MESSAGE.label)} {this.state.add_tag_list.length}</p>
                                        <div className={styles.tagList}>
                                            {this.state.add_tag_list.map((item, index) => {
                                                return (<div key={index} className={styles.addTagItem}>
                                                    <div>
                                                        <input 
                                                            type="color" 
                                                            style={{
                                                                'background': item.background_code
                                                            }}
                                                            value={item.background_code} 
                                                            onChange={(e) => this.setColor(e, index)} 
                                                        />
                                                        <input 
                                                            type="color" 
                                                            style={{
                                                                'background': item.background_code,
                                                                'opacity': '.2',
                                                                'flex': 1
                                                            }}
                                                            value={item.background_code} 
                                                            onChange={(e) => this.setColor(e, index)} 
                                                        />
                                                        {/* <div 
                                                            style={{
                                                                'background': item.background_code
                                                            }}
                                                        ></div> */}
                                                        <span>{ item.value }</span>
                                                    </div>
                                                    <img src={IMG_CONTAINER.addTagDeleteIcon} alt="" onClick={() => this.removeTag(index)} />
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.tagFooter}>
                                    <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)} onClick={this.closeMask} />
                                    <input disabled={this.state.comfirm_disable} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} onClick={this.addTagConfirm} />
                                </div>
                            </div>
                        ) : null}
                        {/* 保存到我的数据集/下载数据集到本地 */}
                        {this.state.mask_type === 'export' ? (
                            <div 
                                className={classNames(styles.maskBox, styles.createBox)}
                                style={{
                                    'width': this.state.export_type === 'download' ? '43.75rem' : '32.25rem'
                                }}
                            >
                                <div className={styles.renameHeader}>
                                    <img className={styles.closeIcon} src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                </div>

                                {this.state.export_type === 'save' ? (
                                    <div className={styles.saveContent}>
                                        <div>
                                            <span className={styles.saveMust}>*</span>
                                            <span>{this.props.intl.formatMessage(LANG_MESSAGE.datasetName)}：</span>
                                            <br />
                                            <input 
                                                type="text" 
                                                className={styles.saveInput} 
                                                value={this.state.data_set_name}
                                                onChange={this.setDataSetName} 
                                            />
                                        </div>

                                        <div>
                                            <span className={styles.saveMust}>*</span>
                                            <span>{this.props.intl.formatMessage(LANG_MESSAGE.datasetType)}：</span>
                                            <br />
                                            <select id="dataSetType" className={styles.saveInput}>
                                                {this.state.export_data_set_type.length > 0 ? (
                                                    this.state.export_data_set_type.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id}>{item.lableDesc}</option>
                                                        )
                                                    })
                                                ) : ( <option value="no_data">{this.props.intl.formatMessage(LANG_MESSAGE.noDataAvailable)}</option> )}
                                            </select>
                                        </div>

                                        <div>
                                            <span>{this.props.intl.formatMessage(LANG_MESSAGE.labelType)}：</span>
                                            <br />
                                            <select id="tagType" className={styles.saveInput}>
                                                {this.state.export_tag_type.length > 0 ? (
                                                    this.state.export_tag_type.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id}>{item.lableDesc}</option>
                                                        )
                                                    })
                                                ) : ( <option value="no_data">{this.props.intl.formatMessage(LANG_MESSAGE.noDataAvailable)}</option> )}
                                            </select>
                                        </div>

                                        <div>
                                            <span>{this.props.intl.formatMessage(LANG_MESSAGE.datasetDescription)}：</span>
                                            <br />
                                            <input 
                                                type="text" 
                                                className={styles.saveInput}
                                                value={this.state.data_set_desc}
                                                onChange={this.setDataSetDesc} 
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                {this.state.export_type === 'download' ? (
                                    <div className={styles.saveContent}>
                                        {this.state.export_down_list.length > 0 ? (
                                            this.state.export_down_list.map((item, index) => {
                                                return (
                                                    <div 
                                                        key={index} 
                                                        className={styles.downloadItem} 
                                                        onClick={() => this.setDownloadItem(item, index)}
                                                        style={{
                                                            'background': this.state.export_down_act === index ? 'rgb(253, 246, 220)' : ''
                                                        }}
                                                    >
                                                        <img src={this.state.export_down_act === index ? IMG_CONTAINER.checkIcon : IMG_CONTAINER.noCheckIcon} alt="" />
                                                        <div className={styles.downloadContent}>
                                                            <div className={styles.downloadTitle}>
                                                                <span>{item.name}</span>
                                                                <div>
                                                                    {item?.tags ? (
                                                                        item.tags.map((itm, idx) => {
                                                                            return (<span key={idx}>{itm}</span>)
                                                                        })
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className={styles.downloadDesc}>{item.description}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : null}
                                    </div>
                                ) : null}
                                <div className={styles.createFooter}>
                                    {/* <input 
                                        disabled={this.state.comfirm_disable} 
                                        type="button" 
                                        value={this.state.export_type === 'download' ? 
                                        this.props.intl.formatMessage(LANG_MESSAGE.download) :
                                        this.props.intl.formatMessage(LANG_MESSAGE.export)} 
                                        onClick={this.exportDataSet} 
                                    /> */}
                                    <Button 
                                        className={classNames(styles.exportBtn)} 
                                        type="primary" 
                                        loading={this.state.comfirm_disable}
                                        onClick={this.exportDataSet}
                                    >
                                        {
                                            this.state.export_type === 'download' ? (
                                                this.state.comfirm_disable ? '正在下载...' : this.props.intl.formatMessage(LANG_MESSAGE.download)
                                            ) :  this.state.comfirm_disable ? '正在导出...' : this.props.intl.formatMessage(LANG_MESSAGE.export)
                                        }
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                        {/* 删除 */}
                        {this.state.mask_type === 'delete' ? (
                            <div 
                                className={classNames(styles.maskBox, styles.createBox)}
                                style={{
                                    'height': '12.5rem'
                                }}
                            >
                                <div className={styles.renameHeader}>
                                    <img className={styles.closeIcon} src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                </div>

                                <div className={styles.renameTpis}>{this.props.intl.formatMessage(LANG_MESSAGE.prompt)}</div>
                                
                                <div className={styles.deleteContent}>
                                    { this.state.delete_content }
                                </div>

                                {this.state.remove_type === 'img' ? (
                                    <div className={styles.tagFooter}>
                                        <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)} onClick={this.closeMask} />
                                        <input disabled={this.state.comfirm_disable} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} onClick={this.removeSelectImg} />
                                    </div>
                                ) : null}

                                {(this.state.remove_type === 'tag' && this.state.delete_tag) ? (
                                    <div className={styles.tagFooter}>
                                        <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)} onClick={this.closeMask} />
                                        <input disabled={this.state.comfirm_disable} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} onClick={this.removeTagImg} />
                                    </div>
                                ) : null}

                                {this.state.auxiliary_line ? (
                                    <div className={styles.tagFooter}>
                                        <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)} onClick={this.closeMask} />
                                        <input disabled={this.state.comfirm_disable} type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.ok)} onClick={this.removeSelectRect} />
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {/* 保存/下载成功 */}
                        {this.state.mask_type === 'export-success' ? (
                            <div className={classNames(styles.maskBox, styles.exportSuccessBox)}>
                                <div className={styles.exportSuccessHeader}>
                                    <img src={IMG_CONTAINER.closeIcon} alt="" onClick={this.closeMask} />
                                </div>

                                <div className={styles.exportSuccessContent}>
                                    <img src={IMG_CONTAINER.exportSuccessIcon} />
                                    <span>{this.state.export_type === 'save' ? this.props.intl.formatMessage(LANG_MESSAGE.exportSuccessful) : this.props.intl.formatMessage(LANG_MESSAGE.downloadSuccessful)}</span>
                                </div>

                                <div className={styles.exportSuccessFooter}>
                                    <input 
                                        className={classNames(styles.comExportSuccessBtn, styles.closeIpt)}
                                        type="button" 
                                        value={this.props.intl.formatMessage(LANG_MESSAGE.continueEditing)}
                                        onClick={this.closeMask} 
                                    />
                                    <input 
                                        className={classNames(styles.comExportSuccessBtn, styles.closeProjectIpt)} 
                                        type="button" 
                                        value={this.props.intl.formatMessage(LANG_MESSAGE.newCloseProject)}
                                        onClick={this.newCloseProject} 
                                    />
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        )
    }
}

Dimension.propTypes = {
    imageTrainMenuOpen: PropTypes.bool,
    dimUploadMenuOpen: PropTypes.bool,
    onOpenImageTrainMenu: PropTypes.func,
    onCloseImageTrainmenu: PropTypes.func,
    onOpenDimUploadMenu: PropTypes.func,
    onCloseDimUploadMenu: PropTypes.func,
    onChangeMessgae: PropTypes.func,
    intl: intlShape.isRequired,
}

const mapStateToProps = state => {
    return {
        imageTrainMenuOpen: imageTrainMenuOpen(state),
        dimUploadMenuOpen: dimUploadMenuOpen(state)
    }
}

const mapDispatchToProps = dispatch => ({
    onSetDimension: (params) => dispatch(setDimension(params)),
    onOpenImageTrainMenu: () => dispatch(openImageTrainMenu()),
    onCloseImageTrainmenu: () => dispatch(closeImageTrainmenu()),
    onOpenDimUploadMenu: () => dispatch(openDimUploadMenu()),
    onCloseDimUploadMenu: () => dispatch(closeDimUploadMenu())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dimension)
