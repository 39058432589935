import { defineMessages } from "react-intl";

const LANG_MESSAGE = defineMessages({
    camera: {
        id: 'gui.takePhoto.camera',
        description: '摄像头',
        defaultMessage: '摄像头'
    },
    retake: {
        id: 'gui.takePhoto.retake',
        description: '重拍',
        defaultMessage: '重拍'
    },
    photograph: {
        id: 'gui.takePhoto.photograph',
        description: '拍照',
        defaultMessage: '拍照'
    },
    ok: {
        id: 'gui.prompt.ok',
        description: '确定',
        defaultMessage: '确定'
    },
    upload: {
        id: 'gui.takePhoto.upload',
        description: '上传',
        defaultMessage: '上传'
    }
})

export default LANG_MESSAGE