import PropTypes from 'prop-types';
import React from 'react';

import VM from 'scratch-vm';

import {defineMessages} from 'react-intl';
import SpriteLibrary from '../../containers/sprite-library.jsx';
import SpriteSelectorComponent from '../sprite-selector/sprite-selector.jsx';
import StageSelector from '../../containers/stage-selector.jsx';
import {STAGE_DISPLAY_SIZES} from '../../lib/layout-constants';

import styles from './target-pane.css';
import openIcon from './open.png';
import CostumeTab from '../../containers/costume-tab.jsx'
import { style } from 'scratch-render';

const messages = defineMessages({
    addRole: {
        id: 'gui.targetPane.addRole',
        description: 'Label for the name of the addRole',
        defaultMessage: 'addRole'
    }
})


/*
 * Pane that contains the sprite selector, sprite info, stage selector,
 * and the new sprite, costume and backdrop buttons
 * @param {object} props Props for the component
 * @returns {React.Component} rendered component
 */
const TargetPane = function(props) {
    const {
        editingTarget,
        fileInputRef,
        hoveredTarget,
        spriteLibraryVisible,
        onActivateBlocksTab,
        onChangeSpriteDirection,
        onChangeSpriteName,
        onChangeSpriteRotationStyle,
        onChangeSpriteSize,
        onChangeSpriteVisibility,
        onChangeSpriteX,
        onChangeSpriteY,
        onChangeSpriteW,
        onChangeSpriteH,
        onChangeSpriteIn,
        onChangeSpriteOut,
        onDeleteSprite,
        onDrop,
        onDuplicateSprite,
        onExportSprite,
        onFileUploadClick,
        onNewSpriteClick,
        onPaintSpriteClick,
        onRequestCloseSpriteLibrary,
        onSelectSprite,
        onSpriteUpload,
        onSurpriseSpriteClick,
        onActivateTab,
        onActivateCostumesTab,
        raiseSprites,
        stage,
        stageSize,
        sprites,
        rightTab,
        targetPanetype,
        onOpenClick,
        selectedIndex,
        vm,
        ...componentProps
    } = props;

    switch(targetPanetype) {
        case 1:
            return <div
                className={styles.targetPane}
                {...componentProps}
            >
                <SpriteSelectorComponent
                    editingTarget={editingTarget}
                    hoveredTarget={hoveredTarget}
                    raised={raiseSprites}
                    selectedId={editingTarget}
                    spriteFileInput={fileInputRef}
                    sprites={sprites}
                    stageSize={stageSize}
                    onChangeSpriteDirection={onChangeSpriteDirection}
                    onChangeSpriteName={onChangeSpriteName}
                    onChangeSpriteRotationStyle={onChangeSpriteRotationStyle}
                    onChangeSpriteSize={onChangeSpriteSize}
                    onChangeSpriteVisibility={onChangeSpriteVisibility}
                    onChangeSpriteX={onChangeSpriteX}
                    onChangeSpriteY={onChangeSpriteY}
                    onChangeSpriteW={onChangeSpriteW}
                    onChangeSpriteH={onChangeSpriteH}
                    onChangeSpriteIn={onChangeSpriteIn}
                    onChangeSpriteOut={onChangeSpriteOut}
                    onDeleteSprite={onDeleteSprite}
                    onDrop={onDrop}
                    onDuplicateSprite={onDuplicateSprite}
                    onExportSprite={onExportSprite}
                    onFileUploadClick={onFileUploadClick}
                    onNewSpriteClick={onNewSpriteClick}
                    onPaintSpriteClick={onPaintSpriteClick}
                    onSelectSprite={onSelectSprite}
                    onSpriteUpload={onSpriteUpload}
                    onSurpriseSpriteClick={onSurpriseSpriteClick}
                    targetPanetype={targetPanetype}
                />
            </div>;
        case 2:
            return <div className={styles.targetBody}
            {...componentProps}
        >
            <div className={styles.targetPaneButton}>
                <button 
                    title={props.intl.formatMessage(messages.addRole)}
                    className={styles.targetPaneBtn}
                    onClick={onOpenClick}
                >
                    <img src={openIcon} alt="" style={{width: '12px'}}/>
                </button>
            </div>
            <div className={styles.targetPaneBody}>
                <SpriteSelectorComponent
                    editingTarget={editingTarget}
                    hoveredTarget={hoveredTarget}
                    raised={raiseSprites}
                    selectedId={editingTarget}
                    spriteFileInput={fileInputRef}
                    sprites={sprites}
                    stageSize={stageSize}
                    onChangeSpriteDirection={onChangeSpriteDirection}
                    onChangeSpriteName={onChangeSpriteName}
                    onChangeSpriteRotationStyle={onChangeSpriteRotationStyle}
                    onChangeSpriteSize={onChangeSpriteSize}
                    onChangeSpriteVisibility={onChangeSpriteVisibility}
                    onChangeSpriteX={onChangeSpriteX}
                    onChangeSpriteY={onChangeSpriteY}
                    onDeleteSprite={onDeleteSprite}
                    onDrop={onDrop}
                    onDuplicateSprite={onDuplicateSprite}
                    onExportSprite={onExportSprite}
                    onFileUploadClick={onFileUploadClick}
                    onNewSpriteClick={onNewSpriteClick}
                    onPaintSpriteClick={onPaintSpriteClick}
                    onSelectSprite={onSelectSprite}
                    onSpriteUpload={onSpriteUpload}
                    onSurpriseSpriteClick={onSurpriseSpriteClick}
                    targetPanetype={targetPanetype}
                />
                <div className={styles.stageSelectorWrapper} style={{height: '84px'}}>
                    {stage.id && <StageSelector
                        asset={
                            stage.costume &&
                            stage.costume.asset
                        }
                        backdropCount={stage.costumeCount}
                        id={stage.id}
                        selected={stage.id === editingTarget}
                        onSelect={onSelectSprite}
                        targetPanetype={targetPanetype}
                    />}
                    <div>
                        {spriteLibraryVisible ? (
                            <SpriteLibrary
                                vm={vm}
                                onActivateBlocksTab={onActivateBlocksTab}
                                onRequestClose={onRequestCloseSpriteLibrary}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            {rightTab ? 
                <CostumeTab 
                    vm={vm} 
                    istype={1} 
                    selectedIndex={selectedIndex} 
                    onActivateTab={onActivateTab} 
                    onActivateCostumesTab={onActivateCostumesTab}
                /> : null 
            }
        </div>;
        case 7:
            return <div
                {...componentProps}
            >
                <SpriteSelectorComponent
                    editingTarget={editingTarget}
                    hoveredTarget={hoveredTarget}
                    raised={raiseSprites}
                    selectedId={editingTarget}
                    spriteFileInput={fileInputRef}
                    sprites={sprites}
                    stageSize={stageSize}
                    onChangeSpriteDirection={onChangeSpriteDirection}
                    onChangeSpriteName={onChangeSpriteName}
                    onChangeSpriteRotationStyle={onChangeSpriteRotationStyle}
                    onChangeSpriteSize={onChangeSpriteSize}
                    onChangeSpriteVisibility={onChangeSpriteVisibility}
                    onChangeSpriteX={onChangeSpriteX}
                    onChangeSpriteY={onChangeSpriteY}
                    onDeleteSprite={onDeleteSprite}
                    onDrop={onDrop}
                    onDuplicateSprite={onDuplicateSprite}
                    onExportSprite={onExportSprite}
                    onFileUploadClick={onFileUploadClick}
                    onNewSpriteClick={onNewSpriteClick}
                    onPaintSpriteClick={onPaintSpriteClick}
                    onSelectSprite={onSelectSprite}
                    onSpriteUpload={onSpriteUpload}
                    onSurpriseSpriteClick={onSurpriseSpriteClick}
                    targetPanetype={targetPanetype}
                />
            </div>;
        default:
            return <div
                className={styles.targetPane}
                {...componentProps}
            >
                <SpriteSelectorComponent
                    editingTarget={editingTarget}
                    hoveredTarget={hoveredTarget}
                    raised={raiseSprites}
                    selectedId={editingTarget}
                    spriteFileInput={fileInputRef}
                    sprites={sprites}
                    stageSize={stageSize}
                    onChangeSpriteDirection={onChangeSpriteDirection}
                    onChangeSpriteName={onChangeSpriteName}
                    onChangeSpriteRotationStyle={onChangeSpriteRotationStyle}
                    onChangeSpriteSize={onChangeSpriteSize}
                    onChangeSpriteVisibility={onChangeSpriteVisibility}
                    onChangeSpriteX={onChangeSpriteX}
                    onChangeSpriteY={onChangeSpriteY}
                    onDeleteSprite={onDeleteSprite}
                    onDrop={onDrop}
                    onDuplicateSprite={onDuplicateSprite}
                    onExportSprite={onExportSprite}
                    onFileUploadClick={onFileUploadClick}
                    onNewSpriteClick={onNewSpriteClick}
                    onPaintSpriteClick={onPaintSpriteClick}
                    onSelectSprite={onSelectSprite}
                    onSpriteUpload={onSpriteUpload}
                    onSurpriseSpriteClick={onSurpriseSpriteClick}
                    targetPanetype={targetPanetype}
                />
                <div className={styles.stageSelectorWrapper}>
                    {stage.id && <StageSelector
                        asset={
                            stage.costume &&
                            stage.costume.asset
                        }
                        backdropCount={stage.costumeCount}
                        id={stage.id}
                        selected={stage.id === editingTarget}
                        onSelect={onSelectSprite}
                        targetPanetype={targetPanetype}
                    />}
                    <div>
                        {spriteLibraryVisible ? (
                            <SpriteLibrary
                                vm={vm}
                                onActivateBlocksTab={onActivateBlocksTab}
                                onRequestClose={onRequestCloseSpriteLibrary}
                            />
                        ) : null}
                    </div>
                </div>
            </div>;  
    }
}

const spriteShape = PropTypes.shape({
    costume: PropTypes.shape({
        // asset is defined in scratch-storage's Asset.js
        asset: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        url: PropTypes.string,
        name: PropTypes.string.isRequired,
        // The following are optional because costumes uploaded from disk
        // will not have these properties available
        bitmapResolution: PropTypes.number,
        rotationCenterX: PropTypes.number,
        rotationCenterY: PropTypes.number
    }),
    costumeCount: PropTypes.number,
    direction: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    order: PropTypes.number,
    size: PropTypes.number,
    visibility: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number
});

TargetPane.propTypes = {
    editingTarget: PropTypes.string,
    extensionLibraryVisible: PropTypes.bool,
    fileInputRef: PropTypes.func,
    hoveredTarget: PropTypes.shape({
        hoveredSprite: PropTypes.string,
        receivedBlocks: PropTypes.bool
    }),
    onActivateBlocksTab: PropTypes.func.isRequired,
    onChangeSpriteDirection: PropTypes.func,
    onChangeSpriteName: PropTypes.func,
    onChangeSpriteRotationStyle: PropTypes.func,
    onChangeSpriteSize: PropTypes.func,
    onChangeSpriteVisibility: PropTypes.func,
    onChangeSpriteX: PropTypes.func,
    onChangeSpriteY: PropTypes.func,
    onChangeSpriteW: PropTypes.func,
    onChangeSpriteH: PropTypes.func,
    onChangeSpriteIn: PropTypes.func,
    onChangeSpriteOut: PropTypes.func,
    onDeleteSprite: PropTypes.func,
    onDrop: PropTypes.func,
    onDuplicateSprite: PropTypes.func,
    onExportSprite: PropTypes.func,
    onFileUploadClick: PropTypes.func,
    onNewSpriteClick: PropTypes.func,
    onPaintSpriteClick: PropTypes.func,
    onRequestCloseExtensionLibrary: PropTypes.func,
    onRequestCloseSpriteLibrary: PropTypes.func,
    onSelectSprite: PropTypes.func,
    onSpriteUpload: PropTypes.func,
    onSurpriseSpriteClick: PropTypes.func,
    raiseSprites: PropTypes.bool,
    spriteLibraryVisible: PropTypes.bool,
    sprites: PropTypes.objectOf(spriteShape),
    stage: spriteShape,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM),
    targetPanetype: PropTypes.number,
    rightTab: PropTypes.bool,
};

export default TargetPane;
