import { defineMessages } from "react-intl";

const LANG_MESSAGE = defineMessages({
    taskBasedTraining: { // 是否结束所有训练任务，并开始 新的训练
        id: 'gui.createModel.taskBasedTraining',
        description: '您已有任务训练，待任务结束后重试',
        defaultMessage: '您已有任务训练，待任务结束后重试'
    },
    inferenceTaskRunning: { // 是否结束所有推理任务，并开始 新的推理
        id: 'gui.createModel.inferenceTaskRunning',
        description: '您已有推理任务在运行中，待任务结束后重试',
        defaultMessage: '您已有推理任务在运行中，待任务结束后重试'
    },
    compilationTaskPresent: { // 是否结束所有编译任务，并开始 新的编译
        id: 'gui.createModel.compilationTaskPresent',
        description: '您已有编译任务，待任务结束后重试',
        defaultMessage: '您已有编译任务，待任务结束后重试'
    },
    modelNameIsDuplicate: {
        id: 'gui.createModel.modelNameIsDuplicate',
        description: '模型名称重复',
        defaultMessage: '模型名称重复'
    },
    trainingInitialization: {
        id: 'gui.stageHeader.trainingInitialization',
        description: '训练初始化中',
        defaultMessage: '训练初始化中'
    },
    compileInitialization: {
        id: 'gui.stageHeader.compileInitialization',
        description: '编译初始化中',
        defaultMessage: '编译初始化中'
    },
    trainingWaiting: {
        id: 'gui.stageHeader.trainingWaiting',
        description: '训练等待中',
        defaultMessage: '训练等待中'
    },
    compilationWaiting: {
        id: 'gui.stageHeader.compilationWaiting',
        description: '编译等待中',
        defaultMessage: '编译等待中'
    },
    trainingInProgress: {
        id: 'gui.stageHeader.trainingInProgress',
        description: '正在训练',
        defaultMessage: '正在训练'
    },
    compiling: {
        id: 'gui.stageHeader.compiling',
        description: '正在编译',
        defaultMessage: '正在编译'
    },
    trainingFailed: {
        id: 'gui.stageHeader.trainingFailed',
        description: '训练失败',
        defaultMessage: '训练失败'
    },
    compilationFailed: {
        id: 'gui.stageHeader.compilationFailed',
        description: '编译失败',
        defaultMessage: '编译失败'
    },
    trainingSuccessful: {
        id: 'gui.stageHeader.trainingSuccessful',
        description: '训练成功',
        defaultMessage: '训练成功'
    },
    compilationSuccessful: {
        id: 'gui.stageHeader.compilationSuccessful',
        description: '编译成功',
        defaultMessage: '编译成功'
    },
    trainingStopped: {
        id: 'gui.stageHeader.trainingStopped',
        description: '训练已停止',
        defaultMessage: '训练已停止'
    },
    compilationStopped: {
        id: 'gui.stageHeader.compilationStopped',
        description: '编译已停止',
        defaultMessage: '编译已停止'
    },
    cloudDeploymentInitial: {
        id: 'gui.stageHeader.cloudDeploymentInitial',
        description: '云部署初始中',
        defaultMessage: '云部署初始中'
    },
    cloudDeploymentCreation: {
        id: 'gui.stageHeader.cloudDeploymentCreation',
        description: '云部署创建中',
        defaultMessage: '云部署创建中'
    },
    cloudDeploymentAndOperation: {
        id: 'gui.stageHeader.cloudDeploymentAndOperation',
        description: '云部署运行中',
        defaultMessage: '云部署运行中'
    },
    cloudDeploymentFailed: {
        id: 'gui.stageHeader.cloudDeploymentFailed',
        description: '云部署失败',
        defaultMessage: '云部署失败'
    },
    cloudDeploymentStopped: {
        id: 'gui.stageHeader.cloudDeploymentStopped',
        description: '云部署已停止',
        defaultMessage: '云部署已停止'
    },
    PLEASE_SELECT: {
        id: 'gui.blocklyDialog.PLEASE_SELECT',
        description: '请选择',
        defaultMessage: '请选择'
    },
    invalidConfiguration: {
        id: 'gui.createModel.invalidConfiguration',
        description: '无可用算力资源，请充值',
        defaultMessage: '无可用算力资源，请充值'
    },
    pleaseTakePhoto: {
        id: 'gui.createModel.pleaseTakePhoto',
        description: '请拍照',
        defaultMessage: '请拍照'
    },
    newModel: {
        id: 'gui.createModel.newModel',
        description: '新建模型',
        defaultMessage: '新建模型'
    },
    MODEL_NAME: {
        id: 'gui.blocklyDialog.MODEL_NAME',
        description: '模型名称',
        defaultMessage: '模型名称'
    },
    cancel: {
        id: 'gui.customProcedures.cancel',
        description: '取消',
        defaultMessage: '取消'
    },
    ok: {
        id: 'gui.prompt.ok',
        description: '确定',
        defaultMessage: '确定'
    },
    taskType: {
        id: 'gui.dimesion.taskType',
        description: '任务类型',
        defaultMessage: '任务类型'
    },
    selectDataset: {
        id: 'gui.createModel.selectDataset',
        description: '选择数据集',
        defaultMessage: '选择数据集'
    },
    normalMode: {
        id: 'gui.createModel.normalMode',
        description: '普通模式',
        defaultMessage: '普通模式'
    },
    advancedMode: {
        id: 'gui.createModel.advancedMode',
        description: '高级模式',
        defaultMessage: '高级模式'
    },
    trainingModel: {
        id: 'gui.createModel.trainingModel',
        description: '训练模型',
        defaultMessage: '训练模型'
    },
    DATA_SET: {
        id: 'gui.blocklyDialog.DATA_SET',
        description: '数据集',
        defaultMessage: '数据集'
    },
    reSelect: {
        id: 'gui.createModel.reSelect',
        description: '重新选择',
        defaultMessage: '重新选择'
    },
    trainingRounds: {
        id: 'gui.createModel.trainingRounds',
        description: '训练轮次',
        defaultMessage: '训练轮次'
    },
    imageSize: {
        id: 'gui.createModel.imageSize',
        description: '图像大小',
        defaultMessage: '图像大小'
    },
    batchSampleSize: {
        id: 'gui.createModel.batchSampleSize',
        description: '批样本数',
        defaultMessage: '批样本数'
    },
    learningRate: {
        id: 'gui.createModel.learningRate',
        description: '学习速率',
        defaultMessage: '学习速率'
    },
    trainingComputingPower: {
        id: 'gui.createModel.trainingComputingPower',
        description: '训练算力',
        defaultMessage: '训练算力'
    },
    inferentialComputingPower: {
        id: 'gui.createModel.inferentialComputingPower',
        description: '推理算力',
        defaultMessage: '推理算力'
    },
    dataInput: {
        id: 'gui.createModel.dataInput',
        description: '数据输入',
        defaultMessage: '数据输入'
    },
    uploadTest: {
        id: 'gui.createModel.uploadTest',
        description: '上传测试',
        defaultMessage: '上传测试'
    },
    confidenceLevel: {
        id: 'gui.createModel.confidenceLevel',
        description: '置信度',
        defaultMessage: '置信度'
    },
    threshold: {
        id: 'gui.createModel.threshold',
        description: '阈值',
        defaultMessage: '阈值'
    },
    neuralNetworkLearning: {
        id: 'gui.createModel.neuralNetworkLearning',
        description: '神经网络学习',
        defaultMessage: '神经网络学习'
    },
    trainingResults: {
        id: 'gui.createModel.trainingResults',
        description: '推理结果',
        defaultMessage: '推理结果'
    },
    Identifying: {
        id: 'gui.createModel.Identifying',
        description: '正在识别',
        defaultMessage: '正在识别'
    },
    identificationResults: {
        id: 'gui.createModel.identificationResults',
        description: '标签列表',
        defaultMessage: '标签列表'
    },
    currentRound: {
        id: 'gui.createModel.currentRound',
        description: '当前轮次',
        defaultMessage: '当前轮次'
    },
    accuracyRate: {
        id: 'gui.createModel.accuracyRate',
        description: '正确率',
        defaultMessage: '正确率'
    },
    lossRate: {
        id: 'gui.createModel.lossRate',
        description: '损失率',
        defaultMessage: '损失率'
    },
    taskstatus: {
        id: 'gui.createModel.taskstatus',
        description: '任务状态',
        defaultMessage: '任务状态'
    },
    TPUTesting: {
        id: 'gui.createModel.TPUTesting',
        description: 'TPU测试',
        defaultMessage: 'TPU测试'
    },
    deployModel: {
        id: 'gui.createModel.deployModel',
        description: '部署模型',
        defaultMessage: '部署模型'
    },
    startTraining: {
        id: 'gui.createModel.startTraining',
        description: '开始训练',
        defaultMessage: '开始训练'
    },
    TPUType: {
        id: 'gui.createModel.TPUType',
        description: 'TPU类型',
        defaultMessage: 'TPU类型'
    },
    createDataset: {
        id: 'gui.createModel.createDataset',
        description: '创建数据集',
        defaultMessage: '创建数据集'
    },
    compilationComputingPower: {
        id: 'gui.createModel.compilationComputingPower',
        description: '编译算力',
        defaultMessage: '编译算力'
    },
    modelCompilationSimilarity: {
        id: 'gui.createModel.modelCompilationSimilarity',
        description: '模型编译相似度',
        defaultMessage: '模型编译相似度'
    },
    testModel: {
        id: 'gui.createModel.testModel',
        description: '测试模型',
        defaultMessage: '测试模型'
    },
    TPUStatus: {
        id: 'gui.createModel.TPUStatus',
        description: 'TPU状态',
        defaultMessage: 'TPU状态'
    },
    check: {
        id: 'gui.tpu.check',
        description: '检测中',
        defaultMessage: '检测中'
    },
    restart: {
        id: 'gui.tpu.restart',
        description: '待重启',
        defaultMessage: '待重启'
    },
    disconnect: {
        id: 'gui.tpu.disconnect',
        description: '未连接',
        defaultMessage: '未连接'
    },
    starting: {
        id: 'gui.rerun.starting',
        description: '启动中',
        defaultMessage: '启动中'
    },
    stop: {
        id: 'gui.tpu.stop',
        description: '已停止',
        defaultMessage: '已停止'
    },
    run: {
        id: 'gui.tpu.run',
        description: '已启动',
        defaultMessage: '已启动'
    },
    rerun: {
        id: 'gui.tpu.rerun',
        description: '请重启',
        defaultMessage: '请重启'
    },
    startCompiling: {
        id: 'gui.createModel.startCompiling',
        description: '开始编译',
        defaultMessage: '开始编译'
    },
    downloadTPU: {
        id: 'gui.createModel.downloadTPU',
        description: '下载TPU',
        defaultMessage: '下载TPU'
    },
    modelSave: {
        id: 'gui.createModel.modelSave',
        description: '模型保存',
        defaultMessage: '模型保存'
    },
    isCover: {
        id: 'gui.createModel.isCover',
        description: '同名是否覆盖',
        defaultMessage: '同名是否覆盖'
    },
    yes: {
        id: 'gui.createModel.yes',
        description: '是',
        defaultMessage: '是'
    },
    no: {
        id: 'gui.createModel.no',
        description: '否',
        defaultMessage: '否'
    },
    curing: {
        id: 'gui.createModel.curing',
        description: '固化中',
        defaultMessage: '固化中'
    },
    downloadSuccessful: {
        id: 'gui.dimesion.downloadSuccessful',
        description: '下载成功',
        defaultMessage: '下载成功'
    },
    downloadFailed: {
        id: 'gui.createModel.downloadFailed',
        description: '下载失败',
        defaultMessage: '下载失败'
    },
    close: {
        id: 'gui.cards.close',
        description: '关闭',
        defaultMessage: '关闭'
    },
    downloadAgain: {
        id: 'gui.createModel.downloadAgain',
        description: '重新下载',
        defaultMessage: '重新下载'
    },
    modelNameCannotEmpty: {
        id: 'gui.createModel.modelNameCannotEmpty',
        description: '模型名称不能为空',
        defaultMessage: '模型名称不能为空'
    },
    customParameters: {
        id: 'gui.createModel.customParameters',
        description: '自定参数',
        defaultMessage: '自定参数'
    },
    matchingUnsuccessful: {
        id: 'gui.createModel.matchingUnsuccessful',
        description: '自定参数{str}匹配不成功',
        defaultMessage: '自定参数{str}匹配不成功'
    },
    taskType: {
        id: 'gui.createModel.taskType',
        description: '请选择任务类型',
        defaultMessage: '请选择任务类型'
    },
    running: {
        id: 'gui.createModel.running',
        description: '正在运行中，请勿多次点击',
        defaultMessage: '正在运行中，请勿多次点击'
    },
    noTaskType: {
        id: 'gui.createModel.noTaskType',
        description: '未选任务类型',
        defaultMessage: '未选任务类型'
    },
    pleaseSelectDataset: {
        id: 'gui.createModel.pleaseSelectDataset',
        description: '请选择数据集',
        defaultMessage: '请选择数据集'
    },
    endTraining: {
        id: 'gui.createModel.endTraining',
        description: '是否结束所有训练任务，并开始 新的训练',
        defaultMessage: '是否结束所有训练任务，并开始 新的训练'
    },
    endReasoning: {
        id: 'gui.createModel.endReasoning',
        description: '是否结束所有推理任务，并开始 新的推理',
        defaultMessage: '是否结束所有推理任务，并开始 新的推理'
    },
    endCompilation: {
        id: 'gui.createModel.endCompilation',
        description: '是否结束所有推理任务，并开始 新的编译',
        defaultMessage: '是否结束所有推理任务，并开始 新的编译'
    },
    enterName: {
        id: 'gui.createModel.enterName',
        description: '请输入名称',
        defaultMessage: '请输入名称'
    },
    range: {
        id: 'gui.createModel.range',
        description: '置信度，阈值有效值的范围在0-1之间，请设置在有效范围内',
        defaultMessage: '置信度，阈值有效值的范围在0-1之间，请设置在有效范围内'
    },
    enterNumber: {
        id: 'gui.createModel.enterNumber',
        description: '请输入数字',
        defaultMessage: '请输入数字'
    },
    algorithmType: {
        id: 'gui.createModel.algorithmType',
        description: '算法类型',
        defaultMessage: '算法类型'
    },
    compilationModel: {
        id: 'gui.createModel.compilationModel',
        description: '编译模型',
        defaultMessage: '编译模型'
    }
})

export default LANG_MESSAGE
